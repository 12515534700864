.posts-list {

    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        margin-bottom: $padding;
        list-style: none;
        @include box-theme();

        @media (max-width: $size-mobile) {
            border: none;
            border-bottom: solid 1px $light3;
            @include border-radius(0);
        }

        .post-tile-left {
            vertical-align: middle;
            width: 65%;
            display: inline-block;

            h3 {
                font-size: $font-largest;
                font-weight: 700;
            }

            p {
                font-size: $font-normal;
                color: $dark1;
            }
        }

        .post-tile-right {
            width: 35%;
            vertical-align: middle;
            display: inline-block;
            text-align: right;

            .btn {
                margin-top: $rad;
                display: inline-block;
                margin-left: $padding;
            }
        }
    }

}