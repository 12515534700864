.disabled,
:disabled,
button:disabled,
button:disabled:hover {
  cursor: not-allowed !important;
}



// Align
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center !important;
}

@media (max-width: $size-mobile) {
  .align-center-mobile {
    text-align: center !important;
  }
}

// Padding
.no-margin {
  margin: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.padded {
  padding: $padding * 2 !important;
}

.no-padded-bottom {
  padding-bottom: 0 !important;
}

.padded-small {
  padding: $spacing * 1 !important;
}


.no-padding {
  padding: 0 !important;
}


.padded-mobile {
  @media (max-width: $size-mobile) {
    padding: $padding !important;
  }
}

.padded-tablet {
  @media (max-width: $size-tablet) {
    padding: $padding !important;
  }
}

.padded-left {
  padding-left: $padding !important;
}

.padded-right-small {
  padding-right: $spacing !important;
}

.padded-right {
  padding-right: $padding !important;
}

.padded-bottom {
  padding-bottom: $padding * 2 !important;
}

.padded-bottom-small {
  padding-bottom: $spacing !important;
}
.padded-bottom-tiny {
  padding-bottom: $rad !important;
}

.padded-top {
  padding-top: $padding;
}

.padded-top-large {
  padding-top: $padding * 2 !important;
}

.padded-top-huge {
  padding-top: $padding * 5 !important;
}

.padded-left-large {
  padding-left: $padding * 3 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.margin-top {
  margin-top: $padding !important;
}

.negative-margin-top {
  margin-top: -$padding * 4 !important;

}

.negative-margin-top-small {
  margin-top: -$padding * 2 !important;


}

.margin-top-large {
  margin-top: $padding * 3 !important;
}

.margin-top-largest {
  margin-top: $padding * 5 !important;
}

.margin-top-small {
  margin-top: $spacing !important;
}

.margin-right-small {
  margin-right: $spacing !important;
}

.margin-bottom {
  margin-bottom: $padding !important;
}


.center {
  text-align: center !important;
}

.margin-bottom-small {
  margin-bottom: $spacing !important;
}

.margin-right {
  margin-right: $padding !important;
}

.margin-left {
  margin-left: $padding !important;
}

.margin-left-small {
  margin-left: $spacing !important;
}

.margin-right-desktop {
  @media (min-width: $size-tablet) {
    margin-right: $padding !important;
  }
}

.margin-left-desktop {
  @media (min-width: $size-tablet) {
    margin-left: $padding !important;
  }
}

.hide {
  display: none !important;
}



.row {
  margin: 0;
}

.thumbnail-image {
  @include border-radius($rad);
  max-width: 100%;
}

.small-image {
  max-width: $padding * 20;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.inline {
  display: inline-block;
}