.accordion {

    padding: $padding * 1.5 $padding;
    &.dark {
        .card {
            background: lighten($light2, 6);
            border: solid 1px darken($light2, 4);
        }
    }

    .card {
        padding: 0;
        border: solid 1px transparent;
        border-radius: $rad;
        @include transition-fade(140ms);

        &:first-child {
            border-radius: $rad $rad 0 0 !important;
        }

        &:last-child {
            border-top: none !important;
            border-radius: 0 0 $rad $rad !important;
        }

        &:hover {
            background: lighten($light2, 6);
            border: solid 1px darken($light2, 4);

            .card-header {
                .accordian-title {
                    color: $pink;
                }

            }
        }

        .card-header {
            font-size: $font-large;
            font-weight: 400;
            padding: $spacing;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: $size-mobile) {
                font-size: $font-largish;
                text-align: left;
    
            }

            .accordian-title {
                width: 90%;
            }

            .svg-inline--fa {
                width: 10%;

                right: $padding * 5;
                color: $pink;

            }

            &:hover {
                cursor: pointer;
                //    color: $pink;
            }
        }

        .card-body {
            padding: $padding;
            padding-top: 0;
            color: $dark2;
            font-size: $font-largish;
            text-align: left;

        }
    }
}