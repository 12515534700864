.react-mde-wrapper {

    .rsw-editor {
        border: solid 1px $light3;
        position: relative;

        .rsw-toolbar {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1000;
            .rsw-dd {
                padding: $rad;
                width: $padding * 8;
            }
        }

        .rsw-ce{
            overflow-y: scroll;
            padding: $padding;
            padding-top: $padding * 3 !important;
        }

        .markdown-heading,
        .rsw-ce {
            h1 {
                font-size: $font-largest * 1.5 !important;
                line-height: 1.1;
            }

            h2 {
                font-size: $font-large * 1.15 !important;
                line-height: 1.1;

            }

            div {
                margin-bottom: $padding;
                font-size: $font-large * 1;
                
            }

            ul, li {
                font-size: $font-large * 1;
            }
            ul{
                margin-left: $padding * 2;
            }

            img{
                max-width: 100%;
                margin: $padding 0;
            }

            a {
                color: $green1;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    ;
                }
            }
        }
    }

}