.user-edit {

    .user-edit-hero {
        width: 100%;
        padding-top: $padding;
        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));

        padding-bottom: $padding * 2;

        @media (max-width: $size-mobile) {
            padding-top: $padding * 6;
            margin-bottom: $padding;
        }
    }



}