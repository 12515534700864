.job-apply-button {

    .apply-expired{
        background: rgba($pink, 0.15);
        padding: $padding * 1.5;
        border-radius: $rad;
        margin-bottom: $padding;
        text-align: center;
        border: solid 2px $pink;
        color: $pink;
        font-weight: 600;
        font-size: $font-large;
    }

    .btn {
        display: block;
        width: 100%;
        padding: $spacing * 1.5 $padding * 1.25;
        margin: 0;
        font-size: $font-largest * 1.05;

        .apply-location {
            font-size: $font-small;
            font-weight: 400;
            padding-top: $rad * 1.5;
            margin: 0;
        }
    }
}