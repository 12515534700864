.filters-wrapper {

    margin-bottom: 0; // $padding;
    width: 100%;
    padding: $padding * 2 $padding;
    padding-top: $spacing;
    background: $green1;
    background: #1d976c;

    border-bottom: none; //solid 1px $light2;
    background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, $light1, lighten($light2, 3));


    @media (max-width: $size-desktop) {
        @include border-radius($rad);
    }

    @media (max-width: $size-mobile) {
        margin-bottom: 0;

        border-bottom: none;
    }


    @media ((min-width: $size-mobile) and (max-width: $size-tablet)) {
        background: $light1 !important;
        padding: 0;
    }

    .filters-content-wrapper {

        margin: 0 auto;
        max-width: $size-mobile;

        .filters {
            margin: 0 auto;



            @media (max-width: $size-mobile) {
                @include border-radius(0);
                margin-bottom: $padding;
                background: transparent;
                border: none;
                text-align: center;
                padding: 0 !important;
                display: block;
            }

            /*
    Full search panel
    */
            .search {
                position: relative;

                .svg-inline--fa {
                    max-width: $padding;
                    max-height: $padding;

                }

                .form-wrapper {

                    min-height: 126px; // CLS
                    //      border: solid 3px $light3;
                    //          @include border-radius($spacing * 1.25);

                    @media (max-width: $size-mobile) {
                        border: none;
                        min-height: 154px;
                    }


                    .extra-filters {
                        padding: $spacing * 1 0 0 0;
                        width: 100%;

                        .field-row {
                            display: inline-block;


                            @media (max-width: $size-mobile) {
                                width: 100%;
                                #remote_option {
                                    margin-top: -$rad * 2;
                                }
                            }


                            &.wide {
                                @media (max-width: $size-mobile) {
                                    width: 100%;
                                }

                                .react-select__value-container,
                                .react-select__control {
                                    width: $padding * 19;

                                    @media (max-width: $size-mobile) {
                                        width: 100%;
                                    }

                                }
                            }


                            &:last-child {
                                .react-select__control {
                                    margin-right: 0;
                                }
                            }


                            @media (max-width: $size-mobile) {
                                //    width: 50%;
                                vertical-align: top;

                                padding-bottom: $spacing;

                                .react-select__value-container {
                                    padding: 0;
                                    margin: 0 !important;
                                    width: 100%;
                                    height: $padding * 3;
                                    line-height: 1;

                                }

                                .react-select__indicator {
                                    margin: 0;
                                    padding: 0 $rad;
                                }

                            }

                            .react-select__control {
                                padding: 0;
                                margin-right: $padding;
                                @media (max-width: $size-mobile) {
                                    margin-right: 0;
                                }

                                @include border-radius($spacing);



                                .react-select__value-container {

                                    @media (max-width: $size-mobile) {
                                        padding: $spacing 0 $spacing $spacing;
                                    }


                                    padding: $spacing 0 $spacing $padding;
                                    font-size: $font-normal;

                                }
                            }
                        }
                    }


                }

                .label-container {
                    padding: 0;

                }

                .react-select-container {


                    // Actual react-select input
                    .react-select__control {
                        font-size: $font-large * 0.9;
                        border: solid 1px $light4 !important;


                        input {
                            padding: 0 !important;
                            line-height: 1 !important;
                            border: none !important;
                        }

                        .react-select__value-container {
                            padding: $padding * 1.1 $padding * 2;

                        }

                        @media (max-width: $size-mobile) {
                            margin-bottom: $rad;
                        }

                    }


                    .react-select__menu {
                        z-index: 1000000;

                            // do an inner shadow
                            border: solid 1px $light3;

                            -webkit-box-shadow: inset 0px 0px 12px -4px rgba(0, 0, 0, 0.38);
                            -moz-box-shadow: inset 0px 0px 12px -4px rgba(0, 0, 0, 0.38);
                            box-shadow: inset 0px 0px 12px -4px rgba(0, 0, 0, 0.38);
                    }


                }

                /*
         Skill container
         */
                .skill {
                    .field-row {
                        padding-bottom: 0;


                    }

                    .search-icon {
                        position: absolute;
                        left: $padding * 0.75;
                        top: $padding * 1.3;
                        font-size: $font-huge * 0.45;
                        color: $light4;


                        @media (max-width: $size-mobile) {
                            top: $padding * 1.25;
                        }
                    }

                    .react-select-container {

                        // Actual react-select input
                        .react-select__control {
                            height: $control-height-large;
                            @include border-radius($spacing 0 0 $spacing);

                            @media (max-width: $size-mobile) {
                                @include border-radius($spacing);
                            }

                            .react-select__value-container {
                                .react-select__input input {}

                            }

                            .react-select__indicators {

                                .react-select__clear-indicator {
                                    line-height: 1.4rem;
                                    display: block;
                                }

                            }


                        }


                        // Dropdown menu
                        .react-select__menu {
                            .react-select__menu-list {
                                min-height: $padding * 15
                            }

                            .react-select__option {
                                padding: $rad * 2.5 $rad * 2;

                                .svg-inline--fa {
                                    font-size: $font-large * 0.8;
                                    opacity: 0.8;
                                    width: $padding * 1.5;
                                    padding-right: $rad;
                                }

                                .fa-sitemap {
                                    color: $green1;
                                }

                                .fa-briefcase {
                                    color: $pink;
                                }

                                .fa-tag {
                                    color: $dark3;
                                }

                            }

                        }


                    }

                }

                .company-reduced-hours {
                    .field-row {
                        padding-bottom: 0;

                        .react-select__control {

                            height: $control-height-large;
                        }

                        .react-select-multi {
                            padding: 0;



                            .react-select-multi-values {
                                padding: 0;
                                line-height: 1;
                                font-size: $font-normal * 1.15;
                            }


                        }
                    }

                    .search-icon {
                        position: absolute;
                        left: $padding * 0.75;
                        top: $padding * 1.3;
                        font-size: $font-huge * 0.45;
                        color: $light4;


                        @media (max-width: $size-mobile) {
                            top: $padding * 1.25;
                        }
                    }

                    .react-select-container {

                        // Actual react-select input
                        .react-select__control {
                            @include border-radius($spacing 0 0 $spacing);
                            line-height: 1;

                            @media (max-width: $size-mobile) {
                                @include border-radius($spacing);
                            }


                            .react-select-multi {

                                padding: $padding * 1.25 $padding * 2.25;
                                padding-right: 0;

                                input {
                                    margin-left: 40px !important;
                                }
                            }

                            .react-select__indicators {

                                .react-select__clear-indicator {
                                    line-height: 1.4rem;
                                    display: block;
                                }

                            }


                        }



                    }

                }

                /*
         Location container
         */
                .location {
                    position: relative;

                    height: $control-height-large;

                    label {
                        position: absolute;
                        z-index: 1;
                        line-height: $padding * 2.4;
                        color: $light4;
                        left: $padding * .75;
                        top: $spacing * 1.5;
                        font-size: $font-largish;

                        @media (max-width: $size-mobile) {
                            top: $spacing * 1.25;
                        }
                    }

                    .react-select-container {

                        // Actual react-select input
                        .react-select__control {
                            height: $control-height-large;
                            border-left: none;
                            @include border-radius(0);
                            @include border-radius(0 $spacing $spacing 0);


                            @media (min-width: $size-mobile) {
                                border-left: none !important;

                            }

                            @media (max-width: $size-mobile) {
                                @include border-radius($spacing);
                                border: solid 1px $light3;
                            }

                            &.react-select__control--is-focused {}

                            .react-select__value-container {
                                @media (min-width: $size-desktop) {
                                    padding-right: 0;

                                }

                                .react-select__input input {}

                            }

                            // Right buttons
                            .react-select__indicators {


                                .react-select__dropdown-indicator {
                                    padding: $spacing $spacing;
                                    padding-right: $padding;
                                }

                                .react-select__clear-indicator,
                                .react-select__indicator-separator {}

                            }

                        }

                        // Dropdown menu
                        .react-select__menu {

                            .react-select__menu-list {
                                max-height: $padding * 15.5;
                            }

                            .react-select__option {}

                        }


                    }



                }

                .buttons {




                    .btn-success {
                        padding: $padding * 1.15;
                        margin-left: $spacing;

                        @media (max-width: $size-mobile) {
                            width: 100%;
                            margin: 0;
                            margin-bottom: $padding * 2;
                        }
                    }


                }



            }



        }


    }

    .popular {

        margin: 0 auto;
        max-width: $size-mobile;
        margin-bottom: $padding * 15;
        padding-top: $padding * 6;

        h4 {
            text-align: center;
            font-weight: 800;
            margin-bottom: $padding * 2;
        }

        ul {
            margin: 0;
            padding: 0;
            text-align: left;

            li {
                list-style: none;
                width: calc(100% / 3);
                display: inline-block;
                padding: $spacing $padding;
                vertical-align: top;

                @media (max-width: $size-mobile) {
                    width: 50%;
                    padding: $spacing;

                    &:nth-child(odd) {
                        padding-right: $spacing;
                    }


                }

                &.view-all {
                    min-height: 0;
                    width: 100%;

                    .popular-btn {
                        min-height: 0;
                        padding: $padding * 1 !important;
                    }
                }


                &.view-all-part-time {
                    min-height: 0;
                    width: 100%;


                    .btn {
                        h5 {
                            font-weight: 500 !important;
                            font-size: $font-large;
                        }
                    }
                }


                .popular-btn {
                    @include box-theme();
                    width: 100%;
                    padding: $padding * 1;
                    @include border-radius($rad);
                    text-align: center;
                    text-decoration: none;

                    margin-bottom: 0 !important;
                    min-height: $padding * 9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;


                    &:hover {
                        background: lighten($light2, 4);
                        text-decoration: none;
                    }


                    &:focus {
                        background-color: $light2;
                    }



                    h5 {
                        font-size: $font-largest;
                        margin: 0;
                        color: $dark4;
                        font-weight: 400;

                    }

                    p {
                        color: $dark1;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                    }
                }
            }

        }
    }

    .cooc {
        margin: 0;
        padding: 0;
        text-align: left;
        padding-top: $spacing;

        @media (min-width: $size-desktop) {
            padding-top: 0;

        }

        @media (min-width: $size-mobile) {
            padding-top: $spacing;
            overflow: hidden;
            white-space: nowrap;

            text-overflow: ellipsis;
        }

        li {
            font-size: $font-normal;
            display: inline-block;
            list-style: none;
            padding-right: $rad;


            &::after {
                content: ",";
            }

            &:first-child::after {
                content: "";
            }


            &:last-child::after {
                content: "";
            }



        }


    }

}