section {
  padding: $padding * 2 0 0 0;

  @media (max-width: $size-mobile) {
    padding: $padding;
    padding-top: $padding;
  }


  @media (max-width: $size-mobile) {


    &.no-padding-mobile,
    .no-padding-mobile {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }


  }

  &.section-header {

    @media (max-width: $size-mobile) {
      padding: $padding * 0 $padding * 1;
    }


  }


  &.section-padded {
    padding: $padding * 6 0 $padding * 2 0;

    @media (max-width: $size-mobile) {
      padding-top: $padding * 2;
    }

    &.section-padded-mobile {

      @media (max-width: $size-mobile) {
        padding-left: $padding;
        padding-right: $padding;
        text-align: left;
      }
    }
  }


  &.thinnish {
    margin: 0 auto;
    max-width: $size-tablet * 1.2;
  }

  &.thin {
    margin: 0 auto;
    max-width: $size-mobile;
  }

  &.normal {
    margin: 0 auto;
    max-width: $size-desktop;
  }

  &.thinnest {
    margin: 0 auto;
    max-width: $size-mobile * 0.77;
  }
}

.row {
  &.thin-width {
    max-width: $size-mobile;
    margin: 0 auto;
  }

  &.medium-width {
    max-width: $size-desktop;
    margin: 0 auto;
  }

  &.relative {
    position: relative;
  }
}

// Main content
.main-container {


  &.medium-width {
    max-width: $size-desktop;
    margin: 0 auto;
  }

  &.thin-width {
    max-width: $size-mobile;
    margin: 0 auto;
  }
}

// App
.wrapper {
  height: 100%;
  position: relative;


  .content-wrapper {

    padding: 0 $padding;

    width: calc(100% - #{$menu-width});
    display: flex;
    flex-direction: column;
    //height: 100vh;
    transition: none !important;




    @media (max-width: $size-desktop) {
      padding: 0;
      width: 100%;
    }

    @media (min-width: $size-desktop) {
      padding: 0;
    }

    &.full-width {
      width: 100%;
    }


    &.scrolled {

      .header-container {

        border-bottom: solid 1px $light2;
      }

    }


    // Header
    .header-container {
      text-align: left;
      padding: $rad 0;
      position: sticky;
      width: 100%;
      min-height: $padding * 3;
      top: 0;
      z-index: 1000;
      background-color: $light1;
      transition: box-shadow 0.2s ease-in-out;
      transition: padding 0.2s ease;
      //border-bottom: solid 1px $light2;


      @media (max-width: $size-mobile) {
        padding: 0; //$padding $padding * 1.5;
        margin: 0;
        position: fixed;
        width: 100%;
        z-index: 100000;
        background: $light1;
      }

      .navbar {
        transition: padding 0.2s ease;
        max-width: $size-desktop;
        padding: 0;
        margin: 0 auto;
        min-height: unset;

        @media (min-width: $size-mobile) and (max-width: $size-desktop) {
          padding: $spacing $padding !important;

        }
      }


    }


    // Main
    .main-container-wrapper {
      margin: 0 auto;
      padding: 0; //$padding * 1;
      width: 100%;
      overflow: visible;
      // min-height: 700px;
      //     max-width: $size-desktop;
      flex: 1;

      // z-index: 10000000;
      @media (max-width: $size-mobile) {
        padding: $padding * 0;
      }

      @media (min-width: $size-mobile) and (max-width: $size-tablet) {
        padding: 0 $padding * 1 !important;

      }





      // Main content
      .main-container {
        //       max-width: $size-widescreen;
        margin: 0 auto;
        // overflow-y: hidden;
        padding-top: $padding;

        @media (max-width: $size-mobile) {
          padding: 0;
          padding-top: $padding * 5;
        }





        &.center {
          margin: 0 auto;
        }

        .hero {
          padding-top: $padding * 6;
          padding-bottom: $padding * 6;
          margin: 0 auto;


          @media (max-width: $size-mobile) {

            padding-top: $padding * 6;
            padding-bottom: $padding * 3;

          }

          img {
            max-width: 100%;
          }

          &.hero-center {
            max-width: $size-mobile;
            margin: 0 auto;
            text-align: center;

            h2 {
              font-size: $font-huge * 1.2;
              text-align: center;
              font-weight: 900;
              padding: 0 $padding * 4;
              line-height: $font-huge * 1.5;

              @media (max-width: $size-mobile) {
                padding: 0;
                font-size: $font-huge * 0.9;
                line-height: $font-huge * 1.1;
              }
            }


            h1,
            h3,
            h4,
            h5,
            h6 {
              font-size: $font-huge * 0.8;
              text-align: center;
              padding: 0 $padding * 4;
              line-height: $font-huge;

              @media (max-width: $size-mobile) {
                padding: 0;
                font-size: $font-huge * 0.7;
                line-height: $font-huge * 0.9;
              }
            }

            p {
              font-size: $font-largest;
              text-align: center;
              padding: 0 $padding * 4;
              line-height: $font-largest * 1.2;

            }
          }

          &.small-width {
            max-width: $size-mobile * 0.75;
          }

          &.medium-width {
            max-width: $size-desktop;
          }


          @media (max-width: $size-mobile) {
            padding-top: $padding;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              text-align: left;
            }

            h1 {
              font-size: $font-huge * 0.9;
              line-height: $font-huge * 0.85;
            }

            h3 {
              font-size: $font-large * 1.1;
              line-height: $font-large * 1.3;
            }

            h6 {
              font-size: $font-largish * 1.1;
            }
          }

          .hero-text {
            font-size: $font-huge * 1.3;
            line-height: $font-huge * 1.3;
            font-weight: 900;
            margin-bottom: $spacing;

            @media (max-width: $size-mobile) {

              font-size: $font-huge * 0.9;
              line-height: $font-huge * 1.0; // Same as logo
            }
          }

          .hero-left {
            padding-right: $padding * 3;

            @media (max-width: $size-mobile) {
              padding: 0;
            }

          }

          h3 {
            color: $dark2;
          }

          h5 {
            font-size: $font-largish * 1.2;
            padding-top: $padding * 4;
            font-weight: 400;
            color: $dark1;

            * {
              font-size: inherit;
              line-height: inherit;
            }
          }

          .hero-right {
            @media (max-width: $size-mobile) {
              padding: 0;
            }

          }

          .hero-center {
            text-align: center;
          }

          .hero-img {
            width: 100%;
            max-width: $padding * 16;
          }

          p {
            //  margin-top: 0;
            margin-bottom: $padding;
            color: $dark2;
          }

          .arrow-btn {

            .btn,
            p {
              display: inline-block;
            }

            p {
              margin-left: $padding;
              color: $dark1;
            }
          }

        }



      }

    }
  }


}


// Padded cols
.cols {
  padding: 0 ($padding * 3) 0 0;

  &:nth-child(2) {
    padding: 0 0 0 ($padding * 3);
  }


  &.cols-sm {
    padding: 0 ($padding * 1) 0 0;

    &:nth-child(2) {
      padding: 0 0 0 ($padding * 1);
    }


    @media (max-width: $size-mobile) {
      padding: 0;

      &:nth-child(2) {
        padding: 0;
      }
    }


  }

  &.cols-lg {
    padding: 0 ($padding * 4) 0 0;

    &:nth-child(2) {
      padding: 0 0 0 ($padding * 4);
    }


    @media (max-width: $size-mobile) {
      padding: 0;

      &:nth-child(2) {
        padding: 0;
      }
    }


  }

  &.quad {
    padding: 0;
    padding-right: $spacing;

    input,
    .field-row {
      max-width: 100%;
      min-width: auto;
    }
  }

  &.triple {
    &:nth-child(1) {

      @media (max-width: $size-mobile) {
        padding-bottom: $padding;
      }
    }

    &:nth-child(2) {
      margin: 0;
      padding: 0;

      @media (max-width: $size-mobile) {
        padding-bottom: $padding;
      }
    }

    &:nth-child(3) {
      padding: 0;
      margin: 0;
      padding-left: $padding * 2;

      @media (max-width: $size-mobile) {
        padding: 0;
      }


    }

  }

  @media (max-width: $size-tablet) {
    padding: 0 ($spacing) 0 0;

    &:nth-child(2) {
      padding: 0 0 0 ($spacing);
    }

  }

  @media (max-width: $size-mobile) {
    padding: 0;
    margin-bottom: 0; //$padding * 2;

    &:nth-child(2) {
      padding: 0;
    }
  }

  &.half-height-container {
    height: calc(100vh - #{$padding * 14});
    display: flex;
    ;
    flex-direction: column;
  }
}


@media (max-width: $size-mobile) {

  .cols,
  .cols-big,
  .cols-sm,
  .triple .cols {
    padding: 0;
    clear: both;
  }

  .triple .cols {
    margin-bottom: $spacing;
    height: auto !important;
  }
}