.wrapper .content-wrapper .main-container-wrapper {

    .add-company {


        .add-company-form {
            @include box-theme();
            padding: $padding * 2;
            max-width: $size-mobile * 0.8;
            position: relative;

            @media (max-width: $size-mobile) {
                border: none;


            }

            h3 {
                //  font-size: $font-largest * 1.75;
                //  line-height: $font-largest * 1.75;

                font-weight: 900;
                margin-bottom: $padding * 2;
            }

            .emoji {
                position: absolute;
                left: -$padding * 1.75;


            }
        }

    }
}