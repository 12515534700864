.tags {
    margin: 0;
    color: $dark2;
    padding: $padding 0 0 0;

    &.tags-sm {
        padding-top: $rad;

        li {
            padding: 0 $spacing;
            margin-right: $rad;
        }
    }

    &.tags-lg {
        padding: $padding 0;

        li {
            font-size: $font-largish;
            padding: $spacing * 0.75 $padding * 0.75;
            margin-right: $spacing;

            @media (max-width: $size-mobile) {
                font-size: $font-largish * 0.9;
                line-height: 1;
            }

            @media (min-width: $size-mobile) and (max-width: $size-tablet) {
                font-size: $font-largish * 0.9;
                line-height: 1;
            }
        }
    }

    &.tags-huge {
        padding: $padding 0;

        li {
            font-size: $font-largest * 1.1 !important;
            padding: $spacing * 2.5 $padding * 2 !important;
            margin-right: $spacing !important;
            margin-bottom: $padding !important;
        }
    }

    &.tags-filled {
        li {
            background: rgba($green1, 0.1);
            color: darken($green1, 5) !important;
            padding: $spacing $padding * 1;
            border: none;
        }
    }

    li {
        background: $light1;
        border: solid 1px darken($light2, 10);
        font-size: $font-normal * 1.1;
        @include border-radius($padding);
        display: inline-block;
        margin: 0;
        margin-right: $spacing * .75;
        list-style: none;
        padding: $rad $spacing * 1.25;
        font-weight: 400;
        margin-bottom: $spacing;

        &.success {
            border-color: lighten($green2, 8);
            color: $green2;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}