.sign-up {

    //padding: $padding * 5 0;
    // margin: $padding * 5 auto;
    padding: $padding * 5 0 !important;
    width: $padding * 32;
    max-width: 100%;
    padding: $padding * 5 0 !important;

    @media (max-width: $size-mobile) {
        padding: $padding * 7 $padding !important;

    }

    .sign-up-form {
        @include box-theme();
        @include box-shadow-lg();
        padding: $padding * 3.5 !important;
        text-align: center;
        margin-bottom: $padding * 2;

        @media (max-width: $size-mobile) {
            border: none;
            padding: 0 !important;
            box-shadow: none;
            overflow: visible;
            padding-bottom: $padding * 2 !important;
            width: 100% !important;

            iframe {
                width: 100% !important;
                overflow: visible;
                border-right: solid 1px $light2 !important;
                @include border-radius($rad);
            }
        }



        h1 {
            font-size: $font-huge * 0.62;
            line-height: 1.25;
            margin-bottom: $padding * 1.5;

            @media (max-width: $size-mobile) {
                font-size: $font-huge * 0.7;
            }

        }



        .field-row {
            padding: 0;
            margin-bottom: 0;
            position: relative;

            .error {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                padding-bottom: $rad;
            }

        }

        .btn {
            font-size: $font-largest * 1;
            padding: $padding * 1.25 $padding * 1.5;
            width: 100%;
        }


    }

    .sign-up-login {
        font-size: $font-large;
        text-align: center;
    }

}