.bold {
  font-weight: 900 !important;
}


h1 {
  font-weight: 900;
  font-size: $font-huge * 1.05;
  margin-top: 0;
  margin-bottom: $padding * 1;

  line-height: $font-huge * 1.1; // Same as logo
  text-wrap: balance;

  @media (max-width: $size-mobile) {
    line-height: $font-huge * 0.85; // Same as logo
    font-size: $font-huge * 0.8;
  }

  &.smaller {
    margin-bottom: $spacing * 1;


    line-height: $font-huge * 0.93; // Same as logo
    font-size: $font-huge * 0.85;


    @media (max-width: $size-mobile) {
      line-height: $font-huge * 0.9;
      font-size: $font-huge * 0.8;

    }
  }

  &.huge {
    font-size: $font-huge * 1.22;
    line-height: $font-huge * 1.24; // Same as logo

    @media (max-width: $size-mobile) {
      line-height: $font-huge * 1.1; // Same as logo
      font-size: $font-huge * 1.1;
    }


  }

  @media (max-width: $size-mobile) {
    &.align-center {
      text-align: left !important;

    }
  }

  &.page-title {
    font-size: $font-huge;
    text-align: left;
    font-weight: 900;
    margin-top: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    //display: none;



    @media (max-width: $size-mobile) {
      padding: 0 $padding;
      // text-align: center;
    }
  }

}

.page-subtitle {
  color: $dark1;

  .emph {
    //  font-weight: 700;
    color: $pink;
  }
}


h2 {
  font-size: $font-huge * 1.5;
  padding: 0;
  font-weight: 900;
  margin: 0 0 $padding 0;

  margin: 0;
  margin-bottom: $spacing;
  line-height: $font-huge * 2.1; // Same as logo
  text-wrap: balance;

  &.smaller {
    font-size: $font-huge * 1;
    line-height: $font-huge * 1.2; // Same as logo


    @media (max-width: $size-mobile) {
      line-height: $font-huge * 1;
      font-size: $font-huge * 0.9;

    }
  }


  @media (max-width: $size-mobile) {
    line-height: $font-huge * 1.2; // Same as logo
    font-size: $font-huge * 1;
  }

  &.section-title {

    @media (max-width: $size-mobile) {
      font-size: $font-huge * 0.7 !important;
      line-height: $font-largest * 1.5; // Same as logo
    }
  }

  &.page-subtitle {
    font-size: $font-largest * 0.82;
    text-align: left;

    //  max-width: $size-mobile;

    font-weight: 400;
    line-height: $font-largest * 1.25;
    margin: 0 auto $padding * 1 auto;
    //display: none;


    @media (max-width: $size-mobile) {
      &.align-center {
        text-align: left !important;

      }
    }

  }


}

h3 {
  font-weight: 900;
  margin: 0 0 $spacing 0;
  font-size: $font-largest * 1.16;
  color: $dark3;
  line-height: $font-largest * 1.4; // Same as logo
  font-weight: 400;
  margin: 0;


  @media (max-width: $size-mobile) {
    line-height: $font-largest * 1.1; // Same as logo
    font-size: $font-largest * 1;
  }

  &.section-title {

    @media (max-width: $size-mobile) {
      font-size: $font-huge * 0.8 !important;
      line-height: $font-largest * 1.9; // Same as logo
    }
  }

  &.page-subtitle {
    margin: $spacing auto $padding * 1.5 auto;
    font-size: $font-large * 0.87;
    line-height: $font-large * 1.25;

    max-width: $size-mobile;

    @media (max-width: $size-mobile) {
      &.align-center {
        text-align: left !important;

      }
    }
  }
}

h4 {
  font-weight: 900;
  padding: 0 0 $spacing 0;
  margin: 0;
  //text-transform: uppercase;
  color: $dark5;
  font-weight: 400;
  font-size: $font-largest * 1.35;
  line-height: 1.1em;
  margin-top: $padding;
}


h5 {
  line-height: 1.4em;
  margin: 0 0 $spacing 0;
  font-size: $font-largest;
  font-weight: 900;
}

h6 {
  line-height: 1.2em;
  margin: 0;
  font-size: $font-largish;
  font-weight: 400;
  color: $dark5;
  margin-top: -$padding;
  padding-bottom: $padding * 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.emph {
    border-bottom: solid 2px red;
  }

  &.section-title {
    text-align: center !important;
  }




  &.section-title {
    font-size: $font-huge * 0.8;
    text-align: center;
    font-weight: 900;
    line-height: 1em;
    margin-top: 0;
    margin-bottom: $padding * 2;
    color: $dark5;



    @media (max-width: $size-mobile) {
      //  padding: 0 $padding;
      // text-align: center;
    }
  }
}



// Lists
ul,
ol {
  margin-bottom: $padding;

  &.two-columns {
    column-count: 2;        /* Specifies the number of columns */
    column-gap: $padding * 2.5;      /* Adjusts the gap between the columns */
    width: 100%;            /* Optional: Adjust this as needed */
    list-style: none;       /* Optional: Removes the default list style */
    padding: 0;             /* Optional: Removes default padding */
}

  li {
    font-weight: 400;
    font-size: $font-large * 0.87;
    line-height: $font-large * 1.28;

    a {
      font-weight: 400;
    }
  }
}


.references-list {
  max-width: 810px;
  margin: 0 auto;

  @media (max-width: $size-mobile) {
    display: block;
    padding-left: $spacing;
  }
}


p {
  color: $dark2;
  text-align: left;
  font-weight: 400;
  margin-bottom: $padding;

  &.small {
    font-size: $font-small;
    color: $dark4;
  }

  &.success {
    font-weight: 900;
    color: $green2;
  }

}

p,
ul li {

  // font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: $font-large * 0.87;
  line-height: $font-large * 1.28;
}



pre {
  font-size: $font-tiny;

  border: 1px solid $light3;
  border-left: $rad solid $red2;
  color: $dark3;
  page-break-inside: avoid;
  font-family: monospace;
  line-height: 1.6;
  max-width: 100%;
  overflow: auto;
  padding: $padding !important;
  display: block;
  word-wrap: break-word;
}

.empty-list {
  padding: $padding $padding * 3;
  text-align: center;
  font-size: $font-large;

  h2 {
    font-size: $font-largest;
    font-weight: 400;
    color: $dark4;
  }


}

p:last-child {
  margin-bottom: 0
}

hr {
  margin: $padding * 2 0;
  border-color: $light2;
}

b,
strong {
  font-weight: 900;
}




q,
.quote {
  font-size: $font-huge * 0.77;
  text-align: center;
  display: block;
  font-weight: 400;
  line-height: 1.05;
  margin: 0 auto;

  @media (max-width: $size-mobile) {
    font-size: $font-largest * 1.1;
    line-height: $font-largest * 1.2;
  }
}


.font-small {
  font-size: $font-small;
}

.font-normal {
  font-size: $font-normal;
}


.not-link,
.not-link * {
  font-weight: 400 !important;
  text-decoration: none;
}


.background-emph{
  background: rgba(lighten($green1, 7), 0.25);
  color: $dark5;

  padding: $rad / 2 $spacing;
  line-height: 1;
}


.fa-w-16 {
  max-width: 16px !important;
  max-height: 16px !important;
}