.company-badge-wrapper {
    .section-header {
        margin-bottom: 0; // $padding;
        width: 100%;
        padding: $padding * 2 $padding;
        background: $green1;
        background: #1d976c;
        border-bottom: none; //solid 1px $light2;
        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));

        @media (max-width: $size-desktop) {
            @include border-radius($rad);
        }

        @media (max-width: $size-mobile) {
            margin-bottom: 0;
            padding: $padding * 4 $padding;
            padding-bottom: $padding * 2;
            border-bottom: none;
        }

        @media ((min-width: $size-mobile) and (max-width: $size-tablet)) {
            background: $light1 !important;
            padding: 0;
        }

        .hero {
            padding: $padding * 2 0;

            .page-subtitle {
                margin-bottom: 0;
            }
        }
    }


    .company-badge {
        padding: $padding * 2 0;

        @media (max-width: $size-mobile) {
            padding: $padding * 2 $padding * 1;

        }

        img {
            text-align: center;
            display: block;
            margin: 0 auto;
            margin-top: $padding * 3;
            min-width: $padding * 16;
        }


        p,
        li,
        a {
            font-size: $font-large !important;
            //     line-height: 1.5 !important;
        }

        .badge-code-wrapper {
            margin: $padding * 3 0;

            .badge-buttons {
                padding-top: $padding;

                .primary-btns {
                    @media (max-width: $size-mobile) {
                        text-align: center;

                        .btn {

                            width: 100%;
                        }
                    }

                }

                .secondary-btns {
                    @media (max-width: $size-mobile) {

                        text-align: center;
                        padding: $padding * 2 0;
                    }
                }

                a,
                button {
                    display: inline-block;
                    margin-right: $padding;
                    ;
                }

                .badge-code {
                    max-width: 100%;
                    word-break: break-all;
                    margin: 0;
                    background: white;
                    border: dashed 1px $dark1 !important;
                    background-color: rgba($light2, .10) !important;
                    overflow: visible;
                    width: 100%;
                    padding: $padding $padding * 3 !important;
                    font-size: $font-largish !important;
                    white-space: pre-wrap;
                    /* Ensures the code wraps within the container */
                    word-wrap: break-word;
                    /* Ensures long words break to fit within the container */
                }
            }
        }
    }
}