.article-page {


    .jobs-list {
        padding-bottom: 0;

        .job-tile {

            @media (max-width: $size-mobile) {
                padding-top: $padding * 1.25;
                padding-bottom: $padding * 1.25;

            }
        }
    }

    .auth-check-inbox {
        h2 {
            font-size: $font-largest * 1.5;
            margin: 0;
        }

    }


    .article-wrapper {
        padding-top: $padding;

        @media(max-width: $size-mobile) {
            padding: 0;
        }

        .article-content-wrapper {
            //   border-right: solid 1px $light3;
            display: inline-block;
            width: calc(100% - #{$padding * 24});

            padding-right: $padding * 3;

            @media(max-width: $size-desktop) {
                width: calc(100% - #{$padding * 15});
                padding: 0 $padding;
            }



            @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                padding: 0;
                width: 100%;

            }


            @media(max-width: $size-mobile) {
                width: 100%;
                border: none;
                padding: 0;
            }


            .article-image {
                margin: 0 auto;
                margin-bottom: $padding;
                width: 100%;
                height: auto;


                //@include border-radius(50%);
                /*  width: $padding * 12;
        height: $padding * 12;
        max-width: 100%;
        max-height: 100%;
        background-size: cover;
        margin-bottom: $padding * 2;


        @media(max-width: $size-mobile) {
            width: 100%;
            @include border-radius(0%);
        }*/
            }

            .article-back {
                margin-left: -$padding;


                @media(max-width: $size-mobile) {
                    margin-left: 0;
                }

            }



            .article-title {


                h1 {
                    font-size: $font-largest * 1.9;

                    @media(max-width: $size-mobile) {
                        padding: 0 $padding * 1.25;
                        font-size: $font-largest * 1.45;
                        line-height: $font-large * 2;
                        text-align: left;
                    }
                }

                .page-subtitle {
                    @media (max-width: $size-mobile) {
                        padding: 0 $padding * 1.25;
                    }
                }
            }

            .page-subtitle {

                margin-top: 0; //$padding;
                margin-bottom: $spacing; // $padding;
                font-size: $font-large * 1.0;
                line-height: $font-large * 1.4;
                //     font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;

            }

            .article-info {
                padding: $spacing 0 $padding * 1 0;


                @media(max-width: $size-mobile) {
                    text-align: center;
                }

                .tag {
                    background: lighten(rgba($light4, 0.6), 15);
                    padding: $rad * 1 $spacing * 1.25;
                    color: $dark4;

                    @include border-radius($rad);
                    font-size: $font-normal;

                    &:hover {
                        background: $pink;
                        color: $dark5;
                        color: $light1;
                    }
                }

                h3 {
                    display: inline-block;
                    font-size: $font-largish;
                    color: $dark1;
                    margin-top: 0;
                    line-height: 1;
                    margin-bottom: $spacing;

                    &:nth-child(1) {
                        &::after {
                            content: '·';
                            position: right;
                            padding-left: $spacing;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        margin-left: $spacing;
                    }

                }

            }

            .article-content {
                min-height: 4000px; //CLS meh

                .article-content-inner {

                    img {
                        margin: $padding * 1 0 $padding * 2 0;
                        max-width: 100%;
                    }

                    @media(max-width: $size-mobile) {
                        padding: 0 $padding * 1.5;

                        img {
                  //          margin-left: -$padding * 1.5;
                            @include border-radius(0px);
                            width: calc(100% + #{$padding * 3}) !important;
                            border-left: none !important;
                            border-right: none !important;
                        }

                        table {

                            width: calc(100% + #{$padding * 3}) !important;
                            margin-left: -$padding * 1.5 !important;
                            table-layout: fixed;
                            /* Forces the table to obey width constraints */
                            border-collapse: collapse;


                        }

                    }



                    pre {
                        padding: $padding;
                        border: solid 1px $light3;
                        margin-bottom: $padding * 2;
                        color: $dark4;
                        font-size: $font-normal;

                    }

                    table {
                        margin: $padding * 3 0;
                        width: 100%;
                        border-bottom: solid 1px $light2;

                        th {
                            font-size: $font-largish * 1.2;
                            padding: $spacing $spacing;
                        }

                        td {
                            font-size: $font-largish;
                        }
                    }

                    p {
                        margin-top: 0; //$padding;
                        margin-bottom: $padding * 1.5;
                        font-size: $font-largish * 1.15;
                        color: darken($dark2, 4);
                        line-height: $font-largish * 1.7;
                        font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;

                        a {
                            font-weight: 700;
                            color: lighten($green2, 8);

                            &:hover {
                                text-decoration: underline;
                            }
                        }


                        img {
                            width: 100%;
                            text-align: center;
                            //     @include border-radius($rad);
                            //   margin-top: $padding;
                            border: solid 1px $light3;


                        }
                    }

                    ol,
                    ul {
                        margin: 0 0 $padding * 2 $spacing;
                        padding-left: $padding;
                        font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;

                        li {

                            font-size: $font-largish * 1.2;
                            line-height: 1.5;
                            margin-bottom: $font-largish * 1.2;
                            color: darken($dark2, 4);

                            p {
                                margin-bottom: 0;
                            }

                            a {
                                color: $green2;
                                font-weight: 700;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }



                    }

                    blockquote {
                        margin: 0;
                        margin: $padding * 2 0 $padding * 3 0;
                        border: none;
                        background: lighten($light2, 2);
                        //    background: lighten(rgba($dark1, 0.15), 12);
                        @include box-theme();
                        padding: $padding * 3;

                        @media (max-width: $size-mobile) {
                            padding: $padding * 2;

                        }

                        ul {
                            li {
                                &:last-child {
                                    margin-bottom: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }

                        p {
                            padding: 0;
                            margin: 0;
                            color: $dark5;
                            line-height: normal;
                            font-size: $font-largish * 1.2;
                            line-height: 1.5;
                            margin-bottom: $font-largish * 1.2;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            @media (max-width: $size-mobile) {
                                font-size: $font-largish * 1.25;


                            }
                        }
                    }

                    h2 {
                        font-size: $font-largest * 1.35;
                        line-height: normal;

                        border-bottom: dotted 1px $light3;
                        padding-bottom: $spacing * 1.5;
                        margin-bottom: $spacing * 1.5;
                        margin-top: $padding * 4;

                        @media(max-width: $size-mobile) {
                            text-align: left;
                        }

                        a {
                            color: $green2;

                            font-size: $font-largish;
                            font-weight: 700;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }


                    h3 {

                        font-size: $font-large * 1.3;
                        line-height: 1.3;
                        color: $dark4;
                        font-weight: 700;
                        text-align: left;
                        margin-bottom: $spacing;
                    }

                    h4,
                    h5 {

                        font-size: $font-large * 1.1;
                        line-height: 1.3;
                        color: $dark5;
                        font-weight: 700;
                        text-align: left;
                        margin-bottom: 0;
                        padding-bottom: $rad * 2;
                    }

                    strong {
                        color: $dark5;
                    }


                }






                .article-bottom {
                    margin-top: $padding * 5;
                    padding: $padding * 2;


                }

                .article-top,
                .article-bottom {

                    .subscriber-green {
                        margin: $padding * 4 0;

                        @media (max-width: $size-mobile) {
                            border-left: none;
                            border-right: none;
                            padding: $padding * 2;
                            @include border-radius(0px);

                            .subscriber-form {
                                padding: 0;
                            }
                        }


                    }
                }

                .article-bottom {
                    padding: 0;
                }




            }

            .article-cta {
                padding: 0 $padding;

                .btn {

                    @media(max-width: $size-mobile) {
                        width: 100%;
                    }
                }
            }
        }






        .article-sidebar-wrapper {
            display: inline-block;
            vertical-align: top;
            width: calc(#{$padding * 24});
            padding-left: $padding * 1.5;
            position: sticky;
            top: $padding * 3;
            padding-top: $padding;
            padding-right: $padding * 2;
            height: calc(100vh - #{$padding * 2.25});

            overflow-y: auto;
            padding-bottom: $padding * 4;

            @media(max-width: $size-desktop) {
                width: calc(#{$padding * 15});
                padding: 0 $padding;
                padding-bottom: $padding * 4;
            }


            @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                width: 100%;
                padding: 0 $padding * 3;
                height: auto;

            }

            @media(max-width: $size-mobile) {
                // display: none;
                padding: $padding;
                width: 100%;
                height: unset;
                padding-bottom: $padding * 3;
                overflow: auto;
                position: relative;
            }

            .article-side-bar {
                .subscriber-social-proof {
                    display: none !important;
                }

                .article-about {
                    display: block;
                    margin-bottom: $padding * 3;


                    @media(max-width: $size-mobile) {
                        display: none;
                    }

                    @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                        display: none;

                    }

                    .article-about-top {
                        text-decoration: none !important;

                        p:hover,
                        h3:hover {
                            color: $green1;
                        }

                        .about-logo {
                            width: $padding * 3;
                            height: $padding * 3;
                            @include border-radius(50%);
                            max-width: 100%;
                        }

                        h3 {
                            font-size: $font-largish;
                            font-weight: 700;
                        }

                        p {
                            font-size: $font-normal;
                            line-height: 1.5;
                        }
                    }
                }

                .subscriber {
                    padding: $padding * 1.5;
                    margin-bottom: $padding * 3;


                    @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                        padding: $padding * 2.5;
                    }

                    .subscriber-full {
                        font-size: $font-largest * 1 * 1.2 !important;


                        @media (max-width: $size-mobile) {
                            font-size: $font-largest * 1.5 !important;
                            padding: 0;
                        }

                        @media (min-width: $size-mobile) and (max-width: $size-desktop) {

                            font-size: $font-largest * 1.6 !important;
                        }
                    }

                    .subscriber-resume {
                        display: none;
                    }

                    .subscriber-cta{
                        padding: 0;
                    }

                    .subscriber-h2{
                        font-size: $font-largest * 1.2;
                    }

                    .subscriber-form-desc {
                        font-size: $font-largish * 0.95;
                        padding: 0;
                        color: $dark3;
                        padding-bottom: $padding;


                        @media (max-width: $size-mobile) {
                            font-size: $font-largest * 1 !important;
                        }


                        @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                            font-size: $font-largest;
                        }
                    }

                    .subscriber-email {

                        .label-container input {
                            padding: $padding $spacing;
                            height: $padding * 3;
                            font-size: $font-normal;
                        }
                    }

                    .subscriber-btn {
                        width: $padding * 5;

                        .cta {
                            font-size: $font-largish;
                            height: $padding * 3;
                            width: 100%;
                        }
                    }

                    .subscriber-success {
                        padding-bottom: $padding;
                        ;

                        .col-sm-8 {
                            width: 100% !important;
                            margin: 0;
                            padding: 0;

                            h3 {

                                font-size: $font-largish * 0.97 !important;
                                line-height: 1.5;
                            }
                        }

                        .col-sm-4 {
                            display: none;
                        }

                    }
                }

                .article-related-articles {


                    h4 {
                        font-size: $font-largish;
                        font-weight: 400;
                        color: $dark1;
                        font-style: italic;
                        margin-bottom: $padding;
                    }

                    p {
                        font-size: $font-largish;
                        color: $dark2;
                    }

                    .related-articles {

                        margin: 0;
                        padding: 0;

                        .article-tile {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;
                            display: block;
                            padding-bottom: $padding * 1.5;

                            .tile-text {

                                h3 {
                                    color: $dark5;
                                    font-weight: 400;
                                    font-size: $font-largish * 1;
                                    line-height: 1.25;
                                }
                            }


                        }
                    }

                }

                .article-sponsored-by {
                    display: block;
                    border-top: solid 1px $light3;

                    margin: 0;
                    padding: 0;
                    margin-top: $padding;

                    padding-top: $padding * 2;

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }

                    &:hover {
                        cursor: pointer;

                        h3 {
                            color: $green2 !important;
                        }
                    }


                    h5 {
                        color: $dark1;
                        font-size: $font-small;
                    }

                    h4 {
                        color: $dark2;
                        margin: 0;
                        padding: 0;
                        font-size: $font-largish;
                        padding-bottom: $padding;

                    }

                    h3 {
                        position: relative;
                        color: $dark5;
                        font-weight: 700;
                        font-size: $font-largish * 1.1;
                        line-height: 1.25;
                        padding-right: $padding * 3;
                        ;

                        img {
                            position: absolute;
                            right: 0;
                            top: $rad;
                        }
                    }

                    p {
                        font-size: $font-largish * 0.9;
                        color: $dark2;
                    }

                    .article-sponsor-description {
                        margin: 0;
                        padding: 0;
                        display: block;

                    }

                    .article-sponsor-image {
                        margin: 0;
                        padding: 0;
                        display: block;

                    }

                }
            }
        }

    }
}


.article-page.career-path-page .article-wrapper .article-content-wrapper {


    h1 {
        margin-bottom: $spacing;

        @media (max-width: $size-mobile) {
            text-align: center;
            font-size: $font-huge;
            line-height: 1.15;
            padding: $padding * 1.5 $padding * 3 0 $padding * 1.5;
        }
    }

    .article-info {
        padding-bottom: $padding * 1.5;
    }
}