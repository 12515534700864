// Colours
.green {
    color: $green1 !important;
}

.green-light {
    color: lighten($green1, 10) !important;
}

.blue {
    color: $blue2 !important;
}

.blue-light {
    color: lighten($blue2, 25) !important;
}

.pink {
    color: $pink !important;
}

.red {
    color: $red1 !important;
}

.gold {
    color: $gold !important;
}