.login {

    padding: $padding * 5 0 !important;
    // margin: $padding * 5 auto;
    width: $padding * 32;
    max-width: 100%;

    @media (max-width: $size-mobile) {
        padding: $padding * 7 $padding !important;

    }

    .login-form {
        @include box-theme();
        @include box-shadow-lg();
        padding: $padding * 3.5 !important;
        text-align: center;
        margin-bottom: $padding * 2;
        overflow: visible;


        @media (max-width: $size-mobile) {
            border: none;
            padding: $padding * 0 !important;
            box-shadow: none;
            padding-bottom: $padding * 2 !important;
            width: 100% !important;
            overflow: visible;

            iframe {
                width: 100% !important;
                overflow: visible;
                border-right: solid 1px $light2 !important;
                @include border-radius($rad);
            }
        }

        h1 {
            font-size: $font-huge * 0.9;
            line-height: 1;
            margin-bottom: $spacing;
        }

        h3 {
            margin-bottom: $padding * 2;
            line-height: 1.35;
            color: $dark2;
        }

        .field-row {
            padding: 0;
            margin-bottom: 0;
            position: relative;

            .error {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                padding-bottom: $rad;
            }

        }

        .btn {

            font-size: $font-largest * 1;
            padding: $padding * 1.25 $padding * 1.5;
            width: 100%;
        }


    }

    .login-signup {
        font-size: $font-large;
        text-align: center;
    }


}