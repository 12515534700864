.post-job-sidebar-reviews {
    padding: $padding * 3;
    //border-bottom: solid 1px lighten($light3, 8);

    .post-job-review {

        margin: 0;
        padding: 0;
        margin-bottom: $padding * 4;

        img {
            @include border-radius(50%);
            display: block;
            max-width: $padding * 9;

            max-height: $padding * 9;
            margin: 0 auto;
        }

        blockquote {
            margin: 0;
            padding: 0;
            border: none;



            p {
                font-size: $font-largest * 0.93;
                line-height: 1.5;
                text-align: center;
                color: $dark1;
                padding: $padding * 1.5 0 $padding * 1 0;
                margin: 0;

                .emph {
                    font-weight: 600;
                    background: rgba($gold, 0.35);
                    color: darken($gold, 40);
                }
            }

            cite {
                font-size: $font-large * 0.95;
                margin: 0 auto;
                padding: 0;
                text-align: center;
                font-style: normal;
                color: $dark1;
                display: block;

                a {
                    border-bottom: solid 1px $pink;
                    color: $pink;
                    font-weight: 600;
                    text-decoration: none;

                    &:hover {
                        color: darken($pink, 10%);
                        border-bottom: none;
                    }
                }

            }
        }

        .post-job-review-stars {
            text-align: center;
            padding: 0 0 $spacing 0;
            margin: 0;

            .svg-inline--fa {
                font-size: $font-largest * 1.2;
                color: $gold;
            }

        }
    }
}