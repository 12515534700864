.subscriber {
    //padding: $padding 0;


    // max-width: $padding * 35;

    &.subscriber-green {

        @include box-theme();
        padding: $padding * 3;
        @include box-shadow-success();
        border: solid 1px lighten($green1, 15);
        background-color: rgba(lighten($green1, 18), 0.25);

        @media (max-width: $size-mobile) {
            padding: $padding * 2 $padding * 1;

        }

        @media (min-width: $size-mobile) and (max-width: $size-tablet) {
            padding: $padding * 1.75;

        }



        .subscriber-form {
            @media (max-width: $size-mobile) {
                padding: 0;
                padding-top: $padding;
                ;
            }

            .subscriber-form-desc {
                color: $dark3;
            }

            .subscriber-resume {
                border: solid 1px $dark1;
                overflow: hidden;

                &.resume-collapsed {
                    .label-checkbox {
                        background-color: transparent;
                    }
                }

                .label-checkbox {
                    background-color: $light1;

                    &:hover {
                        background-color: $light1;
                    }

                    label {
                        color: $dark3;
                    }
                }
            }

            .fileupload {
                label {
                    border-color: $dark3;

                    .fileupload-helper {
                        color: $dark5 !important;
                    }

                    .fileupload-browse {
                        background: $dark2;
                        border: none;
                        color: $light1;
                    }

                    &:hover {
                        border-color: $green2;
                        //     background-color: rgba(lighten($green1, 18), 0.28);
                        //     @include box-shadow-success();
                    }
                }
            }


        }
    }

    .subscriber-success {
        // padding: $padding * 2;
        //border: solid 1px $light3;
        padding-top: $padding * 1;
        @include border-radius($rad);

        .social-image {
            height: $padding * 5;
            width: $padding * 5;
        }

        h3 {
            font-size: $font-largest * 1.1;
            padding-bottom: $spacing;
        }

        @media (max-width: $size-mobile) {
            text-align: center !important;

            h3 {
                text-align: center !important;
            }
        }

        .btn-default {
            background: rgb(29, 155, 240);
            color: $light1;

            .svg-inline--fa {
                margin-left: $spacing;
            }
        }


        img {
            max-width: 100%;
            @include border-radius(50%);

            @media (max-width: $size-mobile) {
                margin-top: $padding * 2;
                max-width: $padding * 10;
            }


        }
    }

    .subscriber-h1 {
        font-size: $font-huge * 1.2;
        color: $dark5;
        display: block;
        padding-bottom: 0;
        margin-bottom: $padding;
        line-height: 1;
        text-align: left;
        font-weight: 900;

        @media (min-width: $size-mobile) and (max-width: $size-tablet) {

            font-size: $font-huge * 1;
        }
    }

    .subscriber-h2 {
        font-size: $font-huge * 0.8;
        color: $dark5;
        display: block;
        padding-bottom: $rad ;
        line-height: 1.1;
        text-align: left;
        font-weight: 900;

        @media (min-width: $size-mobile) and (max-width: $size-tablet) {

            font-size: $font-largest * 1.25;
        }
    }

    h4 {
        font-size: $font-largest;
        text-align: left;
        margin: 0;
        line-height: 1.1em;
    }

    .subscriber-image {
        padding: 0 $padding * 2;
        max-width: 100%;
    }


    .subscriber-form {

        .label-container {


            .error {
                //      position: absolute;
                //      bottom: -$rad;
            }
        }

        .desktop-cta {
            @media (max-width:$size-mobile) {
                display: none;
            }
        }

        .mobile-cta {
            width: 100%;

            @media (min-width:$size-mobile) {
                //     display: none;
            }
        }

        .subscriber-form-desc {
            margin: 0;
            padding: 0;
            padding-bottom: $padding * 1.5;
            line-height: 1.5;

            font-size: $font-largest * 0.95;
            font-weight: 400;
            opacity: 0.8;
            color: $dark2;

            @media (max-width: $size-mobile) {
                font-size: $font-largest * 0.85;
            }

            @media (min-width: $size-mobile) and (max-width: $size-tablet) {

                font-size: $font-largest * 0.9;
            }


            .emph {
                color: $dark5 !important;
                font-weight: 700;
                padding: 0;
                // background: rgba($pink, 1);
            }
        }

        .subscriber-email {
            padding: 0;
            margin: 0;
            width: 100%;
            // padding-right: $padding;
            //  display: inline-block;

            @media (max-width: $size-mobile) {
                width: 100%;
            }


            display: inline-block;

            .field-row {
                padding-bottom: 0;

                input[type="email"] {
                    background-color: $light1; //lighten($light2, 6);
                    line-height: $padding * 3.5;
                    color: $dark5;
                    @include inner-box-shadow();
                    padding-left: $padding;
                    height: $padding * 3.5;
                    border-color: $dark1;

                    &:focus {
                        border-color: $green1;
                        @include box-shadow();
                    }


                    @media (max-width: $size-mobile) {
                        @include border-radius($rad);
                        border: solid 1px $dark1;
                    }
                }
            }
        }


        .subscriber-cta {
            padding: 0 0 $padding 0;

            @media (max-width: $size-mobile) {
                padding-bottom: $padding;
            }
        }



        .subscriber-resume {
            //background: red;
            padding-left: 0;
            @include border-radius($rad);
            border: solid 1px $light2;
            display: inline-block;
            margin-bottom: $padding;

            @media (max-width: $size-mobile) {
                width: 100%;
                margin-bottom: $padding * 0.75 !important;
            }

            &.resume-collapsed {
                border: 1px solid transparent;
                

                .label-checkbox {
                    padding: 0; //$spacing 0;

                    &:hover {
                        background: none;
                    }

                    label {
                        padding: 0;
                        padding-left: $padding * 1;
                    }


                    input[type="checkbox"] {

                        margin: 0;

                    }
                }
            }

            // Full checkbox component (when shown)
            .label-checkbox {
                padding-bottom: 0;
                max-width: $padding * 26;
                width: 100%;

                &:hover {
                    background: lighten($light2, 5);
                }

                label {
                    padding: $spacing $padding;
                    padding-left: $spacing;

                }

                input[type="checkbox"] {
                    //    margin-left: 0;
                    margin-top: $spacing;
                    margin-right: $padding * 0.25;
                    margin-left: $padding * 0.75;

                }
            }

            .label-file {
                padding-bottom: 0;
            }



            .field-row {
                padding-bottom: 0;

                .fileupload {
                    background-color: lighten($light2, 8);

                    label {
                        width: 100%;
                        border: none;
                        @include border-radius(0);
                        border-top: solid 1px $light2;
                        padding: $padding * 2;


                        @media (max-width: $size-mobile) {
                            max-width: 100%;


                        }
                    }
                }
            }

            label {
                font-size: $font-large * 0.98;
                padding-bottom: 0;
                color: $dark2;
                line-height: 1.2;
            }
        }
    }

    .subscriber-social-proof {
        width: $padding * 33;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-bottom: 0;
        padding-top: $padding * 2;


        @media (max-width: $size-mobile) {
            padding-top: $padding * 1.5;
            width: 100%;
        }

        .subscriber-social-proof-images {
            text-align: right;
            padding-right: $spacing;

            @media (max-width: $size-mobile) {
                padding-right: $rad;
            }

            .subscriber-social-proof-img {
                width: $padding * 2.5;
                height: $padding * 2.5;
                @include border-radius(50%);
                margin-left: -$spacing * 1.5;
                border: solid 3px $light1;

                @media (max-width: $size-mobile) {
                    width: $padding * 2;
                    height: $padding * 2;
                    margin-left: -$padding * 0.75;

                    &:nth-child(5),
                    &:nth-child(4) {
                        display: none;
                    }
                }


            }
        }

        .subscriber-social-proof-text {

            font-size: $font-largish;
            color: $dark1;

            @media (max-width: $size-mobile) {
                max-width: $padding * 11;
            }

            @media (min-width: $size-mobile) and (max-width: $size-tablet) {
                font-size: $font-largish * 0.9;

            }
        }

        .subscriber-social-proof-arrow {
            margin-left: 0;
            opacity: 1;
            max-height: $padding * 3;

            @media (max-width: $size-mobile) {
                max-width: $padding * 3.5;
            }
        }
    }


    .subscriber-form-loading,
    .subscriber-form-success {
        text-align: left;
        font-size: $font-largest * 1.1;
        color: $dark5;
        padding: $padding * 1 0;
    }

    .subscriber-form-loading {
        color: $dark1;
        font-style: italic;

        display: flex;
        align-items: center;

    }

    .subscriber-form-success {
        padding-top: $padding * 2.5;
    }
}