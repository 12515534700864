.wrapper .content-wrapper .main-container-wrapper .main-container .hero .exit-popup .subscriber-popup.subscriber {

    .subscriber-full {
        font-size: $font-huge * 1.3;
        color: $dark5;

        @media (max-width: $size-mobile) {
            font-size: $font-huge * 0.9;
            ;
        }
    }

    .subscriber-form-desc {}

 
}