.country-page {

    .article-author {
        @media (max-width: $size-mobile) {
            display: none;

        }

    }


    .country-header-wrapper {
        margin-bottom: $padding;
        width: 100%;
        padding: $padding * 2 $padding;
        padding-top: $padding;
        background: $green1;
        background: #1d976c;
        /* fallback for old browsers */

        border-bottom: none; //solid 1px $light2;
        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));

        //  min-height: 320px; // CLS
        @media (max-width: $size-mobile) {
            //  min-height: 520px; // CLS
        }

        @media (min-width: $size-mobile) and (max-width: $size-tablet) {
            padding: $padding;
            background: none;
            border-bottom: solid 1px $light2;
        }

        .country-header-content {
            max-width: $size-desktop;
            margin: 0 auto;

            @media (max-width: $size-mobile) {
                margin-top: $padding * 4;
            }

            .country-header {
                padding-top: $spacing;

                .cta {
                    margin-top: $padding;
                }
            }

            .country-name {
                margin-bottom: $spacing;
            }

            .country-desc {
                color: $dark1;
                font-weight: 400;
                font-size: $font-largest;
                margin-bottom: $padding;
                line-height: $font-largest * 1.25;
            }

            .tags {
                padding: 0;
                margin: 0;

                li {

                    //  border: none;
                    &.offered,
                    &.salary-100 {
                        //    color: darken($dark3, 5%);
                        border: solid 1px $green1; //: rgba(215, 255, 225, 1);
                        color: $green1;
                    }

                }
            }

            .country-image {
                margin-right: $spacing * 1.5;
                max-width: $padding * 10;

                @media (max-width: $size-mobile) {
                    margin: 0;
                    margin-bottom: $padding;
                    height: $padding * 4;
                    width: $padding * 4;

                }

            }




        }

    }

    .country-content {

        max-width: $size-desktop;
        margin: 0 auto;

        padding-top: $padding;


        @media (max-width: $size-desktop) {
            padding: 0 $padding;

        }

        @media (max-width: $size-mobile) {
            padding: 0 $padding;

        }

        .country-companies {
            .row>h3 {
                font-size: $font-large;
                font-weight: 900;
                text-align: center;
            }
        }

        .country-four-day-week-description {
            margin-bottom: 0;
            padding-bottom: $padding !important;
        }

        .country-description,
        .country-four-day-week-description {
            margin-bottom: 0;
            padding-bottom: $padding * 3;

            @media (max-width: $size-mobile) {
                padding-top: $padding;
            }

            h2 {
                font-size: $font-largest * 1.65;
                font-weight: 900;
                line-height: 1.25;
                margin-bottom: $padding * 1.5;
            }

            h4 {
                font-size: $font-largest;
                font-weight: 700;
                color: $dark4;
            }


            h3 {
                font-size: $font-largest * 1.25;
                margin-bottom: $padding;
                font-weight: 900;
                line-height: 1.5;

                //  border-bottom: dotted 1px $light3;

                &:first-child {
                    margin-top: 0;
                }
            }

            a {
                text-decoration: underline;
                font-weight: normal;

                &:hover {
                    text-decoration: none;
                    ;
                }
            }

            ul {
                margin: $spacing 0;
                padding: 0;
                margin-left: $padding * 2;
                margin-bottom: $padding * 1.5;

                li {
                    margin-bottom: $spacing;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            img {
                max-width: 100%;
            }

            p {
                font-size: $font-largish;
            }
        }



        .subscriber-green {
            margin-bottom: $padding * 3;
        }




    }

    .companies-list {
        padding-left: 0;

        @media (max-width: $size-mobile) {
            margin-left: -$padding;
            margin-right: -$padding;
        }

        >li {
            list-style: none;
        }

        .nottavert-tile-wrapper {
            display: none;
        }

        .company-tile-wrapper .company-tile {
            .company-tile-top {
                display: block;
            }

            .pto {
                display: none !important;
            }


            .company-tile-title {
                width: 100%;
            }

            .company-tile-top {
                .company-score {
                    display: none;
                }
            }

            .reduced_hours {
                display: none;
            }

            .company-name-desc {
                -webkit-line-clamp: 2;
                /* number of lines to show */
                line-clamp: 2;
                overflow: hidden;
                padding-bottom: $spacing;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-height: 1.9;
            }


            .company-tile-bottom {

                display: block;

                .company-num-employees,
                .company-num-jobs,
                .company-location {
                    display: none;
                }

                .company-tile-left {

                    .company-num-employees,
                    .company-location {
                        display: none;
                    }
                }





                .company-tile-right {
                    width: 100%;
                    text-align: left;
                    display: none;

                    .company-fdw {

                        .fa-w-16 {
                            display: none;
                        }

                        text-align: left;
                    }
                }
            }
        }
    }
}



.countries-page {
    .countries-list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }

    .nottavert-tile-wrapper {
        margin-bottom: $padding * 1;
        border-bottom: solid 1px $light3;
        @include border-radius($spacing);

        @media (max-width: $size-mobile) {
            margin-bottom: 0;
            border-bottom: none;
        }



    }
}