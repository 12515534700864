.cover-letter-landing {
    color: $dark5;

    .content {
        padding: $padding;
        margin-block: $padding * 5;

        @media screen and (max-width: $size-mobile) {
            margin-block: $padding * 2;
        }
    }
}

.cover-letter-hero {
    .content {
        text-align: center;
        max-width: $size-tablet;
        margin-inline: auto;
        padding-top: $padding * 2;
        padding-bottom: 0;
    }

    h1 {
        font-weight: 900;
        font-size: $font-huge * 1.3;
        line-height: $font-huge * 1.4;
        color: $dark5;
        margin: $padding * 2 0;

        @media (max-width: $size-mobile) {
            font-size: $font-huge * 1.1;
            line-height: $font-huge * 1.2;
        }

        span {
            display: block;

            &:last-child {
                color: $green1;
            }
        }
    }

    p {
        margin: 0;
        width: 100%;
        font-size: $font-large * 1.4;
        line-height: $font-large * 1.8;

        @media (max-width: $size-mobile) {
            font-size: $font-large * 1.2;
            line-height: $font-large * 1.5;
        }
    }
}

.cover-letter-form {
    width: 100%;
    
    .content {
        max-width: $size-desktop;
        padding-top: $padding * 2;
    }
    
    .form-container {
        display: flex;
        gap: $padding * 3;
        margin-top: $padding * 2;
        
        @media screen and (max-width: $size-tablet) {
            flex-direction: column;
        }
        
        .input-section, .output-section {
            flex: 1;
            border-radius: $rad;
            padding: $padding * 2;
            background-color: $light1;
            box-shadow: 0 4px 0 0 $green2;
            
            @media screen and (max-width: $size-mobile) {
                padding: $padding;
            }
        }
        
        .form-group {
            margin-bottom: $padding * 2;
            
            label {
                display: block;
                margin-bottom: $spacing;
                font-weight: 600;
                font-size: $font-large;
            }
            
            .form-control {
                width: 100%;
                padding: $padding;
                border: 1px solid $light3;
                border-radius: $rad/2;
                font-size: $font-large;
                
                &:focus {
                    outline: none;
                    border-color: $green1;
                    box-shadow: 0 0 0 2px rgba($green1, 0.2);
                }
            }
            
            .resume-upload-box {
                border: 2px dashed $light3;
                border-radius: $rad/2;
                padding: $padding;
                text-align: center;
                position: relative;
                cursor: pointer;
                transition: all 0.2s ease;
                
                &:hover {
                    border-color: $green1;
                    background-color: rgba($green1, 0.05);
                }
                
                input[type="file"] {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
                
                .upload-icon {
                    font-size: $font-huge;
                    color: $green1;
                    margin-bottom: $spacing;
                }
                
                p {
                    margin: 0 0 $spacing 0;
                    font-size: $font-large;
                }
                
                small {
                    color: $dark2;
                }
            }
            
            .resume-detected {
                margin-top: $spacing;
                color: $green1;
                font-size: $font-normal;
                
                i {
                    margin-right: $spacing/2;
                }
            }
        }
        
        .privacy-note {
            margin-top: $padding;
            color: $dark2;
            font-size: $font-normal;
            font-style: italic;
            
            i {
                margin-right: $spacing/2;
            }
        }
        
        .output-section {
            h3 {
                font-size: $font-large * 1.2;
                font-weight: 600;
                margin-top: 0;
                margin-bottom: $padding;
                text-align: center;
            }
            
            .generating-indicator {
                text-align: center;
                padding: $padding * 2;
                
                .spinner {
                    display: inline-block;
                    width: $padding * 3;
                    height: $padding * 3;
                    border: 3px solid rgba($green1, 0.3);
                    border-radius: 50%;
                    border-top-color: $green1;
                    animation: spin 1s ease-in-out infinite;
                }
                
                p {
                    margin-top: $padding;
                    color: $dark3;
                }
            }
            
            .cover-letter-output {
                background-color: $light2;
                border-radius: $rad/2;
                padding: $padding * 1.5;
                height: 400px;
                overflow-y: auto;
                
                pre {
                    font-family: Arial, sans-serif;
                    white-space: pre-wrap;
                    margin: 0;
                    font-size: $font-normal;
                    line-height: 1.6;
                }
            }
            
            .output-actions {
                display: flex;
                gap: $padding;
                margin-top: $padding;
                
                button {
                    flex: 1;
                    
                    i {
                        margin-right: $spacing/2;
                    }
                }
            }
            
            .empty-state {
                text-align: center;
                padding: $padding * 5 $padding * 2;
                color: $dark2;
                
                .empty-icon {
                    font-size: $font-huge * 2;
                    margin-bottom: $padding;
                    opacity: 0.3;
                }
                
                p {
                    margin: 0;
                    font-size: $font-large;
                }
            }
        }
    }
}

.cover-letter-features {
    background-color: $light1;
}

.cover-letter-how-it-works {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
    background: linear-gradient(to bottom, $light1, lighten($light2, 3));

    .content {
        max-width: $size-desktop;
        margin: $padding * 10 0;
        flex-flow: row wrap;
    }

    h2 {
        font-size: $font-huge * 1.2;
        line-height: $font-huge * 1.5;
        font-weight: 900;
        margin-bottom: $padding * 2;
    }

    h6 {
        font-size: $font-large * 1.3;
        line-height: $font-large * 1.6;
        margin: 0;
        color: $dark1;
        font-weight: 400;
    }

    .steps {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0;
        margin-top: $padding * 3;
    }

    .step-wrapper {
        padding: $padding;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 3);

        @media screen and (max-width: $size-mobile) {
            flex-direction: row;
            width: 100%;
        }

        .step {
            border-radius: $rad;
            border: 2px solid $green1;
            padding: $padding * 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $light1;
            box-shadow: 0px 4px 0px 0px $green2;

            h4 {
                font-size: $font-large * 1.2;
                line-height: 1;
                font-weight: 500;
                color: $light1;
                background-color: $green1;
                margin: 0;
                padding: 0;
                border-radius: 100%;
                width: $padding * 2.5;
                height: $padding * 2.5;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: $padding * 1;
            }

            p {
                font-weight: 400;
                text-align: center;
                font-size: $font-large * 1.1;
                color: $dark5;
                line-height: $font-large * 1.5;
            }
        }
    }
    
    .cover-letter-animation {
        margin-top: $padding * 5;
        
        img {
            max-width: 100%;
            height: auto;
            border-radius: $rad;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }
    }
}

.cover-letter-testimonial {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .content {
        max-width: $size-desktop;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    h2 {
        font-size: $font-huge * 1.2;
        line-height: $font-huge * 1.5;
        font-weight: 900;
        margin-bottom: $padding * 4;
    }
    
    .ratings {
        display: flex;
        gap: $padding * 3;
        margin: $padding * 3 0;
        
        @media screen and (max-width: $size-mobile) {
            flex-direction: column;
            gap: $padding;
        }
        
        .rating {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .rating-source {
                font-weight: 600;
                font-size: $font-large;
                margin-bottom: $spacing;
            }
            
            .rating-stars {
                color: gold;
                font-size: $font-large;
                margin-bottom: $spacing;
            }
            
            .rating-score {
                font-weight: 600;
                font-size: $font-large;
            }
        }
    }
    
    .final-cta {
        background-color: $light2;
        border-radius: $rad;
        padding: $padding * 3;
        margin-top: $padding * 3;
        width: 100%;
        max-width: $size-tablet;
        box-shadow: 0 4px 0 0 $green2;
        
        h3 {
            font-size: $font-large * 1.3;
            font-weight: 600;
            margin-bottom: $padding * 2;
        }
    }
}

@keyframes spin {
    to { transform: rotate(360deg); }
} 