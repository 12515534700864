.article-notad-alt-tile-wrapper {
    margin: 0 auto;
    display: block;
    position: relative;
    padding: 0;
    overflow: visible;
    margin: $padding * 6 0;

    //  @include box-shadow-light();

    &:hover, &:focus, &:active{
        background-color: $light1;
        text-decoration: none !important;
    }


    &::before {
        content: "Sponsored";
        position: absolute;
        background-color: lighten($light2, 8);
        border: solid 1px lighten($light2, 3);
        color: $dark3;
        padding: $rad / 2 $rad * 2;
        @include border-radius($rad);
        bottom: $padding * 0;
        right: 0;


        @media (max-width: $size-mobile) {
            right: $padding;
            top: $padding * 1.5;
            bottom: unset;
        }
    }


    @media (max-width: $size-mobile) {
        padding: $spacing;
    }





    .article-notad-alt-tile-content-wrapper {

        width: 50%;
        display: inline-block;



        @media (max-width: $size-mobile) {
            width: 100%;
            display: block;
            padding: $padding;
        }

        h4 {
            font-size: $font-largish * 1.15 !important;
            color: $dark3;
            margin: 0;
            padding: 0;
            padding-bottom: $spacing;
            color: $green1;
        }

        h5 {
            font-size: $font-largest * 1.5 !important;
            color: $dark5;
            margin: 0;
            padding: 0;
        }

        .btn {
            margin: 0;

        }

        h6 {
            display: inline-block;
            line-height: $control-height;
            vertical-align: middle;
            padding: 0;
            padding-left: $spacing;
            color: $green1;
            margin: 0;
            font-style: italic;
        }

        p {
            color: $dark3;
            font-size: $font-largest * 0.9;
            line-height: 1.5;
            margin: 0;
            padding: 0;
        }

        .article-notad-alt-tile-tags {
            margin: 0;
            padding: 0;
            padding-top: $padding * 1.5;
            display: block;

            @media (max-width: $size-mobile) {
                margin-bottom: $padding;
            }


            li {
                list-style: none;
                margin-bottom: 0;
                display: inline-block;
                color: $dark1;
                font-size: $font-largish * 1;
                margin-right: $padding * 1.5;

                &:last-child {
                    margin-right: 0;
                }




            }
        }


    }


    .article-notad-alt-tile-image-wrapper {
        width: 50%;
        display: inline-block;
        vertical-align: top;

        @media (max-width: $size-mobile) {
            width: 100%;
            display: block;
            padding: $spacing;
        }

        .article-notad-alt-tile-image {
            @include border-radius($rad);
            width: 100%;


        }

        .article-notad-alt-meta {
            padding-top: $spacing;



            .article-notad-alt-rating {
                margin: 0 auto;
                text-align: center;
                max-width: $padding * 8;
                display: inline-block;
                margin-top: -$rad;
                width: 45%;


                @media (max-width: $size-mobile) {
                    width: auto;
                }

            }


        }


    }


}