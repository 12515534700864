.profile-form {


    .form-meta {

        h4 {
            font-size: $font-largest;
            margin:0;
            font-weight: 600;
            color: $dark3;
        }

        p{
            padding-bottom: $padding * 1;
        }



    }

    .form-section {
        margin-bottom: $padding * 3;

        &:last-child{
            margin-bottom: $padding;
        }

        .form-fields {

            .field-row {
                padding: 0;
                margin-bottom: 0;


            }
        }
    }


    .btn {
        font-size: $font-largest * 1.1;
        padding: $padding * 1.5 $padding * 2;
    }


    .user-edit-footer {
        text-align: center;
        font-size: $font-normal;
        padding-top: $padding * 3;
        color: $dark1;
    }
}