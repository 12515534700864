.critique-notification-tile-wrapper {
    max-width: $size-mobile;
    margin: 0 auto $spacing auto;
    position: relative;


    @media (max-width: $size-mobile) {
        margin-bottom: 0;
    }


    .critique-notification-content {

        background: $pink;
        position: relative;
        border: solid 1px darken($pink, 8);
        padding: $padding * 0.75 $padding;
        @include border-radius($rad);
        padding-left: $padding * 2;
        display: flex;
        flex-direction: row;
        align-items: center;


        @media (max-width: $size-mobile) {
            border: none;
            padding:  $padding * 0.75 $padding;
            @include border-radius(0);
        }


        .critique-notification-title {
            width: 60%;

            h4 {
                padding: 0;
                padding-right: $spacing;;
                color: $light1;
                margin: 0;
                font-size: $font-large;
                font-weight: 600;
            }
        }

        .critique-notification-cta {
            width: 40%;
            text-align: right;


            @media (max-width: $size-mobile) {
                text-align: left;
            }


            .btn {
                background-color: $light1;
                color: $dark4;

                &:hover,
                &:focus {
                    background: lighten($green1, 5);
                    color: $light1;
                }

            }
        }


        .critique-notification-close {
            position: absolute;
            top: -$padding * 0.9;
            right: -$padding * 0.9;
            @include border-radius(50%);
            cursor: pointer;
            padding: 0;
            width: $padding * 1.8;
            height: $padding * 1.8;
            border: solid 1px $dark2 !important;
            color: $dark5;
            text-align: center;
            line-height: $padding * 1.8;
            font-size: $font-large;
            font-weight: 600;
            background: $light1;

            @media (max-width: $size-mobile) {
                top: $padding * 1.1;
                right: $padding * 1.1;
                border: none !important;
                opacity: 0.75;
            }


            &:hover {
                background: $dark5;
                color: $light1;
            }
        }
    }



}