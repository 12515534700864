// Sizes
@mixin input_sm() {
  font-size: $font-normal;
  line-height: $control-height;
  font-weight: 400;

  &.has-icon {
    padding-left: $padding * 2;
  }

  @media (max-width: $size-mobile) {
    font-size: $font-large;
    line-height: $control-height * 1.25;
  }
}

@mixin input_lg() {
  font-size: $font-large;
  height: $control-height * 1.5;
  font-weight: 400;

  &.has-icon {
    padding-left: $padding * 2.5;
  }
}

// Inputs
input,
select,
textarea,
.fake-input {
  @include basic_input();

  &.sm {
    @include input_sm();
  }

  &.lg {
    @include input_lg();
  }

}

select {
  &:hover {
    cursor: pointer;
  }
}

textarea {
  height: $padding * 18;
  padding: $padding;

  &.smaller {
    height: $padding * 10;
  }
}

// Hide number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

  max-width: $padding * 5;
  text-align: center;
}


// Field row
.field-row {

  padding-bottom: $padding;
  min-width: $padding * 8;

  &.no-padding .label-container {
    padding-bottom: 0 !important;
  }

  @media (max-width: $size-mobile) {
    .cols {
      margin-bottom: $rad;
    }
  }

  &.checkbox-left {
    input {
      float: left !important;
    }

    label {
      //   float: right;
    }
  }

  label {
    font-size: $font-largish;
    font-weight: 400;
    margin-bottom: 0;

    &.small {
      font-weight: 400;
      font-size: $font-normal;

      &:hover {
        cursor: pointer;
      }
    }


  }


  .label-container {

    &.label-checkbox {
      label {
        &:hover {
          cursor: pointer;
        }
      }
    }

    label {
      font-size: $font-largest * 0.85;
      padding-bottom: $rad * 1.5;

      .required {
        color: $red1;
        padding-left: $rad;
      }

      .optional {
        color: $dark2;
        font-size: $font-small;
        padding-left: $rad * 3;
      }
    }



    &.single-col {
      text-align: left;
      padding-bottom: $padding * 1.25;

      input[type=number] {
        display: block;
      }

      input[type=checkbox] {
        //  display: inline-block;
        //    margin-left: $padding;
      }

      .error {
        padding-left: 0;
        padding-top: 0;
      }

    }

  }

  p.small {
    line-height: $padding;
    font-size: $font-normal;
    vertical-align: middle;
    color: $dark1;
    margin-bottom: $spacing;

    @media (max-width: $size-mobile) {
      padding-top: $spacing;
    }
  }

  .error {
    padding-top: $spacing;
    color: $red2;
    font-weight: 400;
    font-size: $font-normal;
  }

  input[type=checkbox],
  input[type=radio] {
    width: $padding * 1.25;
    height: $padding * 1.25;
    margin-right: $spacing;
    margin-top: 0;

    line-height: $padding;
    vertical-align: sub;

    &:hover {
      cursor: pointer;
    }
  }

  input[type=radio] {
    margin-left: 0;
    margin-right: $spacing;

  }

  input[type=file] {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: $font-large * 0.95;

  }

  .radio-meta {
    display: inline-block;
    color: $dark1;
    font-size: $font-small;
    padding-left: $spacing;
  }

  &:last-child {
    padding-bottom: 0;

  }
}






.field-array {
  .cols {
    padding: 0;

    .cols-sm {
      margin: 0;
      padding: 0 $rad 0 0;
    }
  }
}



.fileupload {
  input {
    opacity: 0;
    width: 100%;
    height: 0;
    display: none;

  }

  label {
    position: relative;
    border: 1px dashed $light3;
    width: 100%;
    border-radius: $rad;
    padding: $padding * 2 !important;
    text-align: center;

    &:hover {
      //  border-color: transparent;
      //   @include box-shadow-light();
      border-color: $dark1;
      cursor: pointer;
    }
  }

  .fileupload-drop-file {
    display: block;
    font-weight: 700;
    color: #07074d;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .fileupload-icon {
    font-size: $font-largest * 1.2;
    color: $dark1;

    .svg-inline--fa {
      margin-bottom: $padding / 2;
    }
  }

  .fileupload-helper {
    color: $dark1;
    display: block;
    padding-bottom: $padding;
  }

  .fileupload-browse {
    font-weight: 400;
    font-size: $font-large;
    color: $dark3;
    display: inline-block;
    padding: $spacing * 1.25 $padding * 1.25;
    border: 1px solid $light3;
    border-radius: $rad;
  }

  .uploaded-files {
    p {
      padding: $padding;
      margin-top: -$padding;
      position: relative;
      font-style: italic;
      text-align: center;
    }
  }
}