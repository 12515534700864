.article-page.interview-questions {
    .article-title {


        .article-info {
            padding-top: 0;
        }

        .page-subtitle{
            padding-bottom: $padding !important;
        }
    }


}