.jobcopilot-ad {
  display: flex;
  align-items: flex-start;
  padding: $padding * 2 $padding * 2;
  margin-top: $padding * 3;
  background-color: rgba($pink, 0.15);
 // border: 2px solid rgba($pink, 1);
  border-radius: 6px;
  color: #fff; // ensures text is legible on pink

  .jobcopilot-ad-icon {
    font-size: $font-huge * 0.9;
    margin-right: $padding * 2;
    display: flex;
    align-items: center;
    justify-content: center;

    // if you want to style the icon color specifically
    svg {
      color: rgba($pink, 1);
    }
  }

  .jobcopilot-ad-content {
    // you can tweak spacing as desired

    .jobcopilot-ad-content-title {
      margin: 0;
      font-size: $font-largest;
      font-weight: 600;
      margin-bottom: $padding * 0.5;
    }

    .jobcopilot-ad-content-description {
      margin: 0 0 $padding * 1.5;
      font-size: $font-large;
      color: rgba($dark5, 0.9);
    }

    .jobcopilot-ad-content-cta {
      background-color: $pink;
      color: #fff;
      font-size: $font-large;
      &:hover {
        background-color: lighten($pink, 10%);
        color: #fff;
      }
    }
  }
}