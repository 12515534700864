.article-author {
    margin-top: $padding;
    padding: $padding * 4 0;
    border-top: solid 1px $light3;

    .author-img {
        width: $padding * 6;
        display: inline-block;
        padding-right: $padding;
        vertical-align: top;

        img {
            @include border-radius(50%);
            max-width: 100%;
        }

        @media (max-width: $size-mobile) {
            display: none;
        }
    }

    .author-description {
        width: calc(100% - #{$padding * 6});
        display: inline-block;

        @media (max-width: $size-mobile) {
            width: 100%;
            display: block;
            padding: 0 $padding * 1.5;
        }

        .author-name {
            font-size: $font-largest;
            padding-bottom: $spacing;
            line-height: 1.25;
            margin: 0;
            font-weight: 900;
        }

        .author-title {
            font-weight: 400;
            font-size: $font-large;
            padding-bottom: $padding;
        }

        p {
            color: $dark1;
            line-height: 1.4;
            font-size: $font-large;
        }

        .author-social {
            padding: 0;
            margin: 0;
            padding-top: $padding * 1;

            li {
                display: inline-block;
                list-style: none;
                margin-right: $padding * 2;
                ;
            }

        }
    }
}