.auth-check-inbox {
    padding: $padding * 3 0;
    width: $padding * 35;
    max-width: 100%;
    h2 {
        margin-bottom: $padding;
        font-size: $font-huge;
        line-height: 1.2;
        color: $dark5;
    }

    p {
        line-height: 1.5;
        font-size: $font-largest;
        color: $dark1;
    }

    .inbox-links{
        margin-top: $padding * 3;
        p{
            font-size: $font-largish;
            color: $dark1;
        }
        .btn{
            vertical-align: middle;
            margin-bottom: $spacing * 1;
            margin-right: $spacing;
            img{
                margin-right: $spacing;
                max-height: $padding * 1 !important;
                display: inline-block;
            }
        }
    }
}