
#cookie-consent {
    background: rgba(0, 0, 0, 0.8) !important;
    color: $light1 !important;
    font-size: $font-small !important;
    padding: 0 $spacing !important;
  
    >div {
      padding: 0 !important;
      position: relative;
    }
  
    #cookie-consent-button {
      line-height: $control-height !important;
      height: $control-height !important;
      padding: 0 $padding !important;
      margin: $spacing 0 !important;
      position: relative;
      max-width: 50%;
  
      &:hover,
      &:focus {
        background-color: $light3 !important;
      }
    }
  }