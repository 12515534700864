

button,
.btn {
  max-width: 100%;

  max-height: $padding * 6 !important;

  * {
    max-height: $padding * 6 !important;

  }

  &:hover {
    cursor: pointer !important;
  }


  &.full{
    width: 100%;
    display: block;
  }
  &.no-click {

    &:hover,
    &:focus {
      cursor: default;
      text-decoration: none;
    }
  }

  &[disabled] {
    opacity: 1;
  }

  &:focus {
    outline: none !important;
  }

  @include btn-theme();

  &.btn-lg {
    line-height: $control-height;
    font-size: $font-large * 0.9;
    font-weight: 400;
    //border-width: 2px;

    .fa-fw, .fa-w-16,
    .fa {
      opacity: 0.8;
      font-size: $font-small !important;
    }
    
    &.full{
      padding: $spacing * 0.75;
    }
  }

  &.btn-xl {
    line-height: 1;
    padding: $padding * 0.75 $padding * 1;
    display: inline-block;
    font-size: $font-largest * 0.85;
    font-weight: 500;


    @media(max-width: $size-mobile) {
      font-size: $font-largest * 0.85;
      line-height: 0.8;

    }

    .fa {
      font-size: $font-large * 1;

    }
  }

  &.btn-huge {
    line-height: 1;
    padding: $padding * 1.1 $padding * 1.25;
    display: inline-block;
    font-size: $font-largest * 1;
    font-weight: 700;


    @media(max-width: $size-mobile) {
      font-size: $font-largest * 0.9;
      line-height: 0.9;

    }

    .fa {
      font-size: $font-large * 1.25;

    }
  }



  &.btn-sm {
    max-height: $control-height;
    line-height: $control-height;
    font-size: $font-normal;
  }

  &.btn-md {
    max-height: $control-height;
    line-height: $control-height;
    font-size: $font-largish;
  }

  &.btn-ghost {
    color: $dark4;
    background-color: transparent;
    //border-color: transparent !important;

    &.cta{
      border: solid 1px transparent !important;
    }

    &:hover {
      color: $pink;
      @include box-shadow();
    }

    &.success {
      color: $green2 !important;
    }

    &:focus {
      background-color: $light2 !important;
    }

    &.active {
      color: $pink;
      box-shadow: none;

      &:hover {

        @include box-shadow();
      }
    }
  }

  &.btn-light {
    color: $dark4;
    background-color: $light1;
    border: solid 1px $dark1 !important;

    &:hover {
      color: $dark5;
      @include box-shadow();
      background: $light2;
      border-color: $dark5 !important;
    }


    &:focus {
      background-color: $light2 !important;
    }

    &.active {
      color: $dark5;
      box-shadow: none;

      &:hover {

        @include box-shadow();
      }
    }
  }





  &.icon-only,
  &.btn-icon-only {
    margin: 0;
    text-align: center;
    padding: 0;
    width: 40px;
    line-height: 40px;
    vertical-align: middle;



    .fa,
    .fa-fw {
      margin: 0 !important;
      padding: 0 !important;
      opacity: 1 !important;
      margin-top: -2px;
    }

    &.btn-sm {
      width: $padding * 1.5;
      line-height: $padding * 1.5;

      @media (max-width: $size-mobile) {
        width: $padding * 2.25;
        line-height: $padding * 2.25;


      }
    }

    &.btn-md {
      width: $padding * 2;
      line-height: $padding * 2;
    }

    &.btn-huge {
      line-height: 48px;
      width: 48px;

      .fa {
        margin-top: -5px !important;
      }
    }

    &.btn-massive {
      line-height: $padding * 4;
      width: $padding * 4;

      .fa,
      .material-icons {
        margin-top: -5px !important;
      }
    }
  }

  &.btn-primary {
    color: $light1;
    //border-color: $blue1;
    background: $blue2;

    &:hover {
      //border-color: $blue2;
      background: $blue1;
      color: $light1;
    }

    &:active {
      color: $light4;
      //border-color: $blue2;
      background: $blue1;
    }

    &:focus {
      //border-color: $blue1;
    }

    &:focus:active {
      background: $light2;
    }

    &.active {
      background: $blue2;
      //border-color: $blue2;
      color: $light4;
    }
  }

  &.btn-default {
    color: darken($dark2, 3);
    //border-color: $dark1;

    background: lighten($light2, 1);

    &:hover {
      background: $light3;
      color: $dark5;
    }

    &:active {
      background: $dark1;
      color: $light2;
    }

    &:focus {
      background: $light3;
      color: $dark4;
    }

    &.active {
      background: darken($light3, 3);
      color: $dark5;
    }
  }

  &.btn-circle {
    //border: none;
    @include border-radius(50%);

    .fa,
    .fa-fw,
    .material-icons {
      margin-top: -$rad;
      margin-right: -$rad / 2;
      padding-right: 0;
    }

    &.btn-sm {

      .fa,
      .fa-fw,
      .material-icons {
        margin-right: 0;
      }
    }


  }

  &.btn-success {
    color: $light1;
    background: $green1;
    //border: solid lighten($green2, 0);

    &.cta {
      
      border-right: solid 2px darken($green2, 4) !important;
      border-bottom: solid 2px darken($green2, 4) !important;
      @include border-radius($rad * 2.5);
      @include box-shadow-light();

      @media (max-width: $size-mobile) {
        @include border-radius($spacing);
      }

      &:hover {
        -webkit-box-shadow: 0px 0px 23px 2px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 0px 23px 2px rgba(0, 0, 0, 0.22);
      }
    }

    &:hover {
      background: lighten($green1, 10);
      //border-color: lighten($green1, 5);
      color: $light1;
    }

    &:active {
      color: $light1;
      //border-color: $green2;
      background: $green1;
    }

    &:focus {
      color: $light1;
      //border-color: $green2;
      background: darken($green1, 5) !important;
    }

    &.active {
      background: $green2;
      //border-color: $green1;
      color: $light1;
    }
  }


  &.btn-link {
    line-height: 1em;
    padding: 0;
    //color: $blue2;
    font-weight: 400;
    color: $green2;
    box-shadow: none;
    vertical-align: initial;
    text-decoration: none;
    white-space: unset;
    display: unset;
    border-bottom: solid 1px transparent !important;

    //border: none;
    max-width: 100%;

    &.normal {
      border-bottom: solid 1px $green1 !important;
      @include border-radius(0px);


      &:hover {
        color: $green1;
        border-bottom: solid 1px transparent !important;

        text-decoration: none !important;
      }
    }


    &.blue {
      color: $blue1;
    }

    &.pink {
      @include border-radius(0);
      color: $pink;
      text-decoration: none !important;

      &:hover,
      &:active,
      &:focus {
        //    border-bottom:none !important;
        border-bottom: solid 2px $pink !important;
        color: $pink;
      }
    }

    &.dark {
      color: $dark5;
    }

    &.light {
      color: $light1;
    }

    &:hover {
      color: $blue1;
      box-shadow: none !important;
      text-decoration: underline;

      &.blue {
        color: $blue1;
      }

      &.dark {
        color: $dark4;
        text-decoration-color: $dark1;
      }

      &.light {
        color: $light4;
      }
    }

    &:active {
      color: $green2;

      &.blue {
        color: $blue1;
      }

      &.dark {
        color: $dark4;
      }

      &.light {
        color: $light4;
      }
    }

    &:focus {
      color: $green2;
      text-decoration: underline;

      &.blue {
        color: $blue1;
      }

      &.dark {
        color: $dark4;
      }

      &.light {
        color: $light4;
      }
    }

    &.active {
      color: $green2;

      &.blue {
        color: $blue1;
      }

      &.dark {
        color: $dark3;
      }

      &.light {
        color: $light4;
      }
    }
  }
}