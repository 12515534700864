.company-side-box {
  //  @include box-theme();
    text-align: left;
    //@include box-shadow();
    padding: $padding * 2;
  //  margin-bottom: $padding * 2;

  
    border-bottom: solid 1px lighten($light3, 8);
    &:last-child {
        border-bottom: solid 1px darken($light3, 8);
    }

    @media (max-width: $size-mobile) {
        border: none;
        box-shadow: none;
        @include border-radius(0);
        border-bottom: solid 1px $light3;
        padding: 0;
        margin: 0;
        padding: $padding * 2 0;

        &.company-benefits {
            border-bottom: none;
        }
    }



    p {
        margin-bottom: $spacing;
        line-height: 1.7;
        font-size: $font-largish;
        color: $dark1;
    }

    h3 {
        line-height: 1;
        font-size: $font-largest * 0.9;
        font-weight: 700;
        padding-bottom: $spacing * 1.5;
        color: $dark2;

        @media (max-width: $size-mobile) {
            font-size: $font-large;
        }

    }


    &.job-stack {
        padding-bottom: $spacing * 1.5;
    }

    // Remote
    &.company-remote {
        p {
            margin-bottom: 0;
        }

        ul {
            padding: 0;
            margin: 0;
            margin-top: $spacing;

            li {
                list-style: none;
                margin-bottom: $spacing;
                position: relative;


                &:last-child {
                    margin-bottom: 0;
                }


                .remote-bar,
                .department-bar {
                    background: $light2;
                    border-radius: $rad;
                    height: $padding * 1.25;

                }

                .remote-number {
                    position: absolute;
                    right: $rad;
                    font-size: $font-small;
                    color: $dark1;
                    top: 0;
                }

                .remote-title {
                    position: absolute;
                    left: $rad;
                    font-size: $font-small;
                    color: $dark5;
                    top: 0;
                }


                .department-number {
                    position: absolute;
                    right: $rad;
                    font-size: $font-small;
                    color: $dark1;
                    top: 0;
                }

                .department-name {
                    position: absolute;
                    left: $rad;
                    font-size: $font-small;
                    color: $dark5;
                    text-transform: capitalize;
                    top: 0;
                }
            }
        }
    }

    


    &.company-sideprojects {

        ul {
            margin: 0;
            padding: 0;
            padding-top: $spacing;
            ;

            li {
                list-style: none;
                margin: 0;
                padding: 0;
                padding-bottom: $padding;

                &:last-child {
                    padding-bottom: 0;
                }

                .sideproject-icon {
                    width: $padding * 2.5;
                    display: inline-block;

                    img {
                        @include border-radius(50%);
                    }
                }

                .sideproject-title {
                    width: calc(100% - #{$padding * 2.5});
                    vertical-align: top;

                    display: inline-block;

                    .sideproject-name {
                        font-size: $font-small;
                        color: $dark1;
                    }

                }
            }
        }

    }

    // benefits
    &.company-benefits {

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: $spacing * 1.25;
                position: relative;
                display: flex;
                font-size: $font-normal;

                &:last-child {
                    margin-bottom: 0;
                }

                &:nth-child(1) .benefit-icon {
                    color: $green1;
                }

                &:nth-child(2) .benefit-icon {
                    color: $pink;
                }

                &:nth-child(3) .benefit-icon {
                    color: $blue2;
                }

                .benefit-icon {
                    display: inline-block;
                    width: $padding * 1.75;
                    line-height: 1.3;
                    vertical-align: middle;
                }

                .benefit-title {
                    display: inline-block;
                    width: calc(100% - #{$padding * 1.75});
                    line-height: 1.3;
                }
            }
        }
    }


    // Typical week
    &.company-typical-week {

        h3{
            position: relative;
            .read-more{
                position: absolute;
                right: 0;
                top: 0;

                @media (min-width: $size-mobile) and (max-width: $size-tablet) {
                    position: unset;
                    padding-top: $rad;
                }
            }
        }

        .twitter-tweet-wrapper {
            padding-top: $spacing;
            margin-left: -9px;
        }

        .typical-week-any {
            position: relative;
            padding-left: $padding * 1.55;
            font-size: $font-largish * 1;
            line-height: $padding * 1.75;
            color: $green1;
            margin-top: $padding;

            .fa-check {
                color: $green1;
                font-size: $font-largest * 0.9;
                position: absolute;
                opacity: 0.5;
                left: 0;
                top: $rad;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            margin-top: $spacing * 2.5;



            li {
                text-align: center;
                line-height: 1.25;
                list-style: none;
                font-style: $font-largish;
                display: inline-block;
                width: 20%;


                &:last-child {
                    margin-bottom: 0;
                }


                .week-icon {
                    color: $dark1;
                }

                h5 {
                    font-size: $font-normal;
                    font-weight: 400;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }

    // TYpical day
    &.company-typical-day {
        ul {
            padding: 0;
            margin: 0;

            li {
                line-height: 1.25;
                list-style: none;
                font-size: $font-small;
                padding: 0 0 $spacing 0;
                width: 100%;
                position: relative;

                &:last-child {
                    padding-bottom: 0;
                }

                .day-bar {
                    display: inline-block;
                    background: lighten($light2, 2);
                    text-align: center;
                    color: $light1;
                    padding: $rad;
                    @include border-radius($rad);
                }

                .day-from {
                    padding: 0 $spacing 0 0;
                    color: $dark1;
                    display: inline-block;
                    text-align: right;
                }

                .day-to {
                    padding: 0 0 0 $spacing;
                    display: inline-block;
                    color: $dark1;
                    text-align: left;
                }


                &.range {
                    .day-bar {
                        width: 60%;
                        color: $green1;
                        // background: $green1;
                    }

                    .day-from,
                    .day-to {
                        width: 20%;
                    }
                }

                &.typical {
                    .day-bar {
                        width: 50%;
                        color: $dark5;
                        // background: $green1;
                    }

                    .day-from,
                    .day-to {
                        width: 25%;
                    }

                }

                &.core {
                    .day-bar {
                        width: 40%;
                        color: $pink;
                        // background: $green1;
                    }

                    .day-from,
                    .day-to {
                        width: 30%;
                    }

                }
            }
        }
    }







    // Vacation
    &.company-vacation {


        ul {
            padding: 0;
            margin: 0;
            padding-left: $spacing;
            margin: 0 auto;
            max-width: $padding * 11;

            li {
                text-align: right;
                line-height: 1.25;
                list-style: none;
                font-size: $font-largish;
                padding: $rad 0;

                &:nth-child(1),
                &:nth-child(3) {
                    padding-left: $spacing * 1.25;
                }

                &:nth-child(2) {
                    border-bottom: solid 1px $light3;
                    padding-bottom: $spacing;

                    &::before {
                        content: "+ ";
                    }
                }

                &:nth-child(3) {
                    padding: $spacing 0 0 $spacing * 1.25;
                    color: $green2;
                    font-weight: 700;
                    // border-bottom: solid 1px $light3;
                    //font-weight: 900;
                }
            }
        }
    }

    &.company-profile {
        .company-logo {
            height: $padding * 3;
            position: absolute;
            @include border-radius(50%);
            right: $padding * 2;
            top: $spacing * 3;
            max-width: $padding * 4;
            border: solid 1px $light3;
        }   

        p {
            padding-top: $spacing;
        }

    }

    &.company-ranking {
        position: relative;


        .tag {
            position: absolute;
            right: $padding;
            top: $padding;
            background: $light3;
            margin-left: $padding;
            border: 1px solid $light2;
            color: $dark3;
            font-size: $font-small;
            padding: $spacing * 0.75 $spacing;
            line-height: 1;
            display: none;
            @include border-radius($padding);

            .fa-star {
                color: $pink;
                font-size: $font-small;

            }
        }

        .company-score {
            color: $green1;
            font-size: $font-huge * 1.3;
            font-weight: 900;
            line-height: 1;
            text-align: center;
            padding: 0 0 $spacing 0;

            .small {
                color: $dark1;
                font-size: $font-large;
                padding-top: $spacing;
            }
        }

        .company-score-details {
            padding: 0;
            margin: 0;
            padding-top: $spacing;


            li {

                list-style: none;
                margin: 0;
                padding: 0;
                padding-left: 0;
                font-size: $font-normal * 1.05;
                margin-bottom: $spacing;
                line-height: $font-normal * 1;

                .number {
                    //    border: solid 1px $light3;
                    //                       background: $light2;
                    display: inline-block;
                    width: $padding * 1.5;
                    font-size: $font-small * 0.97;
                    margin-top: 2px;
                    color: $green1;
                    font-weight: 700;
                    margin-right: $spacing;
                    text-align: center;
                    vertical-align: top;


                }

                .text {
                    display: inline-block;
                    vertical-align: super;
                    width: calc(100% - #{$padding * 2});
                    line-height: 1.3;
                }
            }

        }
    }


    &.company-technologies, &.job-skills {

        .company-tags.tags {
            li {
                padding: $rad * 1.25 $rad * 1.75;
                font-size: $font-normal;
                color: $dark1;
                line-height: 1.25;
                .btn {
                    font-size: $font-normal;
                }

            }
        }

    }


}