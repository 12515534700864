.modal {

    z-index: 111111111;
    background-color: rgba(0, 0, 5, 0.55);

    overflow: auto;

    .modal-content {
        

        .modal-header {
            .close {
                position: absolute;
                top: $padding * 0.75;
                right: $padding * 0.75;
                font-size: $font-largest;
                color: $dark1;
                opacity: 100;
                opacity: 1 !important;

                &:hover {
                    background-color: $light2;
                    color: $dark5;
                }
            }

        }

        .field-row {
            padding-bottom: 0;

            .label-container {
                label {
                    font-size: $font-largish;
                }

                input,
                .react-select__value-container {
                    padding: $spacing;
                }
            }
        }
    }

    &.exit-popup {
        padding: $padding * 1.5;
        animation: fadeIn ease-in-out 0.3s;
        -webkit-animation: fadeIn ease-in-out 0.3s;
        -moz-animation: fadeIn ease-in-out 0.3s;
        -o-animation: fadeIn ease-in-out 0.3s;
        -ms-animation: fadeIn ease-in-out 0.3s;

        @media (max-width: $size-mobile) {
            padding: $padding * 0.75;
        }

        .modal-dialog {
            width: 100% !important;
            height: 100% !important;
            margin: 0;

            .modal-content {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                overflow-y: scroll;
                padding: $padding * 4 $padding * 2;

                .btn-close {
                    position: absolute;
                    top: $spacing;
                    right: $spacing;
                    width: $padding * 3;
                    height: $padding * 3;
                }

                .modal-content-inner {
                    max-width: 100%;
                    max-height: 100%;
                    width: $padding * 32;

                }


                .subscriber.subscriber-popup {

                    h3 {
                        font-size: $font-huge * 1.1;

                        @media (max-width:$size-mobile) {
                            font-size: $font-huge * 0.8;
                        }
                    }

                    .subscriber-form-desc {
                        font-size: $font-largest * 1.2;

                        @media (max-width:$size-mobile) {
                            font-size: $font-largest * 0.9;

                        }
                    }

                }

            }

        }
    }
}