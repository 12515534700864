  // Footer
  .footer {
      width: 100%;
      margin-top: $padding * 4;
      padding: 0;
      z-index: 1111;
      //     min-height: 1400px; // CLS meh

      .footer-content {
          background-color: $dark3;
          padding: $padding * 2 $padding;


          @media (max-width: $size-mobile) {
              padding: $padding * 2 0;
          }


          @media (min-width: $size-mobile) and (max-width: $size-desktop) {
              padding: $padding * 3;

          }

          .top-row,
          .bottom-row {
              max-width: $size-desktop;
              margin: 0 auto;
              padding: $padding * 2 0;

              font-size: $font-largish;

              h4 {
                  color: $light4;
                  font-size: $font-largish;
                  padding-bottom: $padding * 1;
                  font-weight: 400;
              }


              @media (max-width: $size-mobile) {
                  padding: $padding;
                  text-align: center;
              }


              .bottom-menu {
                  list-style: none;

                  padding: 0;

                  &.single-col {
                      display: block;
                  }


                  &.full-width {
                      li {
                          width: 100%;
                      }
                  }

                  li {
                      padding-right: $padding * 2;
                      line-height: 1.1;
                      margin-bottom: $rad * 1.5;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      color: $light3;


                      @media (max-width: $size-mobile) {
                          padding-right: 0;
                          ;
                      }


                      a {
                          font-size: $font-normal;
                      }

                      &:last-child {
                          margin-bottom: 0;
                      }

                      @media (max-width: $size-mobile) {
                          width: 100%;
                          margin-bottom: $padding;

                      }

                  }
              }
          }

          // Top
          .top-row {

              padding-bottom: $padding * 2;
              ;


              .social-list {
                  padding-right: 0;
                  text-align: right !important;

                  @media (max-width: $size-mobile) {
                      padding: $padding;

                  }

                  p {
                      text-align: right !important;
                      color: $light3;
                  }


                  ul {
                      padding: 0;
                      margin: 0;

                      li {
                          margin: 0;
                      }
                  }



                  .logos {
                      padding: $padding * 1 0 $padding * 2 0;



                      @media (max-width: $size-mobile) {
                          text-align: center;

                          li:first-child {
                              width: 100%;
                              padding-bottom: $spacing;
                          }

                      }

                      li {
                          display: inline-block;
                      }

                      .logo {
                          width: $padding * 12;
                          float: right;
                          max-width: 100%;
                          margin-bottom: $spacing;


                      }

                  }

                  .address {
                      padding-top: $padding * 2;
                      color: $light2;

                      @media (max-width: $size-mobile) {
                          text-align: center;
                      }

                  }

                  .product-hunt {
                      @media (max-width: $size-mobile) {
                          text-align: center;

                      }
                  }

                  .jbs {
                      margin-top: $padding;
                      
                  }



                  li {
                      list-style: none;
                      color: $light3;

                      a {
                          color: $light3;
                          font-size: $font-largest * 1.3;

                          &:focus,
                          &:active {
                              color: $dark5;
                          }

                          @media (max-width: $size-mobile) {
                              margin: 0;
                          }
                      }
                  }
              }

          }

      }



      .footer-bottom {
          background-color: $dark5;
          padding: $padding * 1 $padding;

          .footer-bottom-content {

              max-width: $size-desktop;
              margin: 0 auto;
              padding: $padding * 1 0;

              li {
                  display: inline-block;
                  width: auto;
                  padding: $spacing 0;

                  padding-right: $padding * 2;

                  color: $light1;
                  line-height: 1.5;

                  &:last-child {
                      padding-right: 0;
                  }
              }



          }
      }

  }