.wrapper .content-wrapper .header-container .navbar {
    padding-bottom: $padding;
    border: none;


    max-width: $size-desktop;
    padding: 0;
    margin: 0 auto;

    @media (max-width: $size-mobile) {
        //   box-shadow: 0px 10px 10px -3px $light2;
        margin: 0;
        padding: $padding;
        min-height: $padding * 4;
    }

    .container-fluid {
        padding: 0;

        .navbar-header {

            @media (max-width: $size-mobile) {
                margin: 0;
            }

            .icon-bar {
                background: $dark1;

            }

            // Logo
            .navbar-brand {
                margin-left: 0;
                padding: 0;
                height: unset;

                img {
                    max-height: $control-height;


                }

                @media (max-width: $size-mobile) {
                    padding-top: 0;
                }

            }
        }

        .navbar-collapse {
            padding: 0;

            // Actual links
            .navbar-nav {
                float: right;

                &.tablet-menu,
                &.desktop-menu {
                    display: none;
                }


                &.tablet-menu {

                    @media (max-width: $size-mobile) {
                        display: block;
                        padding-bottom: $padding * 12 !important;
                    }

                }

                .caret {
                    color: lighten($dark1, 5);
                    margin-left: $rad;
                }

                .logged-out{
                    padding-left: $padding;
                    padding-right: $rad;
                }

                .prevent-jumping{
                    width: 338px;;
                    text-align: right;
                    display: inline-block;
                    color: $dark1;
                    font-size: $font-small;
                    padding-top: $spacing;;
                    padding-right: $padding;
                }

                .user-menu  {
                    width: 247px;
                    text-align: right;

                    .dropdown-menu{
                        width: auto;
                        left: auto;
                        right: 0;;
                    }
                    .btn {
                        display: inline-block;
                    }
                }

                .user-menu, .more-menu {



                    .dropdown-menu {
                        @include box-theme();
                        @include border-radius($rad);
                        @include box-shadow-light();
                        padding: 0;

                        .divider {
                            margin: $rad 0;
                        }

                        li {

                            &.seperator, &.separator {
                                border-top: solid 1px $light3;
                            }

                            a {
                                padding: $rad * 1.5 $spacing * 1.5;
                                color: $dark5;
                                font-size: $font-largish;
                                text-align: left;
                                margin: 0;

                                .svg-inline--fa {
                                    color: $green1;
                                    font-size: $font-small;
                                }

                            }
                        }

                    }
                }
                .user-menu{
              //      margin-left: $padding * 5;
                }


                &.desktop-menu {


                    @media (min-width: $size-mobile) {
                        display: block;
                    }
                }

                .first-right-menu{
                    padding-left: $padding * 2;

                    @media (max-width: $size-tablet) {
                        padding-left: $padding;
                    }
                }


                >li {

                    //Menu button
                    .btn {
                        margin-left: $padding * 0.75;
                        padding: $spacing * 1.5 $padding;
                        line-height: 1;

                        @media (max-width: $size-desktop) {
                            margin-left: $rad;
                            padding: $spacing * 1.25 $spacing;
                        }


                        @media (max-width: $size-tablet) {
                            margin-left: 0;

                            .caret {
                                display: none;
                            }
                        }


                        &.btn-ghost {

                            &:focus,
                            &:active,
                            &:hover {
                                background: $light1;
                                ;
                            }

                            &.active {
                                //background: $pink;
                                color: $pink;

                            }

                        }


                        &.cta {

                            font-size: $font-largest * 0.8;
                            font-weight: 700;
                            @include border-radius($spacing);
                            text-align: center;

                            &.cta-first {
                                margin-left: $padding * 5;

                                @media (max-width: $size-desktop) {
                                    margin-left: $padding;

                                    background-color: $green1 !important;
                                    font-weight: 900;
                                    padding: $padding * 0.75 $padding !important;
                                    color: $light1 !important;

                                    .svg-inline--fa {
                                        color: $light1 !important;
                                    }

                                }


                                @media (max-width: $size-mobile) {
                                    margin-top: $padding * 2 !important;


                                }



                                .svg-inline--fa {
                                    color: $green2;
                                    font-size: $font-large;
                                    margin-right: $rad;
                                }
                            }

                            &.cta-last {
                                @media (max-width: $size-mobile) {
                                    margin-bottom: 100px !important;
                                    background-color: $light2 !important;
                                    padding: $padding * 0.75 $padding !important;

                                }
                            }

                            @media (max-width: $size-tablet) {
                                margin-left: $padding * .5;

                            }

                            &.btn-success {
                            //    border: none !important;



                            }


                         
                            .svg-inline--fa {
                                color: $pink;
                                font-size: $font-large;
                                margin-right: $rad;
                                max-height: $font-large;
                            }

                            &.cta-secondary {
                                font-weight: 400;
                            }

                        }



                    }
                }



            }




        }
    }



    // Mnobile menu
    @media (max-width: $size-mobile) {
        padding: $spacing $padding;
        min-height: 0;
        ;


        .navbar-toggle {
            margin: 0;
            padding: $spacing * 1.75;

        }

        .navbar-collapse {
            position: absolute;
            border: none;
            width: 100%;
            left: 0;
            margin: 0;
            z-index: 100000;
            background: $light1;
            -webkit-box-shadow: 0px 25px 25px -6px $dark5;
            box-shadow: 0px 50px 50px -6px $light3;
            -webkit-box-shadow: inset 0px -4px 8px 1px rgba(0, 0, 0, 0.21);
            box-shadow: inset 0px -15px 18px 3px rgba(0, 0, 0, 0.15);

            height: 100vh;




            .navbar-nav {
                padding: $padding * 1.5 $padding !important;
                width: 100%;
                padding-bottom: $padding * 2;
                margin: 0;
                overflow: auto;
                height: 100%;



                .navbar-title {
                    color: $dark1;
                    font-size: $font-largish;
                    margin-bottom: $spacing;
                    text-align: center;

                }

                li {
                 //   padding-bottom: $rad;

                    &.separator {
                        margin-top: $padding * 1;

                        &.large {
                            margin-top: $padding * 2;
                        }
                    }


                    .btn {
                        margin: 0 !important;
                        display: block;
                        line-height: normal !important;
                        text-align: left;

                  
                        &.btn-huge {
                            font-weight: 500;
                            font-size: $font-largest * 1.1;
                        }

                        .svg-inline--fa,
                        .fa-w-16 {
                            width: $padding * 2 !important;
                            opacity: 0.6;
                            margin-right: $spacing;
                        }

                        &.active {
                            background: rgba($green1, 0.15) !important;
                            color: $dark5 !important;
                            box-shadow: none !important;
                            ;
                            //color: $light1 !important;

                            .svg-inline--fa {
                                color: $green1 !important;
                            }


                        }

                        &.dropdown {
                            padding: 0 !important;
                        }
                    }
                }
            }

        }
    }


    .left-nav {
        padding: 0;

    }

    .right-nav {
        .btn {
            margin-left: $spacing;
        }

    }
}