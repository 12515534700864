.post-job-form-preview {

    .job-tile-wrapper {

        max-width: $size-mobile;
        margin: 0 auto;
        position: relative;
        padding: $padding * 1 0;
        padding-left: $padding;
        padding-right: $spacing / 2;

        padding-bottom: calc(#{$padding} - #{$rad}); // to accomomdate for tags
        overflow: visible;
        border: 1px solid $light3;

        @media (max-width: $size-mobile) {
            display: block;
            overflow: hidden;
            padding: 0;
            @include border-radius(0);
            margin: 0;
            margin-left: -$padding;
            margin-right: -$padding;

            //    border-top: solid 2px lighten($light2, 3) !important;
            .inner-triangle {
                display: none;
            }

            &.success {
                border-left: none !important;
                // border-bottom: none !important;




            }
        }


        .job-tile-logo-wrapper {
            position: absolute;
            width: $padding * 3;
            top: $padding;
            left: -$padding * 1.75;
            height: $padding * 3;
            background: $light2;
            border: solid 1px $light3;
            overflow: hidden;
            z-index: 1;
            @include border-radius(50%);
            display: flex;
            align-items: center;


            //      display: none;



            @media (max-width: $size-mobile) {
                width: $padding * 1.5;
                height: $padding * 1.5;
                left: $spacing * 2;
                top: $spacing * 2;
            }

            .job-tile-logo,
            .job-tile-missing-logo {

                line-height: $padding * 3;
                width: 100%;
                height: 100%;

            }

            .job-tile-missing-logo {
                font-size: $font-largest;
                text-align: center;
                background: $light4;
                color: $light1;
                font-weight: 900;


                &:hover {
                    border: solid 1px $light4;
                }

            }

        }



        .job-tile {
            position: relative;
            line-height: 1;
            display: flex;
            flex-direction: row;
            // overflow: auto !important;

            @media (max-width: $size-mobile) {
                padding: $padding * 1.25 0; // $spacing $spacing;
                @include border-radius(0);
                background: $light1;
                flex-direction: column;
                padding-bottom: $padding - $rad;
                margin-bottom: 0;
                line-height: 1;
                // border-bottom: solid 1px $light2;
            }



            .job-tile-left {
                @media (min-width: $size-mobile) {
                    padding-right: 0;
                }


                .job-tile-title {
                    margin: 0;
                    padding-bottom: $spacing;

                    @media (max-width: $size-mobile) {
                        padding-bottom: $spacing;
                    }

                    h3 {
                        text-align: left;
                        margin: 0;
                        padding: 0;
                        line-height: 0.9;

                        padding-top: $rad;

                        @media (max-width: $size-mobile) {
                            padding: $rad 0;
                        }

                        a {
                            font-weight: 700;
                            font-size: $font-large;





                            @media (max-width: $size-mobile) {
                                white-space: normal;
                                text-overflow: unset;
                            }
                        }
                    }


                }

                .job-tile-company {
                    color: $dark5;
                    font-size: $font-largish;
                    font-weight: 400;
                    margin: 0;

                    @media (max-width: $size-mobile) {
                        padding-bottom: $spacing * 0.75;
                    }


                    &.no-link {
                        h5 {
                            color: $dark2;
                        }
                    }

                    h5 {
                        text-align: left;
                        line-height: initial;
                        font-size: $font-largish;
                        font-weight: 400;
                        padding: 0;
                        margin: 0;
                        line-height: 1;

                        @media (max-width: $size-mobile) {
                            padding-left: $padding * 2;
                        }

                        &:active {
                            color: unset;
                        }


                    }

                    a {
                        &:hover {
                            text-decoration: none;
                            color: $pink !important;
                        }

                    }



                }

                .job-tile-tags {
                    color: $dark1;
                    line-height: 1;
                    display: block;
                    margin: 0;
                    padding: 0;



                    li {
                        list-style: none;
                        margin-right: $spacing * 0.75;
                        display: inline-block;
                        line-height: 1.25;
                        font-size: $font-normal * 0.94;
                        margin-bottom: $rad;

                        background: rgba(225, 235, 245, 1);
                        color: $dark2;
                        @include border-radius($spacing);
                        padding: $rad * 1 $rad * 1.5;


                        .svg-inline--fa {
                            margin-right: $rad / 2;
                        }

                        &.offered,
                        &.would-consider,
                        &.remote-100 {
                            background: rgba(215, 255, 225, 0.8);
                            color: darken($green1, 12%);


                            a {
                                color: darken($green1, 12%);
                            }

                        }

                        &.generous-pto,
                        &.unlimited-pto {
                            background: rgba(255, 170, 170, 0.45);
                            color: darken($red2, 30%);

                            a {
                                color: darken($red2, 30%);
                            }
                        }

                        &.remote-city,
                        &.remote-country,
                        &.remote-continent {
                            background: rgba(255, 235, 170, 0.8);
                            color: darken($dark5, 5%);

                            a {
                                color: darken($dark5, 5%);
                            }
                        }

                        &.tile-salary,
                        &.relocation-budget {
                            // background: rgba(200, 230, 255, 1);
                            //    color: darken($dark3, 5%);
                            background: rgba(215, 255, 225, 0.8);
                            color: darken($green1, 12%);
                        }

                        a {
                            color: $dark4;
                        }



                        @media (max-width: $size-mobile) {
                            font-size: $font-small;
                            line-height: 1;
                            //       padding-bottom: 0;
                            margin-bottom: $rad * 1.5;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                }



            }

            .job-tile-right {

                @media (max-width: $size-mobile) {
                    position: absolute;
                    right: $spacing;
                    top: -$rad * 1.5;

                    .job-tile-expired {
                        color: $red2 !important;
                    }

                    .job-tile-time {
                        padding-top: $spacing * 1.5;

                        font-size: $font-small !important;

                    }
                }


                .job-apply-button {
                    display: none;
                }



                .job-tile-time {
                    text-align: right;
                    margin-bottom: $spacing;
                    font-size: $font-small;
                    color: $dark1;
                    line-height: 1;
                    font-weight: 400;

                    @media (max-width: $size-mobile) {
                        margin-top: $spacing * 1.5;
                    }

                }


            }


        }


    }



    .job-listing {
        max-width: $size-mobile;
        margin: 0 auto;

        @media (max-width: $size-mobile) {
            margin-left: -$padding;
            margin-right: -$padding;
        }


        &:last-child {
            border-bottom: solid 1px $light3;
        }

        // Left
        .job-content {
            border: solid 1px $light3;

            padding: $padding * 3;

            @media(min-width: $size-mobile) {}


            @media(max-width: $size-mobile) {
                border: none;
                padding: $padding * 3 $padding * 1.5;

                .read-more {
                    display: none;
                }

            }

            .apply-warning {
                font-style: italic;
                padding: $spacing $padding;
                width: auto;
                margin: $padding auto $padding auto;
                font-size: $font-normal;
                color: darken($pink, 12);
                border: solid 1px rgba($pink, 0.19);
                background: rgba($pink, 0.13);

                //    font-weight: 900;
                //  background-color: lighten($light2, 4);
                @include border-radius($spacing);
                text-align: center;

                @media(max-width: $size-mobile) {
                    padding: $padding;

                    margin: ($padding * 0) (-$padding * 1.5) ($padding * 2) (-$padding * 1.5);

                    border-radius: 0;
                    border: none;

                }

            }

            // Description
            .job-description-text {
                padding-top: $padding;
                min-height: 300px; // CLS

                img {
                    display: none;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: $font-largest;
                    font-weight: 900;
                    margin: 0;
                    padding: 0;
                    padding-bottom: $padding;
                    line-height: 1.25;
                }

                h4,
                h5,
                h6 {
                    font-size: $font-largish;
                }


            }


            .job-footer {
                padding: $padding * 1 0;

                .tile-apply-bottom {

                    .resume-btn,
                    .ai {
                        display: none;
                    }

                    .btn {

                        min-width: $padding * 14 !important;
                        padding: $padding;

                        .apply-location {
                            display: block;
                            font-size: $font-normal;
                            margin-top: $rad * 2;
                            font-weight: 400;
                        }
                    }

                    @media (max-width: $size-mobile) {
                        padding: $padding * 1 0 0 0;
                        text-align: center;
                    }

                    .ai {

                        padding-top: $padding * 1.5;

                        @media (max-width: $size-mobile) {}
                    }
                }


                .apply-warning {
                    color: $pink;
                    font-size: $font-normal;
                    padding-top: $spacing;
                    display: none;

                    @media (max-width: $size-mobile) {
                        padding-top: $rad;
                        //    padding-bottom: $padding;
                        text-align: center;
                        ;


                    }
                }

                .more-info {
                    @media (max-width: $size-mobile) {
                        width: 100%;
                    }
                }


                .job-tile-expired {
                    font-size: 1.8rem;
                    color: $red1;
                    font-weight: 900;
                    text-align: center;
                    margin-top: 0;
                    padding: $spacing;
                    background: rgba($red1, 0.2);
                    border-radius: $rad;
                    max-width: $padding * 12;
                }

            }

            .job-title {
                margin-bottom: 0;
                padding-bottom: $padding;

                @media (max-width: $size-mobile) {
                    padding-bottom: $padding * 2;
                }

                .job-apply-button .btn {
                    padding: $spacing * 1.5;

                    @media (max-width: $size-mobile) {
                        margin-top: $padding * 1.5;
                    }
                }


                .title-fdw {
                    font-size: $font-largish;
                    line-height: 1;
                    padding-bottom: $spacing;
                    color: $green1;


                    .title-salary-80 {
                        color: $red1;
                    }

                    //    background-color: rgba(lighten($green1, 10), 0.2);

                    @media (max-width: $size-mobile) {
                        margin-top: $spacing;
                        text-align: center;
                        ;
                    }
                }


                .job-title-left {

                    // padding-right: $padding;
                    @media(max-width: $size-mobile) {
                        text-align: center;
                    }


                    .title-logo {
                        display: inline-block;
                        vertical-align: top;
                        padding-right: $padding;

                        @media(max-width: $size-mobile) {
                            width: $padding * 4;
                            height: $padding * 4;
                            margin-bottom: $padding;
                            padding-right: 0;
                        }

                        img {
                            display: inline-block;
                            width: $padding * 4;
                            height: $padding * 4;
                            @include border-radius(50%);

                        }
                    }

                    h3 {
                        font-size: $font-largest * 1.4;
                        font-weight: 900;
                        line-height: 1.25;
                        margin: 0;
                        padding: 0;
                        padding-bottom: $rad;

                        @media (max-width: $size-mobile) {
                            font-size: $font-huge * 0.9;
                            padding-bottom: $spacing;


                        }
                    }


                    .title-employees {
                        display: inline-block;
                        color: $dark1;
                        font-size: $font-normal;
                    }

                    .title-company {
                        margin-right: $padding;


                    }

                }

                .job-title-right {}








            }

        }


    }
}