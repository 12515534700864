.wrapper .content-wrapper .main-container-wrapper {
    .sponsor {

        .features {
            // padding: $padding * 4 !important;

            h4 {
                margin-top: $padding * 5;
                font-weight: 900;
            }

            p {
                margin-bottom: 0 !important;
            }

            img {
                float: right;
                max-width: 100%;

            }

            .example-img{
                padding-bottom: $padding;
                padding-top: $padding;
            }
        }

        .pricing {
            display: none;


            .plans {
                .plan {
                    @include box-theme();
                    margin-top: $spacing;
                    overflow: visible;


                    padding: $padding * 2.5 $padding * 0.75 $padding $padding * 0.75;


                    @media (max-width: $size-mobile) {
                        padding: $padding * 2.5 $padding * 0.75 $padding $padding * 0.75;
                        margin-bottom: $padding * 2;
                    }

                    &.plan-starter {
                        margin-left: -1px;
                        margin-right: -1px;
                        // margin-top: -$padding;
                    }

                    .plan-price {
                        text-align: center;
                        margin: 0;
                        padding: $padding * 1.5 0;
                        font-weight: 400;

                        font-size: $font-largish *1.75;
                    }


                    .btn {
                        width: 100%;
                    }


                    &.selected {
                        background: lighten($green2, 73);
                        border-color: lighten($green2, 40);
                    }

                    h3 {
                        font-size: $font-largest * 1.3;
                        text-align: center;
                        font-weight: 900;
                        margin-bottom: 0;
                    }

                    .saving {
                        color: $light1;
                        font-weight: 900;
                        background: $green1;
                        text-align: center;
                        @include border-radius($rad * 2);
                        width: auto;
                        padding: $rad $rad * 1.5;

                        display: inline-block;
                        font-size: $font-largish * 0.9;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0%);
                        top: -$padding * 0.9;

                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: $padding 0 0 0;

                        position: relative;

                        li {
                            margin: 0;
                            padding: 0;
                            font-size: $font-largish * 0.95 !important;

                            padding-left: 0;
                            line-height: 1.25;
                            padding-bottom: $padding * 0.5;
                            color: $dark2;
                            text-align: center;

                            &:last-child {
                                padding-bottom: 0;
                            }

                            .small {
                                padding-top: $rad;
                                display: block;
                                font-size: $font-small * 0.95;
                                font-style: italic;

                            }



                            @media (max-width: $size-mobile) {
                                font-size: $font-largish * 1 !important;

                                .small {
                                    font-size: $font-small * 1.05;
                                }


                            }

                        }
                    }

                    button {
                        text-align: center;
                        margin: 0 auto;
                        margin-top: $padding;
                    }
                }

            }


        }




        .sponsor-basket-meta {
            @media (max-width: $size-mobile) {
                margin-bottom: $padding * 4;
            }


            .benefits-title {
                display: block;
                font-size: $font-huge * 1 !important;
                color: $dark4 !important;
                line-height: 1;
                margin: 0;
                padding-top: $padding * 5 !important;


                @media (max-width: $size-mobile) {
                    font-size: $font-huge * 1.25;
                    padding-left: 0;
                }



                &.title-smaller {
                    padding-top: $padding;
                    font-size: $font-largest * 1;
                    line-height: 1.3;
                    font-weight: 400;

                    @media (max-width: $size-mobile) {
                        font-size: $font-huge * 0.8;
                    }


                }

                .emph {
                    color: $pink !important;
                }

            }

            .benefits-bars {
                list-style: none;
                margin: 0;
                width: 100%;
                @include border-radius($rad);
                @include box-theme();
                @include box-shadow-light();
                padding: $padding * 2;


                .benefits-country {
                    padding-bottom: $rad;

                    .benefits-bar {
                        background: $light3;
                        display: block;
                        text-align: center;
                        color: $dark1;
                        padding: $spacing * 0.3;
                        line-height: $padding;
                        @include border-radius($rad);

                        &.benefits-100 {
                            width: 100%;
                        }

                        &.benefits-90 {
                            width: 90%;
                        }

                        &.benefits-80 {
                            width: 80%;
                        }

                        &.benefits-70 {
                            width: 70%;
                        }

                        &.benefits-60 {
                            width: 60%;
                        }

                        &.benefits-50 {
                            width: 50%;
                        }

                        &.benefits-40 {
                            width: 40%;
                        }

                        &.benefits-30 {
                            width: 30%;
                        }

                        &.benefits-20 {
                            width: 20%;
                        }

                        &.benefits-15 {
                            width: 15%;
                        }

                        &.benefits-10 {
                            width: 10%;
                        }
                    }
                }
            }


        }

        .big-quote {
            position: absolute;
            font-size: $padding * 100;
        }

        .testimonial {

            .testimonial-left {
                padding-top: $padding;
                text-align: center;

                .logo-img {
                    max-width: $padding * 5;
                    padding-bottom: $spacing;
                }

                .btn {
                    display: block;
                    padding-bottom: $padding;
                    text-align: center;
                }

                p {
                    padding-top: $spacing;
                    font-size: $font-large;
                    font-weight: 900;
                    ;
                    text-align: center;

                }
            }

            .testimonial-right {

                text-align: left;

                h4 {
                    line-height: 1.4;
                    padding-top: $padding * 1;
                    font-size: $font-largest * 1.2;

                    .emph {
                        font-weight: 900;
                    }

                }
            }
        }

        .numbers {
            h4 {
                font-weight: 900;
                font-size: $font-huge * 1.75;
                line-height: 1;
                text-align: center;

            }

            

            p {
                text-align: center;
                color: $dark1;
                font-size: $font-large;
            }
        }
    }

    .equiry-form {
        padding: $padding * 5 $padding;
    }
}