@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.subscriber-form-footer {
    // opacity: 0.8;
    display: none;
    position: fixed;
    bottom: 0;
    padding: $spacing $padding;
    background: rgba($dark5, 0.96);
    width: 100%;
    z-index: 10000000;
    animation: fadeIn ease-in-out 0.2s;
    -webkit-animation: fadeIn ease-in-out 0.2s;
    -moz-animation: fadeIn ease-in-out 0.2s;
    -o-animation: fadeIn ease-in-out 0.2s;
    -ms-animation: fadeIn ease-in-out 0.2s;

    @media (max-width: $size-mobile) {
        padding: $padding * 0.75;
    }

    .form-footer-inner {
        max-width: $size-desktop;
        margin: 0 auto;


        .form-footer-success {
            h3 {
                color: $light1;
                padding-top: $rad;
                font-size: $font-large;
                text-align: center;

                @media (max-width: $size-mobile) {
                    padding-bottom: $padding;
                    text-align: left;
                    font-size: $font-largish;
                }
            }

            .btn {

                @media (max-width: $size-mobile) {
                    width: 100%;
                }
            }
        }

        .form-footer-desc {
            color: $light1;
            font-weight: 400;
            font-size: $font-large * 0.9;
            text-align: right;
            line-height: 1.25;
            padding-top: $spacing;

            @media (max-width: $size-mobile) {
                text-align: left;
                padding: 0;
                font-size: $font-large * 0.82;
                padding-bottom: $padding * 0.75;
                padding-right: $padding * 2;
            }

        }



        .form-footer-form {
            @media (max-width: $size-mobile) {
                padding: 0;
                position: unset;
            }

            .form-footer-email {
                padding: 0;

                .label-container {
                    padding: 0;

                    input {
                        border: none;
                        padding: $spacing;
                    }
                }

            }

            .form-footer-btn {
                display: inline-block;
                padding: 0;


                @media (max-width: $size-mobile) {
                    position: unset;
                    padding: 0 0 0 $spacing;
                    .btn-ghost{
                        display: none;
                    }

    
                }

                .btn-success {
                    margin-left: $spacing;

                    @media (max-width: $size-mobile) {
                        width: 100%;
                        font-size: $font-normal;
                        margin: 0;
                    }
                }

            }

        }
    }


    .form-footer-close {
        position: relative;

        .btn {
            right: 0;
            position: absolute;
        }

        @media (max-width: $size-mobile) {
            z-index: 10000000000000;
            position: absolute;
            top: $spacing ;
            right: $spacing  * 1.5;


            .btn-default{
                padding: $rad $padding;
                line-height: 0;


            }
        }
    }
}