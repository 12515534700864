// Basic
html,
body,
#App {
  height: 100%;
  width: 100%;
  background: $light1; //rgb(235, 239, 252);
}


body {
  font-weight: 400;
  color: $dark5;
  background: $light1;
  overflow-x: hidden;
  font-family: 'Source Sans Pro', Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  //font-family: "Roboto", "Source Sans Pro", source-sans-pro, Helvetica, Arial, sans-serif;
  outline: 0 !important;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden !important;
}





// Table
table {
  border-top: solid 2px $light3;
  margin-bottom: $padding * 2;

  >thead {
    font-size: $font-largish;

    >tr {
      border-bottom: none;
      background: $light2;

      >th {
        border-bottom: none;
        padding: $spacing;

      }
    }
  }

  >tbody {
    font-size: $font-normal;

    >tr {
      border-top: none;
      border-bottom: solid 1px $light2;

      td {

        padding: $spacing;
      }

      &:last-child {
        border-bottom: none;
        font-weight: 400;
        font-size: $font-small;
      }

      >td,
      >th {
        border-top: none;
        border-bottom: none;

      }
    }
  }
}