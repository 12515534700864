.wrapper .content-wrapper .main-container-wrapper .main-container.home-page {

    @media (min-width: $size-desktop) {
        padding: $padding * 4 0 $padding * 4 0;

    }


    @media ((min-width: $size-mobile) and (max-width: $size-tablet)) {

        padding-left: $padding;
        padding-right: $padding;
    }

    .section-header {
        padding: $padding;
        padding-top: $padding * 2;
        padding-bottom: $padding * 4;


        @media (min-width: $size-mobile) and (max-width: $size-desktop) {
            padding: $padding * 6 0;
        }

    }

    .featured-in {
        text-align: center;
        margin-top: $padding * 5;
        background: lighten($light2, 6);
        padding: $padding * 3 $padding * 3;
        padding-bottom: $padding * 6;

        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));
    

        @media (min-width: $size-mobile) and (max-width: $size-tablet) {
            background: $light1;
            padding-bottom: 0;
            margin-top: $padding;

        }

        .number-one {
            font-size: $font-large * 1.2;
            line-height: $font-large * 1.6;
            font-weight: 400;
            text-align: center;
            margin-bottom: $padding * 4;
            font-weight: 600;
        }

        p {
            text-align: center;
            line-height: 1;
            margin-bottom: $padding * 2;
            font-size: $font-large * 1.2;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: $padding * 3;

                @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                    margin-bottom: $padding * 3;
                }

                @media (max-width: $size-mobile) {
                    margin-bottom: $padding * 2;
                    margin-right: $padding * 2;
                }




                .featured-logo {
                    max-height: $padding * 2;
                    filter: grayscale(1);
                    opacity: 0.7;
                }
            }
        }
    }
}