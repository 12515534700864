.job-copilot-landing {
	color: $dark5;

	.content {
		padding: $padding;
		margin-block: $padding * 5;

		@media screen and (max-width: $size-mobile) {
			margin-block: $padding * 2;
		}
	}
}

.cop-btn {
	padding: $padding * 1.25 $padding * 3;
	font-size: $font-large * 1.4;
	line-height: $font-large * 1.5;
	background-color: $green1;
	color: $light1;
	text-decoration: none;
	display: inline-block;
	border-radius: $rad;
	box-shadow: 4px 4px 0px 0px darken($green2, 10%);
	margin-top: $padding * 3;
	margin-bottom: $padding;

	&:hover {
		transition: all 0.17s ease-in-out;
		background-color: lighten($green2, 5%);
		box-shadow: 6px 6px 0px 0px darken($green2, 10%);
		text-decoration: none;
		color: $light1;
	}

	&:active,
	&:focus {
		transition: all 0.1s ease-in-out;
		background-color: darken($green2, 5%);
		box-shadow: 2px 2px 0px 0px darken($green2, 10%);
		text-decoration: none;
		color: $light1;
	}

	&.cop-btn-small {
		padding: $padding * 1 $padding * 2;
		font-size: $font-large * 1.1;
		line-height: $font-large * 1.3;
		box-shadow: none !important;
		width: 100%;
	}

	&.cop-btn-pink {
		background-color: $pink;
		color: $light1;
		box-shadow: 4px 4px 0px 0px darken($pink, 10%);

		&:hover {
			background-color: darken($pink, 5%);
		}
	}


}

.job-copilot-hero {
	.content {
		text-align: center;
		max-width: $size-tablet;
		margin-inline: auto;
	}

	h1 {
		font-weight: 900;
		font-size: $font-huge * 1.3;
		line-height: $font-huge * 1.4;
		color: $dark5;
		margin: $padding * 2 0;

		@media (max-width: $size-mobile) {
			font-size: $font-huge * 1.1;
			line-height: $font-huge * 1.2;
		}

		span {
			display: block;

			&:last-child {
				color: $green1;
			}
		}
	}

	p {
		margin: 0;
		width: 100%;
		font-size: $font-large * 1.4;
		line-height: $font-large * 1.8;

		@media (max-width: $size-mobile) {
			font-size: $font-large * 1.2;
			line-height: $font-large * 1.5;
		}
	}


}

.job-copilot-as-seen-on {
	width: 100%;
	border-bottom: none; //solid 1px $light2;
	background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, $light1, lighten($light2, 3));

	display: flex;
	justify-content: center;

	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 0 15px;
	/* Add some padding */


	.content {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		max-width: $size-tablet;
	}

	h3 {
		font-size: $font-large * 1.2;
		line-height: $font-large * 1.5;
		font-weight: 600;
		width: 100%;
		text-align: center;
		margin: 0;
	}

	h4 {
		font-size: $font-large * 1.2;
		line-height: $font-large * 1.5;
		font-weight: 400;
		color: $dark1;
		width: 100%;
		text-align: center;
		margin-top: $padding * 5;
	}

	.as-seen-on-logos {
		justify-content: space-between;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: $padding * 1.5;
		margin-top: $padding * 1.5;
		width: 100%;

		img {
			display: inline-block;
			height: $padding * 2.5;
			object-fit: contain;
			opacity: 0.5;
		}
	}
}

.text-accent {
	color: $pink;
}

.text-accent-green {
	color: $green1;
}

.job-copilot-how-it-works {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;




	.content {
		max-width: $size-desktop;
		margin: $padding * 10 0;
		flex-flow: row wrap;
	}

	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 2;
	}

	h6 {
		font-size: $font-large * 1.3;
		line-height: $font-large * 1.6;
		margin: 0;
		color: $dark1;
		font-weight: 400;
	}

	.steps {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 0;
		margin-top: $padding * 3;
	}

	.step-wrapper {
		padding: $padding;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100% / 3);

		@media screen and (max-width: $size-mobile) {
			flex-direction: row;
			width: 100%;
		}

		.step {
			border-radius: $rad;
			border: 2px solid $pink;
			padding: $padding * 2;

			display: flex;
			flex-direction: column;
			align-items: center;
			background: $light1;
			box-shadow: 0px 4px 0px 0px $pink;


			h4 {
				font-size: $font-large * 1.2;
				line-height: 1;
				font-weight: 500;
				color: $light1;
				background-color: $pink;
				margin: 0;
				padding: 0;
				border-radius: 100%;
				width: $padding * 2.5;
				height: $padding * 2.5;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: $padding * 1;
			}

			p {
				font-weight: 400;
				text-align: center;
				font-size: $font-large * 1.1;
				color: $dark5;
				line-height: $font-large * 1.5;
			}
		}
	}
}

.testimonial-wrapper {
	width: 100%;
	padding: $padding * 4 $padding * 2;
	display: flex;
	justify-content: center;


	.testimonial {
		background-color: $pink;
		border-radius: $rad;
		padding: $padding * 3;
		max-width: $size-tablet;
		box-shadow: 0px 6px 0px 0px darken($pink, 10%);
		position: relative;

		@media screen and (max-width: $size-mobile) {
			margin-left: -$padding * 3;
			margin-right: -$padding * 3;
			border-radius: 0;
		}


		&::before {
			content: "“";
			position: absolute;
			bottom: -$padding * 4;
			left: $padding * 2;
			font-size: $font-huge * 6;
			font-weight: 700;
			color: rgba(255, 255, 255, 0.2);
			line-height: 1;
		}

		.quote {
			color: $light1;
			font-size: $font-large * 1.4;
			line-height: $font-large * 1.8;
			font-weight: 400;
			text-align: center;
			margin-bottom: $padding * 2;


			@media screen and (max-width: $size-mobile) {
				font-size: $font-large * 1.2;
				line-height: $font-large * 1.5;
			}
		}

		.author {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: $padding * 1;

			.avatar {
				width: $padding * 4;
				height: $padding * 4;
				border-radius: 50%;
				border: 2px solid $light1;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.info {
				text-align: left;

				h5 {
					color: $light1;
					font-size: $font-large * 1.2;
					line-height: $font-large * 1.5;
					font-weight: 600;
					margin: 0;
				}

				p {
					color: rgba(255, 255, 255, 0.8);
					font-size: $font-normal;
					margin: 0;
				}
			}
		}
	}
}

.job-copilot-why-use {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;

	background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, $light1, lighten($light2, 3));

	.content {
		max-width: $size-tablet;
		margin: $padding * 10 0;
		flex-flow: row wrap;
	}

	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 2;
	}

	h6 {
		font-size: $font-large * 1.3;
		line-height: $font-large * 1.6;
		margin: 0;
		color: $dark1;
		font-weight: 400;
	}

	.why-use-jobcopilot-items {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 0;
		margin-top: $padding * 3;
		width: 100%;


		.why-use-jobcopilot-item {
			padding: $padding;
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			@media screen and (max-width: $size-mobile) {
				padding: 0;
			}



			.info {
				border-radius: $rad;
				padding: $padding * 2;
				//	background: $light1;
				width: 100%;



				h4 {
					font-size: $font-largest * 1.4;
					font-weight: 600;
					line-height: $font-largest * 1.8;
					text-align: left;
					margin: 0 0 $spacing 0;
				}

				p {
					font-weight: 400;
					text-align: left;
					font-size: $font-large * 1.1;
					color: $dark5;
					line-height: $font-large * 1.5;
				}
			}
		}
	}



}

.job-copilot-features {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;

	.content {
		max-width: $size-desktop;
		margin: $padding * 10 0;
		flex-flow: row wrap;

		h2 {
			font-size: $font-huge * 1.2;
			font-weight: 900;
			line-height: $font-huge * 1.5;
			margin: 0 0 $padding * 2 0;
			color: $dark5;
		}

		h6 {
			font-size: $font-large * 1.2;
			line-height: $font-large * 1.5;
			margin: 0;
			font-weight: 400;
		}

		.jobcopilot-features-items {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap; // This allows items to wrap to the next row
			justify-content: center;
			padding-top: $padding * 2;
			gap: $padding * 2;


			.jobcopilot-features-item {
				padding: $padding * 1.5 $padding * 2;
				box-sizing: border-box;
				border-radius: $rad;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				background: lighten($light2, 6%);
				box-shadow: 0px 4px 0px 0px darken($light3, 15%);
				width: calc((100% - ($padding * 4)) / 3); // Adjust width for 3 columns with gap
				gap: $padding * 1.5;


				@media screen and (max-width: $size-mobile) {
					border-radius: 0;
				}

				.img-mask {
					min-width: $padding * 3;
					color: darken($light4, 15%);
					font-size: $font-huge * 1;
				}

				.info {
					text-align: left;

					h4 {
						font-size: $font-largest * 1.2;
						font-weight: 600;
						color: $dark5;
						margin: 0;
						padding: 0;
						margin-bottom: $spacing;
					}

					p {
						color: $dark2;
						font-size: $font-largish * 1.1;
					}
				}
			}
		}
	}
}

// Responsive design for different screen sizes
@media screen and (max-width: 1200px) {
	.job-copilot-features {
		.content {
			.jobcopilot-features-items {
				.jobcopilot-features-item {
					width: calc((100% - ($padding * 2)) / 2); // 2 columns for medium screens
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.job-copilot-features {
		.content {
			.jobcopilot-features-items {
				.jobcopilot-features-item {
					width: 100%; // 1 column for small screens
				}
			}
		}
	}
}


.job-copilot-empower-yourself {
	width: 100%;
	display: flex;
	justify-content: center;
	color: $dark5;
	text-align: center;
	align-items: center;

	.content {
		max-width: $size-tablet;
		margin: $padding * 10 0;
		flex-flow: row wrap;
		display: flex;
		justify-content: center;
		align-items: center;


		h2 {
			font-size: $font-huge * 1.2;
			line-height: $font-huge * 1.5;
			width: 100%;
			margin-bottom: $padding * 2;
		}

		h6 {
			width: 100%;
			font-size: $font-large * 1.2;
			line-height: $font-large * 1.5;
			margin: 0;
			font-weight: 400;
		}

		.plans {
			display: flex;
			gap: $padding * 3;
			margin: $padding * 3 0;
			justify-content: center;
			flex-wrap: wrap;

			.plan {
				border-radius: $rad;
				max-width: $padding * 24;
				overflow: hidden;
				background: #fff;
				box-shadow: 0 4px 0 0 $pink;
				border: 2px solid $pink;

				.plan-head {
					background: rgba($pink, 0.15);
					color: $pink;
					font-size: $font-large * 1.3;
					padding: $padding * 0.75;
					font-weight: 600;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.plan-content {
					padding: $padding * 2;

					.btn-signup {
						margin-bottom: 0;
						margin-top: $padding * 2;
					}

				}

				.plan-description {

					margin: 0;
					padding: 0;
					text-align: left;
					display: flex;
					flex-direction: column;
					gap: $padding * 0.75;

					li {
						list-style: none;

						margin: 0;
						padding: 0;
						font-size: $font-large * 1;
						line-height: $font-large * 1.4;
					}

				}
			}

		}




	}
}

.job-copilot-join {
	width: 100%;
	display: flex;
	justify-content: center;

	.content {
		max-width: $size-tablet;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		text-align: center;


		h2 {
			font-size: $font-huge * 1.2;
			line-height: $font-huge * 1.5;
			font-weight: 600;
			width: 100%;
			margin-bottom: $padding * 0;
		}



		.ready-to-automate {
			display: flex;
			justify-content: center;
			gap: $padding * 3;
			margin-top: $padding * 5;
			background-color: $light2;
			border-radius: $rad;
			padding: $padding * 5 $padding * 3;
			box-shadow: 0 4px 0 0 $pink;
			flex-flow: row wrap;

			h3 {
				margin: 0;
				font-size: $font-large * 1.2;
				font-weight: 500;
				width: 100%;
				line-height: $font-large * 1.5;
			}

		}


		.payment-notice {
			text-align: center;
			width: 100%;
			color: $dark2;
			font-style: italic;
			font-size: $font-large * 1;
			margin-top: $padding;
		}
	}
}