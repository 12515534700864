.post-job-sidebar-wrapper {
    border-left: solid 1px $light3;
    padding-left: 0 !important;

    .post-job-sidebar {


        .post-job-sidebar-about {
            padding: $padding * 3;
            padding-bottom: $padding * 1.5;
            text-align: left;
            position: relative;

            @media (max-width: $size-mobile) {
                padding: $padding * 1.5 $padding;
            }

            .post-job-sidebar-arrow {
                color: lighten($light4, 15);
                font-size: $font-huge;
                display: block;
                margin: $padding * 3 auto 0 auto;
                display: none;


            }


            h2.post-job-sidebar-title {
                font-size: $font-largest * 1.28;
                color: $dark5;

                font-weight: 400;
                line-height: 1.25;

                .emph {
                    font-weight: 900;
                    color: $pink;
                }
            }



            h3.post-job-sidebar-subtitle {
                font-size: $font-large * 1.08;
                line-height: 1.5;
                padding-top: $padding * 1.5;
                color: $dark4;
                font-style: italic;
            }

            .post-job-sidebar-desc {
                font-size: $font-large * 0.94;
                line-height: 1.5;
                padding-top: $padding * 1.5;
                color: $dark1;


                em {
                    font-style: normal;
                }

                a {
                    color: $dark1;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }


        }

        .post-job-sidebar-box {
            padding-bottom: $padding * 5;
        }

        .post-job-sidebar-image {
            //   border-bottom: solid 1px lighten($light3, 8);

            &.credit-debit {
                .post-job-sidebar-image-img {
                    padding: $padding * 2;
                }
            }

            .post-job-sidebar-image-img {
                max-width: 100%;
                min-width: 100%;
                padding: $padding * 1.5 0;
            }

            .post-job-sidebar-image-caption {
                padding: $padding * 1.5;
                padding-bottom: 0;
                font-size: $font-large;
                font-weight: 700;
                color: $dark3;
                text-align: center;
            }
        }

        .post-job-sidebar-numbers {
            padding: $padding * 3;
            //border-bottom: solid 1px lighten($light3, 8);

            .post-job-number {
                text-align: center;

                padding-bottom: $padding * 1.5;

                &:last-child {
                    padding-bottom: 0;
                }


                .post-job-number-title {
                    font-size: $font-large;
                    color: $dark1;
                    padding-bottom: 0;
                    ;
                    ;
                }

                .post-job-number-value {
                    font-size: $font-huge * 0.85;
                    line-height: 1.25;
                    margin: 0;
                    padding: 0;
                    font-weight: 600;
                }

                .post-job-number-bottom {
                    font-size: $font-largish;
                    color: $dark1;
                    padding-bottom: $padding;
                }

            }

        }

        .post-job-sidebar-trusted-by {
            padding: $padding * 2;
            text-align: center;
            //    border-bottom: solid 1px lighten($light3, 8);

            @media (max-width: $size-mobile) {
                padding: $spacing * 1;
            }

            p {
                text-align: center;
                line-height: 1;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    width: 50%;
                    padding: $spacing;
                    display: inline-block;
                    list-style: none;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}