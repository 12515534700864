.go-to-top-button {
    position: fixed;
    bottom: $padding * 2;
    right: $padding * 3;
    z-index: 1000000;
    cursor: pointer;

    .svg-inline--fa {
        margin-right: $spacing;
        opacity: 0.8;
    }
}