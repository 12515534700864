.company-page {




    .company-header-wrapper {
        margin-bottom: 0;
        width: 100%;
        padding: $padding * 2 $padding;
        padding-top: $padding * 2;
        background: $green1;
        background: #1d976c;
        /* fallback for old browsers */

        border-bottom: solid 1px $light3;
        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));

        //  min-height: 320px; // CLS
        @media (max-width: $size-mobile) {
            //  min-height: 520px; // CLS
        }


        @media (min-width: $size-mobile) and (max-width: $size-tablet) {
            background: $light1;
            padding: $padding * 2 0;
        }

        .company-header-content {
            max-width: $size-desktop;
            margin: 0 auto;

            @media (max-width: $size-mobile) {
                margin-top: $padding * 4;
            }

            .company-header {
                padding-top: $spacing;
            }

            .company-name {
                margin-bottom: $spacing;
            }


            .company-desc {
                color: $dark1;
                font-weight: 400;
                font-size: $font-large;
                line-height: $font-large * 1.25;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
                margin: $spacing 0 $padding 0;
            }


            .tags {
                padding: 0;
                margin: 0;

                li {

                    //  border: none;
                    &.offered,
                    &.salary-100, &.would-consider{
                        //    color: darken($dark3, 5%);
                        border: solid 1px $green1; //: rgba(215, 255, 225, 1);
                        color: $green1;
                        background-color: rgba(245, 255, 255, 0.5);
                    }

                     &.nine-day-fortnight, &.part-time, &.offered-4-5-days, &.offered-every-2nd-week, &.offered-summer {
                        border: solid 1px rgba($blue1,0.5);
                        background-color: rgba(230,245,255, 0.9);
                        color: darken($blue1, 30%);
                    }

                    &.flexible-hours, &.generous-pto, &.unlimited-pto, &.flex-fridays {
                        border: solid 1px rgba($pink,0.4);
                        background-color: rgb(255, 248, 252);
                        color: darken($pink, 10%);
                    }

                    &.offered-other {
                        //    color: darken($dark3, 5%);
                        border: solid 1px $light3; //: rgba(215, 255, 225, 1);
                        color: $dark1;
                    }

                }
            }

            .company-image {
                margin-right: $spacing * 1.5;
                max-width: $padding * 10;

                @media (max-width: $size-mobile) {
                    margin-bottom: $padding;
                    height: $padding * 3;
                    width: $padding * 3;

                }

            }


            .company-header-left {
                .header-top {
                    padding-bottom: $spacing;

                    img,
                    h1 {
                        display: inline-block;
                    }

                    h1 {
                        vertical-align: middle;
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                    }
                }



                .breadcrumbs {
                    margin: 0;
                    padding: 0;
                    margin-bottom: $padding * 1;
                    ;

                    li {
                        margin: 0;
                        padding: 0;

                        display: inline-block;

                        a {
                            color: $dark1;
                        }

                        &:first-child {
                            margin-right: $padding;

                            &:after {
                                content: "/";
                                margin-left: $padding;
                                color: lighten($dark1, 30);

                            }
                        }
                    }
                }

                .tags {

                    display: flex;
                    justify-content: flex-start;

                    @media (max-width: $size-mobile) {
                        display: block;

                        justify-content: unset;

                    }

                    .company-hq {
                        border: none;
                        background-color: transparent;
                        color: $dark1;

                        flex-grow: 1;

                        .svg-inline--fa {
                            opacity: 0.35;
                            margin-right: $rad;
                            max-width: $padding * 1;
                            max-height: $padding * 1;
                        }
                    }
                }
            }


            .company-header-right {
                text-align: center;

                @media (max-width: $size-mobile) {
                    padding: $padding 0 0 0;
                    margin-bottom: 0;

                    button {
                        width: 100%;
                    }
                }


            }

        }

    }

    .company-content {

        max-width: $size-desktop;
        margin: 0 auto;

        padding-top: 0;


        @media (max-width: $size-mobile) {
            padding: 0 $padding;

        }


        @media (min-width: $size-mobile) and (max-width: $size-tablet) {
            padding: 0;

        }

        @media (min-width: $size-tablet) and (max-width: $size-desktop) {
            padding: 0 $padding;

        }

        .company-reference {
            color: $dark2;
            font-weight: 400;
            font-size: $font-largish;
            line-height: $font-largish * 1.5;
            margin-bottom: $padding * 2;
            font-style: italic;
            @include box-theme();
        }


        .company-description {
            margin-top: 0;

            padding: $padding * 2 0;

            @media (max-width: $size-mobile) {}

            h3,
            h2 {
                font-size: $font-largest * 0.90;
                font-weight: 700;
                margin-bottom: $spacing;
                line-height: 1;
                color: $dark2;

            }




            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    color: $blue2;
                }
            }

            img {
                max-width: 100%;
            }

            p {
                font-size: $font-largish;
                line-height: 1.70;
            }
        }



        .company-links {
            padding-top: $padding * 1;
            margin-bottom: $padding;

            // On mobile
            @media (max-width: $size-mobile) {
                .company-extra-links {
                    padding-top: $padding * 4;
                }
            }

            .btn-success {
                width: 100%;
            }

            .company-social {
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    list-style: none;
                    margin-right: $padding;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }





        .no-jobs {
            @media (max-width: $size-mobile) {
                padding: 0 $padding;
            }
        }


        // Jobs list
        .company-jobs-wrapper {

            .company-jobs {


                padding: 0;
                margin-top: $padding;

                @media (max-width: $size-mobile) {
                    padding-bottom: 0;
                }

                .job-listing {


                    @media (max-width: $size-mobile) {
                        .job-content {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }
                    }
                }

                .job-tile-wrapper {
                    @media (max-width: $size-mobile) {
                        margin-left: -$padding;
                        margin-right: -$padding;
                        position: relative;


                    }


                    @media (max-width: $size-mobile) {
                        .job-tile-expired {
                            padding-bottom: $spacing;
                        }
                    }
                }

                .notjobtavert-tile-wrapper {
                    @media (max-width: $size-mobile) {
                        margin-left: -$padding;
                        margin-right: -$padding;

                    }

                }

                .num-jobs {
                    display: none;
                }



            }
        }

        .company-meta {

            .company-meta-boxes {
                border-left: solid 1px $light3;

                @media (max-width: $size-mobile) {
                    border-left: none;

                }


            }

            // On mobile
            @media (max-width: $size-mobile) {
                padding-top: $padding * 4;

            }

            .company-share-buttons {
                text-align: center;

                @media (max-width: $size-mobile) {
                    padding-bottom: $padding * 4;

                }

                p {
                    font-size: $font-small;
                }

                button {
                    margin-right: $rad;
                }
            }


        }

        // Newsletter subscription



    }
}


.companies-page {
    .companies-list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }

        .subscriber {
            margin-bottom: $padding;

            @media (max-width: $size-mobile) {
                margin-bottom: 0;
                border: none;
                border-top: solid 1px $light3;
                @include border-radius(0);
            }


        }


    }

    .empty-list {
        font-size: $font-largest * 1.25;
        padding: $padding * 3;
    }

    .nottavert-tile-wrapper {
        margin-bottom: $padding * 1;
        border-bottom: solid 1px $light3;
        padding: $padding * 1.25 $padding * 1.5;
        @include border-radius($spacing);
        border-color: darken($light2, 3);


        @media (max-width: $size-mobile) {
            background-color: white;
            @include border-radius(0);

            padding: $padding * 1 $padding * 1;

            .notad-sponsor {
                padding-top: $spacing;
            }

        }

        .nottavert-tile {
            padding: 0;

            .nottavert-tile-top {

                @media (max-width: $size-mobile) {
                    padding: $spacing;
                }



                h5 {
                    padding-left: $padding * 0;

                    @media (max-width: $size-mobile) {
                        padding-left: $padding * 1.5;

                    }
                }
            }
        }




        .nottavert-tile-description {
            color: $dark1;
            line-height: 1.6;
        }

        @media (max-width: $size-mobile) {
            margin-bottom: 0;
            border-bottom: none;

        }

    }
}