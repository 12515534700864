.notjobtavert-tile-wrapper {
    max-width: $size-mobile;
    margin: 0 auto;
    display: block;
    position: relative;
    padding: $padding $padding * 2;
    padding-right: $padding;
    overflow: visible;
    // background: lighten($light2, 6);
    border: solid 1px $light3;
    border-bottom: none;

    //  @include box-shadow-light();

    &:hover {
        @include box-shadow();
        background-color: lighten($light2, 3);

    }


    @media (max-width: $size-mobile) {
        overflow: hidden;
        padding: 0;
        @include border-radius(0);
        //  border-left: none;
        border-right: none;
        border-bottom: none;
        border-left: none;

        margin: 0;

        //    border-top: solid 2px lighten($light2, 3) !important;
    }


    .nottavert-logo {
        display: none;
    }


    .nottavert-tile-image {
        position: absolute;
        border: solid 1px $light3;
        background: $light2;
        background-size: contain;
        height: $padding * 3;
        line-height: $padding * 2.5;
        width: $padding * 3;
        top: $padding;
        left: -$padding * 1.75;
        // z-index: 1;
        @include border-radius(50%);

        @media (max-width: $size-mobile) {
            height: $padding * 1.5;
            width: $padding * 1.5;
            top: $padding * 1.25;
            border: none;
            left: $padding;
        }
    }


    &.hovering {
        border-left: solid 1px lighten($light2, 2);

    }

    &:hover {
        text-decoration: none !important;
        //   border-left-width:  $spacing;

    }

    .nottavert-tile {

        position: relative;
        line-height: 1;
        display: flex;
        flex-direction: column;
        // overflow: auto !important;

        @media (max-width: $size-mobile) {
            padding: $padding * 1.25 $padding;
            @include border-radius(0);
            flex-direction: column;
            margin-bottom: 0;
            line-height: 1;
            // border-bottom: solid 1px $light2;
        }



        .row {
            width: 100%;
        }


        .nottavert-tile-left {
            padding-bottom: $spacing;

            .nottavert-tile-title {
                margin: 0;

                @media (max-width: $size-mobile) {
                    padding-bottom: $rad;
                }



                h3 {
                    text-align: left;
                    margin: 0;
                    padding: 0;

                    line-height: 1;

                    @media (max-width: $size-mobile) {
                        padding-top: $rad;
                    }

                    a {
                        font-weight: 700;
                        font-size: $font-large;

                        @media (max-width: $size-mobile) {
                            white-space: normal;
                            text-overflow: unset;
                        }
                    }
                }


            }

            .nottavert-tile-top {
                color: $dark5;
                font-size: $font-largish;
                font-weight: 400;
                margin: 0;
                padding-bottom: $rad;

                .notad-sponsor {
                    font-size: $font-small;
                    color: $dark1;
                    padding-left: $padding;
                    display: none;
                    

                    @media (max-width: $size-mobile) {
                        padding-left: 0;
                        position: absolute;
                        right: $spacing;
                        top: 0;
                        display: block;
                        ;

                    }


                }



                h5 {
                    text-align: left;
                    line-height: 1;
                    padding: 0;
                    margin: 0;
                    font-size: $font-largish;
                    font-weight: 400;


                    @media (max-width: $size-mobile) {
                        padding-left: $padding * 2;
                        padding-top: $rad;
                    }
                }




                &:hover {
                    text-decoration: none;
                    color: $pink !important;
                }


                @media (max-width: $size-mobile) {
                    padding-bottom: $spacing;
                }
            }



        }

        .nottavert-tile-right {
            text-align: right;

            .nottavert-tile-cta {
                @media (max-width: $size-mobile) {
                    width: 100%;
                    display: none;
                }
            }

            .nottavert-tile-time {

                text-align: right;
                margin-bottom: $spacing;
                font-size: $font-normal;
                color: $dark1;
                line-height: 1;
                font-weight: 400;

                @media (max-width: $size-mobile) {
                    display: none;
                }

            }



            .nottavert-tile-apply {
                @media (max-width: $size-mobile) {
                    display: none;
                }

            }





        }


        .nottavert-tile-description {
            color: $dark2;
            line-height: 1.4;
            display: block;
            margin: 0;
            padding-bottom: $spacing;
            font-weight: 400;
            font-size: $font-largish * 0.9;
            @media (max-width: $size-mobile) {
                text-align: left;
                padding-bottom: $spacing;

            }



        }

        .nottavert-tile-tags {
            margin: 0;
            padding: 0;


            li {
                list-style: none;
                margin-right: $spacing * 0.75;
                display: inline-block;
                line-height: 1.25;
                font-size: $font-normal * 0.94;

                background: rgba(225, 235, 245, 1);
                color: $dark2;
                @include border-radius($spacing);
                padding: $rad * 1 $rad * 1.5;

                &.success {
                    background: rgba(215, 255, 225, 0.8);
                    color: darken($green1, 12%);
                }

                @media (max-width: $size-mobile) {
                    font-size: $font-small;
                    line-height: 1;
                    //       padding-bottom: 0;
                }
            }
        }

        .nottavert-tile-cta {
            margin-top: $spacing;


        }

    }
}