
@media (min-width: $size-mobile) {
    .show-mobile {
      display: none !important;
    }
  }
  
  @media (max-width: $size-tablet) {
    .hide-tablet {
      display: none !important;
    }
  }
  
  @media (max-width: $size-mobile) {
    .hide-mobile {
      display: none !important;
    }
  }
  
  @media (max-width: $size-tablet) {
    .hide-tablet {
      display: none !important;
    }
  }
  
  @media (min-width: $size-tablet) {
    .hide-desktop {
      display: none !important;
    }
  }
  