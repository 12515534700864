// List page

.articles-page {

    @media (max-width: $size-mobile) {

        section.row:nth-child(2) {
            padding: 0;
        }
    }

    .articles-list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .article-item {
            margin-bottom: $padding * 2;
            width: calc(100% / 3);
            padding: $padding;


            @media (max-width: $size-mobile) {
                width: 100%;
                padding: 0;
            }

            &:hover {
                text-decoration: none;
            }

            &.article-item-full {
                padding-bottom: 0;
                margin-bottom: $padding;

                .article-info {
                    .article-title {
                        font-size: $font-largest * 1.2;
                        line-height: 1.3;
                    }

                    .article-page-subtitle {
                        font-size: $font-large;
                        line-height: 1.5;
                    }
                }
            }

            // First only
            @media (min-width: $size-mobile) {

                &:nth-child(1) {
                    width: 100%;
                    display: flex;

                    .article-info {
                        width: 50%;
                        padding-left: $padding;

                        .article-meta {
                            padding-top: 0;

                            h3 {
                                font-size: $font-large !important;
                                line-height: 1.5;
                            }

                        }
                    }

                    .article-image-wrapper {
                        width: 50%;
                        display: inline-block;
                        padding-right: $padding * 1;

                        .article-image {
                            @include border-radius($rad);
                            max-width: 100%;
                            height: $padding * 13;

                        }
                    }

                }
            }

            // Normal row
            .article-image-wrapper {

                @media (max-width: $size-mobile) {
                    margin-bottom: $padding;
                }

                overflow: hidden;

                .article-image {
                    @include border-radius($rad);
                    max-width: 100%;
                    height: $padding * 17;
                    background-size: cover;
                    border: solid 1px $light2;
                    @include transition-fade(150ms);

                    &:hover {
                        opacity: 0.8;
                    }

                    @media (max-width: $size-mobile) {
                        @include border-radius(0)
                    }

                }
            }

            .article-info {
                padding: 0;

                @media (max-width: $size-mobile) {
                    padding: $padding;
                    padding-top: 0;
                    text-align: left;
                }

                .article-meta {
                    padding-top: $padding;

                    @media (max-width: $size-mobile) {
                        padding-top: 0;
                    }

                    h3 {
                        display: inline-block;
                        font-size: $font-normal;
                        color: $dark1;
                        margin-top: 0;
                        line-height: 1;

                        &:nth-child(2) {
                            margin-left: $spacing;
                        }
                    }
                }

                .article-title {
                    color: $dark5;
                    font-weight: 700;
                    font-size: $font-largest;
                    text-align: left;
                    margin-top: $spacing;
                    line-height: $font-large * 1.5;


                    &:hover {
                        color: $pink !important
                    }


                }

            }
        }






    }


}