.job-page {


  .company-meta {

    .company-meta-boxes {
      border-left: solid 1px $light3;

      @media (max-width: $size-mobile) {
        border-left: none;

      }

    }

    // On mobile
    @media (max-width: $size-mobile) {
      margin-top: $padding * 6;
    }
  }


  .job-share-buttons {
    margin-top: $padding * 3;
    margin-bottom: $padding * 3;

    p {
      font-size: $font-small;
    }

    button {
      margin-right: $rad;
    }
  }

  // Header
  .job-header-wrapper {
    width: 100%;
    padding: $padding * 2 $padding;

    background: $green1;
    border-bottom: none; //solid 1px $light2;
    background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, $light1, lighten($light2, 3));

    @media (min-width: $size-mobile) and (max-width: $size-tablet) {
      background: white;
      padding: $padding * 2 0;
      border-bottom: solid 1px $light3;
    }



    //    min-height: 270px; // CLS

    @media (max-width: $size-mobile) {
      padding-top: $padding * 5;
    }


    .job-apply-button {
      @media (max-width: $size-mobile) {

        display: none;
      }

    }


    .job-header-content {
      max-width: $size-desktop;
      margin: 0 auto;

      h1 {
        font-size: $font-huge;
        text-wrap: balance;

        @media (max-width: $size-mobile) {
          font-size: $font-huge * 0.9;

        }


      }

      .breadcrumbs {
        margin: 0;
        padding: 0;
        margin-bottom: $spacing;
        ;

        li {
          margin: 0;
          padding: 0;

          display: inline-block;

          a {
            color: $dark1;
          }

          &:first-child {
            margin-right: $padding;

            &:after {
              content: "/";
              margin-left: $padding;
              color: lighten($dark1, 30);

            }
          }
        }
      }

      .company-info {

        margin-bottom: $padding;

        .title-logo {
          display: inline-block;

          img {
            @include border-radius($spacing);
          }

          margin-bottom: $spacing;
        }

        .title-sub {
          display: inline-block;
          line-height: $padding * 3;
          padding: 0;
          margin: 0;
          padding-left: $spacing;
          vertical-align: middle;
          font-size: $font-large;
          font-weight: 400;
          color: $dark2;

          a {
            vertical-align: middle;
            display: block;
          }
        }

        .title-reference {
          padding: 0;
          font-size: unset;
          line-height: 1.3;
          font-size: $font-largish;
          font-weight: 400;
          padding-top: $spacing;
          color: $dark1;
          margin: 0;
          max-width: $size-desktop;
          margin: 0 auto;
          padding-bottom: 0;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* number of lines to show */
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }

      }





      h1 {

        margin-bottom: $spacing;
      }

      .apply-warning {
        color: $pink;
        font-size: $font-small;
        text-align: center;
        padding-top: $spacing;
        max-width: 100%; //$padding * 12;
        margin: 0 auto;
      }

      .job-apply-wrapper {
        padding-top: $padding * 1;
        text-align: center;



        @media (max-width: $size-mobile) {
          display: none;
        }


        .ai {
          .btn-default {
            border: solid 1px $light3 !important;
            @include border-radius($spacing);
            //  background-color: white;
          }
        }


        .apply-location {
          color: $light2;
          font-weight: 400;
          font-size: $font-largish;
          padding-top: $spacing * 0.75;
        }

        .btn-success {
          width: 100%;

          @media(max-width: $size-mobile) {
            width: 100%;
          }
        }
      }

      .job-posted {
        text-align: center;
        font-size: $font-normal;
        color: $dark1;
        margin-bottom: 0;
        padding-bottom: $spacing;

        @media(max-width: $size-mobile) {
          padding-top: $spacing;
          text-align: left;
          padding-bottom: 0;
        }


      }

      .job-tile-expired {
        font-size: $font-largest * 1.25;
        color: $pink;
        font-weight: 900;
        text-align: center;
        margin-top: $padding * 1.5;
        padding: $padding;
        background: rgba($red1, 0.14);
        @include border-radius($rad);
      }


      .job-tags {

        padding-top: 0;

        li {

          .fa-w-16 {
            margin-right: $rad / 2;
            color: rgba($green2, 0.5) !important;
          }


          a {
            color: $dark1;
          }



          &.offered,
          &.would-consider,
          &.salary-100 {
            //    color: darken($dark3, 5%);
            border: solid 1px $green1; //: rgba(215, 255, 225, 1);
            color: darken($green1, 12%);
            background-color: rgba(245, 255, 255, 0.5);

            a {
              color: $green1;
            }
          }

          &.nine-day-fortnight,
          &.part-time,
          &.offered,
          &.offered-every-2nd-week,
          &.offered-summer {
            border: solid 1px rgba($blue1, 0.5);
            background-color: rgba(230, 245, 255, 0.9);
            color: darken($blue1, 30%);

            a {

              color: darken($blue1, 30%);
            }
          }

          &.flexible-hours,
          &.generous-pto,
          &.unlimited-pto,
          &.flex-fridays {
            border: solid 1px rgba($pink, 0.4);
            background-color: rgb(255, 248, 252);

            a {

              color: darken($pink, 10%) !important
            }
          }

          &.offered-other {
            //    color: darken($dark3, 5%);
            border: solid 1px $light3; //: rgba(215, 255, 225, 1);
            color: $dark1;
          }


        }
      }

    }

  }


  // Main content
  .job-content {

    max-width: $size-desktop;
    margin: 0 auto;

    @media (max-width: $size-desktop) {
      padding: 0 $padding;

    }

    @media(max-width: $size-mobile) {
      padding: $padding;
      padding-top: 0;

    }


    @media (min-width: $size-mobile) and (max-width: $size-tablet) {
      padding: 0;
    }


    @media (min-width: $size-tablet) and (max-width: $size-desktop) {
      padding: 0 $padding;
      ;
    }


    .view-more-button {
      font-size: $font-small;
      margin-bottom: $padding * 2;

      @media(max-width: $size-mobile) {
        margin-bottom: $padding * 4;

      }
    }

    .hero-left {

      padding-top: $padding * 2;

      @media(max-width: $size-mobile) {
        padding-top: $padding;
      }
    }

    .apply-warning {
      font-style: italic;
      padding: $spacing $padding;
      color: darken($pink, 40);
      @include border-radius($spacing);
      border: solid 1px lighten($pink, 45);
      border-left: solid 4px $pink;
      background-color: rgba($pink, 0.07);
    }


    .job-description-text {
      min-height: 500px; // CLS

      img {
        display: none;
      }

      em strong {
        color: $pink;
        font-style: normal;
        font-weight: 900;
      }


      * {
        max-width: 100%;
      }

      h1 {
        font-size: $font-large;
        line-height: $font-large * 1.2;
        border-bottom: solid 1px $dark1;
        margin-bottom: $padding;
        padding-bottom: $padding;
      }

      h2 {
        font-size: $font-largest;
        line-height: $font-large * 1.2;
        //  border-bottom: solid 1px $light;
        margin-bottom: 0;
        padding-bottom: $spacing;
      }

      h3 {
        font-size: $font-large * 1.1;
        font-weight: 900;
      }

      p {
        strong:only-child {

          font-size: $font-largish;
          font-weight: 900;
          color: $dark2;


        }
      }

      h4 {
        font-size: $font-largish;
        font-weight: 700;
        margin: 0;

      }

      h5 {
        font-size: $font-largish;
        font-weight: 700;
        margin: 0;

      }

      h6 {
        font-size: $font-largish;
        font-weight: 700;
        margin: 0;

      }


      ul {
        padding: 0 0 0 $padding;

        li {
          p {
            margin-bottom: 0 !important;
          }
        }
      }

      a {
        font-weight: 400 !important;

        text-decoration: underline !important;

        &:hover {
          text-decoration: none !important;
        }

        &:focus {
          color: $blue2 !important;
        }

      }

    }

    .job-apply {

      @media(max-width: $size-mobile) {
        padding: 0 0 $padding * 3 0;
      }



      .apply-location {
        color: $light2;
        font-weight: 400;
        font-size: $font-largish;
        padding-top: $spacing * 0.75;

      }

      .job-tile-expired {
        font-size: $font-largest * 1.25;
        color: $pink;
        font-weight: 900;
        text-align: center;
        margin-top: $padding * 1.5;
        padding: $padding;
        background: rgba($red1, 0.14);
        @include border-radius($rad);
      }



      .apply-warning {
        color: $pink;
        padding-top: $padding;
      }

      .btn {

        @media(max-width: $size-mobile) {
          width: 100%;
        }

      }
    }






  }


  .application-apply {

    .job-apply-top {

      .btn {
        padding: $padding * 1.2 $padding * 1.5;
      }

      .job-secondary {

        @media(max-width: $size-mobile) {
          display: none;
        }

        .btn {
          text-align: center;
          width: 100%;
          margin-top: $padding;
          background: $light1;
          @include box-shadow-light();
        }
      }
    }




    .job-apply-middle {

      margin-top: $padding * 3;
      margin-bottom: $padding * 8;

      @media (max-width: $size-mobile) {
        //  display: none;
      }

      .btn {
        margin-right: $padding * 2;
        height: 100%;
        display: inline-block;
        padding: $padding * 1.25 $padding * 2.5;
        width: auto;

        .apply-location {
          color: $light2;
          padding-top: $rad * 2;
          font-weight: 400;
          font-size: $font-normal;
        }

        &.btn-ghost {}
      }



    }




    .job-apply-bottom {
      position: fixed;
      z-index: 1000000;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: solid 1px $light3;
      background-color: $light1;
      width: 100%;
      @include box-shadow-light();
      padding: $padding * 0.75 0;

      @media(max-width: $size-mobile) {
        padding: $padding;
        border-top: solid 1px $dark1;
      }

      @media(min-width: $size-desktop) {
        display: none;
      }

      .apply-bottom-inner {

        max-width: $size-desktop - $padding;
        display: flex;
        margin: 0 auto;
        ;

        @media (max-width: $size-desktop) {
          padding: 0 $padding;

        }

        @media(max-width: $size-mobile) {
          padding: 0 $spacing * 1.5;
        }


        .apply-bottom-left {
          vertical-align: middle;
          flex-grow: 1;
          align-self: center;

          @media(max-width: $size-mobile) {
            width: 100%;
            display: none;
          }


          h3 {
            font-weight: 900;
            font-size: $font-largest * 1.5;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            color: $dark5;
            text-overflow: ellipsis;

          }

          .company-name {
            color: $dark1;
            font-weight: 400;
            font-size: $font-large * 1.2 !important;
          }

          .apply-warning {
            color: $pink;
            font-size: $font-largish;
            padding-top: $spacing;
            display: none;

            @media(max-width: $size-mobile) {
              font-size: $font-normal;
              line-height: 1.25;
              padding-bottom: $spacing;
            }
          }

        }

        .apply-bottom-right {
          max-width: $padding * 21;
          width: $padding * 21;
          text-align: center;
          vertical-align: top;
          align-self: center;
          justify-content: space-between;

          @media(max-width: $size-mobile) {
            max-width: 100%;
            width: 100%;
            display: block;
            padding-left: 0;
          }


          .ai {
            margin-top: $spacing;

            .btn {
              @media (max-width: $size-mobile) {
                display: block;
                padding: $spacing $padding;
                color: $dark5;
                background: $light2 !important;
                @include border-radius($padding);

                &:hover,
                &:focus,
                &:active {
                  text-decoration: none;
                  background: $light1 !important;

                  color: $dark5 !important;
                }


              }
            }

          }

          .cta {
            width: 100%;
            padding: $padding * 0.75;
            font-size: $font-largest * 1.1;



            .fa-arrow-right {
              color: $light1;
              margin-left: $rad;
              font-size: $font-small;

            }

            @media(max-width: $size-mobile) {
              padding: $spacing * 1.5;
            }

            .apply-location {
              color: $light2;
              padding-top: $rad * 1.25;
              font-weight: 400;
              font-size: $font-normal;
            }
          }
        }
      }

    }
  }


  .application-apply-footer {

    .application-apply {
      position: fixed;
      z-index: 100000000;
    }
  }
}