.notification-container {
    margin-top: $padding * 4;
    z-index: 1000000;
    width: $padding * 24;

    @media (max-width: $size-mobile) {
        width: 100%;
    }

    >div {
        //    margin: 0 auto;
        //  max-width: $padding * 20;

        padding-right: $padding * 2;
        ;

        @media (max-width: $size-mobile) {
            padding-right: 0;
        }

        .notification {
            opacity: 1;
            @include box-theme();
            width: $padding * 20;

            @media (max-width: $size-mobile) {
                width: 100%;
            }

            box-shadow: none;
            //      @include box-shadow-huge();
            border: solid 1px $light3;
            padding: $padding * 1.5;
            color: $dark1;
            background-color: $light1;


            &::before {
                left: $padding * 1.5;
            }

            .notification-message {
                padding-left: $padding * 2.5;

                h4.title {
                    font-size: $font-largest;
                    ;
                    padding: 0;
                    margin: 0;
                    line-height: 1.25;
                }

                .message {
                    font-size: $font-large;
                    line-height: 1.5;
                    padding: 0;
                    margin: 0;
                    display: none;

                }
            }

            &.notification-info {
                &::before {
                    color: $blue1;
                }
            }
            &.notification-warning {
                &::before {
                    color: $pink;
                }
            }

            &.notification-success {
                &::before {
                    color: $green1;
                }
            }
        }

    }
}