.application-form {


    .modal-title {
        font-size: $font-largest;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100% - #{$padding * 3});
        text-overflow: ellipsis;

        .company-name {
            font-size: $font-largish;
            font-weight: 400;
            color: $dark2;
        }
    }

    .application-form-success {
        padding: $padding * 4 $padding * 2;

        text-align: center;

        h4 {
            font-size: $font-huge;
        }

        p {
            text-align: center;
            font-size: $font-large;

            &.ps {
                font-size: $font-normal * 1.1;
            }
        }

    }
}