.sign-up-complete {

    //padding: $padding * 5 0;
    // margin: $padding * 5 auto;
    max-width: 100%;
    padding: $padding * 5 0 !important;

    .sign-up-complete-form {
        @include box-theme();
       // @include box-shadow-lg();
        padding: $padding * 3 !important;
        margin-bottom: $padding * 2;

        @media (max-width: $size-mobile) {
            border: none;
            padding: $padding * 0.5 !important;
            box-shadow: none;
        }



        h1 {
            font-size: $font-huge * 0.7;
            line-height: 1.25;
            margin-bottom: $padding * 1.5;

            @media (max-width: $size-mobile) {
                font-size: $font-huge * 0.7;
            }
    
        }

  

        .field-row {
            padding: 0;
            margin-bottom: 0;
            position: relative;

            .error {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                padding-bottom: $rad;
            }

        }

        .btn {
            font-size: $font-largest * 1.1;
            padding: $padding * 1.5 $padding * 2;
            width: 100%;
        }

        .sign-up-complete-error{
            color: $pink;
            padding-top: $spacing;
            font-size: $font-large;
        }

   
    }

    .sign-up-complete-login {
        font-size: $font-large;
        text-align: center;
    }

}
