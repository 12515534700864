.loader {
    padding: $padding;
    font-size: $font-normal;
    text-align: center;
    &.small {
        padding: 0;
        max-height: $control-height;
    }
    &.large {
        padding: $padding * 2;
    }
    &.huge{
        padding: $padding * 8;
        font-size: $font-large;
    }
    img {
        max-width: 40px !important;
    }
    .fa {
        font-size: 25px;
        color: $light1;
        margin-bottom: 5px;
    }

    .loader-msg{
        color: $dark4;
        font-size: $font-large;
    }

    &.min-height{
        min-height: 500px;
    }
}

.loader+.text {
    text-align: left;
}