.react-calendar{
    @include box-theme();
    font-size: $font-largish;
    width: 500px;
    margin: 0 auto;
    margin-bottom: $padding;

    .filled{
        color: $green2;
        font-weight: 400;
        display: block;
        padding: $spacing $rad;
        .filled-date{
            color: $dark5;
            font-weight: 900;
        }
    }

    .react-calendar__month-view__days__day{
        padding: 0;
        min-height: $padding * 2.5;
        abbr{
            display: none;
        }
    }
}