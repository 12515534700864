.resume-review-landing {
	color: $dark5;

	.content {
		padding: $padding;
		margin-block: $padding * 5;

		@media screen and (max-width: $size-mobile) {
			margin-block: $padding * 2;
		}
	}
}

.resume-review-hero {
	.content {
		text-align: center;
		max-width: $size-tablet;
		margin-inline: auto;
	}

	h1 {
		font-weight: 900;
		font-size: $font-huge * 1.3;
		line-height: $font-huge * 1.4;
		color: $dark5;
		margin: $padding * 2 0;

		@media (max-width: $size-mobile) {
			font-size: $font-huge * 1.1;
			line-height: $font-huge * 1.2;
		}

		span {
			display: block;

			&:last-child {
				color: $pink;
			}
		}
	}

	p {
		margin: 0;
		width: 100%;
		font-size: $font-large * 1.4;
		line-height: $font-large * 1.8;

		@media (max-width: $size-mobile) {
			font-size: $font-large * 1.2;
			line-height: $font-large * 1.5;
		}
	}

	.privacy-note {
		margin-top: $padding;
		color: $dark2;
		font-size: $font-normal;
		
		i {
			margin-right: $spacing/2;
		}
	}
}

.resume-review-as-seen-on {
	width: 100%;
	border-bottom: none;
	background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
	background: linear-gradient(to bottom, $light1, lighten($light2, 3));
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 0 15px;

	.content {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		max-width: $size-tablet;
	}

	h3 {
		font-size: $font-large * 1.2;
		line-height: $font-large * 1.5;
		font-weight: 600;
		width: 100%;
		text-align: center;
		margin: 0;
	}

	h4 {
		font-size: $font-large * 1.2;
		line-height: $font-large * 1.5;
		font-weight: 400;
		color: $dark1;
		width: 100%;
		text-align: center;
		margin-top: $padding * 5;
	}

	.as-seen-on-logos {
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: $padding * 1.5;
		margin-top: $padding * 1.5;
		width: 100%;

		img {
			display: inline-block;
			height: $padding * 2.5;
			object-fit: contain;
			opacity: 0.5;
		}
	}
}

.resume-review-how-it-works {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;

	.content {
		max-width: $size-desktop;
		margin: $padding * 10 0;
		flex-flow: row wrap;
	}

	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 2;
	}

	h6 {
		font-size: $font-large * 1.3;
		line-height: $font-large * 1.6;
		margin: 0;
		color: $dark1;
		font-weight: 400;
	}

	.steps {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 0;
		margin-top: $padding * 3;
	}

	.step-wrapper {
		padding: $padding;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100% / 3);

		@media screen and (max-width: $size-mobile) {
			flex-direction: row;
			width: 100%;
		}

		.step {
			border-radius: $rad;
			border: 2px solid $pink;
			padding: $padding * 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: $light1;
			box-shadow: 0px 4px 0px 0px $pink;

			h4 {
				font-size: $font-large * 1.2;
				line-height: 1;
				font-weight: 500;
				color: $light1;
				background-color: $pink;
				margin: 0;
				padding: 0;
				border-radius: 100%;
				width: $padding * 2.5;
				height: $padding * 2.5;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: $padding * 1;
			}

			p {
				font-weight: 400;
				text-align: center;
				font-size: $font-large * 1.1;
				color: $dark5;
				line-height: $font-large * 1.5;
			}
		}
	}
}

.resume-review-why-use {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
	background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
	background: linear-gradient(to bottom, $light1, lighten($light2, 3));

	.content {
		max-width: $size-tablet;
		margin: $padding * 10 0;
		flex-flow: row wrap;
	}

	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 2;
	}

	h6 {
		font-size: $font-large * 1.3;
		line-height: $font-large * 1.6;
		margin: 0;
		color: $dark1;
		font-weight: 400;
	}
}

.resume-review-features {
	background-color: $light1;
}

.resume-review-uploader {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
	background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
	background: linear-gradient(to bottom, $light1, lighten($light2, 3));

	.content {
		max-width: $size-desktop;
		margin: $padding * 10 0;
		flex-flow: row wrap;
	}

	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 2;
	}

	h6 {
		font-size: $font-large * 1.3;
		line-height: $font-large * 1.6;
		margin: 0;
		color: $dark1;
		font-weight: 400;
		margin-bottom: $padding * 3;
	}

	.uploader-container {
		display: flex;
		justify-content: space-between;
		gap: $padding * 3;
		margin-top: $padding * 2;
		
		@media screen and (max-width: $size-tablet) {
			flex-direction: column;
		}

		.upload-area {
			flex: 1;
			background-color: $light1;
			border: 2px dashed $pink;
			border-radius: $rad;
			padding: $padding * 3;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			transition: all 0.3s ease;
			
			&:hover {
				border-color: darken($pink, 10%);
				background-color: rgba($pink, 0.05);
			}
			
			.upload-icon {
				font-size: $font-huge * 1.5;
				color: $pink;
				margin-bottom: $padding;
			}
			
			p {
				font-size: $font-large * 1.1;
				margin: $padding 0;
			}
			
			.file-types {
				font-size: $font-normal;
				color: $dark2;
				margin-bottom: $padding * 2;
			}
		}
		
		.upload-info {
			flex: 1;
			text-align: left;
			padding: $padding * 2;
			background-color: $light1;
			border-radius: $rad;
			box-shadow: 0px 4px 0px 0px $pink;
			
			h4 {
				font-size: $font-large * 1.3;
				font-weight: 600;
				margin-top: 0;
				margin-bottom: $padding;
			}
			
			ul {
				padding-left: $padding;
				margin-bottom: $padding * 2;
				
				li {
					font-size: $font-large * 1.1;
					margin-bottom: $padding;
					
					i {
						color: $pink;
						margin-right: $spacing;
					}
				}
			}
			
			.privacy-note {
				font-size: $font-normal;
				color: $dark2;
				font-style: italic;
				
				i {
					margin-right: $spacing/2;
				}
			}
		}
	}
}

.resume-review-trust {
	width: 100%;
	display: flex;
	justify-content: center;
	
	.content {
		max-width: $size-desktop;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	
	h2 {
		font-size: $font-huge * 1.2;
		line-height: $font-huge * 1.5;
		font-weight: 900;
		margin-bottom: $padding * 4;
	}
	
	.trust-features {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: $padding * 3;
		margin: $padding * 5 0;
		width: 100%;
		
		.trust-feature {
			flex: 1;
			min-width: 250px;
			max-width: 350px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			
			.trust-icon {
				font-size: $font-huge;
				color: $pink;
				margin-bottom: $padding;
			}
			
			.trust-info {
				h4 {
					font-size: $font-large * 1.2;
					font-weight: 600;
					margin-bottom: $spacing;
				}
				
				p {
					font-size: $font-large;
					color: $dark2;
				}
			}
		}
	}
	
	.final-cta {
		background-color: $light2;
		border-radius: $rad;
		padding: $padding * 3;
		margin-top: $padding * 3;
		width: 100%;
		max-width: $size-tablet;
		box-shadow: 0 4px 0 0 $pink;
		
		h3 {
			font-size: $font-large * 1.3;
			font-weight: 600;
			margin-bottom: $padding * 2;
		}
	}
} 