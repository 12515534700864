.notcountrytavert-tile-wrapper {
    margin: 0 auto;
    display: block;
    position: relative;
    @include box-theme();
    padding: $padding * 1.25 $padding * 1.75;
    margin-bottom: $padding;
    overflow: visible;
    @include border-radius(0px);
    margin-bottom: 0;
    border-bottom: none;
    //  @include box-shadow-light();

    &:hover {
        background-color: lighten($light2, 3);

    }


    @media (max-width: $size-mobile) {
        overflow: hidden;
        padding: 0;
        @include border-radius(0);
        //  border-left: none;
        border-right: none;
        border-bottom: none;
        border-left: none;

        margin: 0;

        //    border-top: solid 2px lighten($light2, 3) !important;
    }



   







    .nottavert-tile-image {
        position: absolute;
        background: $light1;
        border: solid 1px $dark1;
        background-size: contain;
        height: $padding * 2.5;
        line-height: $padding * 2.5;
        width: $padding * 2.5;
        left: -$padding * 1.25;
        top: $padding * 1.25;
        z-index: 100;
        @include border-radius(50%);

        @media (max-width: $size-mobile) {
            display: none;
        }
    }


    &.hovering {
        border-left: solid 1px lighten($light2, 2);

    }

    &:hover {
        text-decoration: none !important;
        //   border-left-width:  $spacing;

    }


    .nottavert-tile-right-cta{

    }
    .nottavert-tile-bottom-cta{

        display: none;
        @media (max-width: $size-mobile) {
            display: block;
            padding: 0 $padding $padding * 1.5 $padding;
        }
    }



    .nottavert-tile {

        position: relative;
        line-height: 1;
        display: flex;
        flex-direction: column;
        // overflow: auto !important;

        @media (max-width: $size-mobile) {
            padding: $padding * 1.25 $padding;
            padding-bottom: $spacing;
            @include border-radius(0);
            flex-direction: column;
            margin-bottom: 0;
            line-height: 1;
            // border-bottom: solid 1px $light2;
        }



        .row {
            width: 100%;
        }


        .nottavert-tile-left {


            .nottavert-tile-top {
                color: $dark5;
                font-size: $font-largest;
                font-weight: 400;
                margin: 0;
                padding-bottom: $spacing * .5;



                h5 {
                    margin-bottom: 0;
                    padding-top: 0;
                    padding-bottom: 0;

                    

                    a {
                        text-align: left;
                        font-weight: 700;
                        //    @include ellipsis();
                        color: $dark3;
                        text-align: left;
                        font-size: $font-largest;
                        margin-bottom: 0;
                        padding-top: 0;
                        padding-bottom: 0;
                        line-height: 1.5;

                        &:hover {
                            color: $pink !important;
                            text-decoration: none;
                        }


                    }

                }
                


                @media (max-width: $size-mobile) {
                    padding-bottom: 0;
                }
            }



        }

        .nottavert-tile-right {
            text-align: right;

            .btn {
                @media (max-width: $size-mobile) {
                    display: none;
                }

            }


            .nottavert-tile-time {

                text-align: right;
                margin-bottom: $spacing;
                font-size: $font-normal;
                color: $dark1;
                line-height: 1;
                font-weight: 400;

                @media (max-width: $size-mobile) {
                    display: none;
                }

            }



            .nottavert-tile-apply {
                @media (max-width: $size-mobile) {
                    display: none;
                }

            }





        }


        .nottavert-tile-title {

            color: $dark1;
            margin-bottom: 0;
            font-size: $font-normal;
            line-height: 1.5;
            margin-bottom: 0;
            padding-bottom: 0;
            margin-top: $spacing * 1.5;
            font-weight: 400;


        }



        .nottavert-tile-promoted {
            color: $dark1;
            position: absolute;
            right: $padding;
            font-weight: 400;
            padding-top: $spacing;
            font-size: $font-normal;
        }


        .nottavert-company-name {
            background: rgba(215, 255, 225, 0.8);
            color: darken($green1, 12%);
            padding: $rad * 1 $rad * 1.5;
            @include border-radius($rad);
            margin-top: $rad;
            line-height: 1;;
            display: inline-block;
        



            @media (max-width: $size-mobile) {
                text-align: left;
                padding-top: $rad;
                ;

                &.country-num-employees {
                    display: none;
                }

            }

        }


    }


    .nottavert-tile-description {
        font-size: $font-normal * 1.1;
        line-height: 1.5;

        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: $spacing;
        font-weight: 400;
        color: $dark1;


        @media (max-width: $size-mobile) {
            padding: 0 $padding $padding $padding;
        }



    }


}