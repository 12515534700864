.jobs-list {

  padding-bottom: $padding * 3;

  .job-listing {
    max-width: $size-mobile;
    margin: 0 auto;


    &:last-child {
      border-bottom: solid 1px $light3;
    }

    // Left
    .job-content {
      border: solid 1px $light3;
      border-bottom: none;

      padding: $padding * 4;

      @media(min-width: $size-mobile) {}


      @media(max-width: $size-mobile) {
        border: none;
        padding: $padding * 3 $padding * 1.5;

        .read-more {
          display: none;
        }

      }

      .apply-warning {
        font-style: italic;
        padding: $spacing $padding;
        width: auto;
        margin: $padding auto $padding auto;
        font-size: $font-normal;
        color: darken($pink, 12);
        border: solid 1px rgba($pink, 0.19);
        background: rgba($pink, 0.13);

        //    font-weight: 900;
        //  background-color: lighten($light2, 4);
        @include border-radius($spacing);
        text-align: center;

        @media(max-width: $size-mobile) {
          padding: $padding;

          margin: ($padding * 0) (-$padding * 1.5) ($padding * 2) (-$padding * 1.5);

          border-radius: 0;
          border: none;

        }

      }

      // Description
      .job-description-text {
        padding-top: $padding;
        min-height: 300px; // CLS

        img {
          display: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: $font-largest;
          font-weight: 900;
          margin: 0;
          padding: 0;
          padding-bottom: $padding;
          line-height: 1.25;
        }

        h4,
        h5,
        h6 {
          font-size: $font-largish;
        }


      }


      .job-footer {
        padding: $padding * 1 0;

        .tile-apply-bottom {
          .btn {

            min-width: $padding * 14 !important;
            padding: $padding;

            .apply-location {
              display: block;
              font-size: $font-normal;
              margin-top: $rad * 2;
              font-weight: 400;
            }
          }

          @media (max-width: $size-mobile) {
            padding: $padding * 1 0 0 0;
            text-align: center;
          }

          .ai {

            padding-top: $padding * 1.5;

            @media (max-width: $size-mobile) {}
          }
        }


        .apply-warning {
          color: $pink;
          font-size: $font-normal;
          padding-top: $spacing;
          display: none;

          @media (max-width: $size-mobile) {
            padding-top: $rad;
            //    padding-bottom: $padding;
            text-align: center;
            ;


          }
        }

        .more-info {
          @media (max-width: $size-mobile) {
            width: 100%;
          }
        }


        .job-tile-expired {
          font-size: 1.8rem;
          color: $red1;
          font-weight: 900;
          text-align: center;
          margin-top: 0;
          padding: $spacing;
          background: rgba($red1, 0.2);
          border-radius: $rad;
          max-width: $padding * 12;
        }

      }

      .job-title {
        margin-bottom: 0;
        padding-bottom: $padding;

        @media (max-width: $size-mobile) {
          padding-bottom: $padding * 2;
        }

        .job-apply-button .btn {
          padding: $spacing * 1.5;

          @media (max-width: $size-mobile) {
            margin-top: $padding * 1.5;
          }
        }


        .title-fdw {
          font-size: $font-largish;
          line-height: 1;
          padding-bottom: $spacing;
          color: $green1;


          .title-salary-80 {
            color: $red1;
          }

          //    background-color: rgba(lighten($green1, 10), 0.2);

          @media (max-width: $size-mobile) {
            margin-top: $spacing;
            text-align: center;
            ;
          }
        }


        .job-title-left {

          // padding-right: $padding;
          @media(max-width: $size-mobile) {
            text-align: center;
          }


          .title-logo {
            display: inline-block;
            vertical-align: top;
            padding-right: $padding;

            @media(max-width: $size-mobile) {
              width: $padding * 4;
              height: $padding * 4;
              margin-bottom: $padding;
              padding-right: 0;
            }

            img {
              display: inline-block;
              width: $padding * 4;
              height: $padding * 4;
              @include border-radius(50%);

            }
          }

          h3 {
            font-size: $font-largest * 1.4;
            font-weight: 900;
            line-height: 1.25;
            margin: 0;
            padding: 0;
            padding-bottom: $rad;

            @media (max-width: $size-mobile) {
              font-size: $font-huge * 0.9;
              padding-bottom: $spacing;


            }
          }


          .title-employees {
            display: inline-block;
            color: $dark1;
            font-size: $font-normal;
          }

          .title-company {
            margin-right: $padding;


          }

        }

        .job-title-right {}








      }

    }
  }
}