.post-job,
.post-edit {

    .section-header {

        margin-bottom: 0; // $padding;
        width: 100%;
        padding: $padding * 2 $padding;
        background: $green1;
        background: #1d976c;

        border-bottom: none; //solid 1px $light2;
        background: -webkit-linear-gradient(to bottom, $light1, lighten($light2, 3));
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, $light1, lighten($light2, 3));


        @media (max-width: $size-desktop) {
            @include border-radius($rad);
        }

        @media (max-width: $size-mobile) {
            margin-bottom: 0;
            padding: $padding * 4 $padding;
            padding-bottom: $padding * 2;

            border-bottom: none;
        }


        @media ((min-width: $size-mobile) and (max-width: $size-tablet)) {
            background: $light1 !important;
            padding: 0;
        }

        .hero {
            padding: $padding * 2 0;

            .page-subtitle {
                margin-bottom: 0;
            }
        }

    }


    .post-job-form {


        .radio-meta,
        .checkbox-meta {
            color: $green2;
            border: solid 1px lighten($green1, 12);
            margin-left: $padding;
            line-height: 1 !important;
            padding: $rad * 0.6 $rad * 1.5;
            border-radius: $rad;
            font-size: $font-normal !important;

    
        }

        .checkbox-meta{
            @media (max-width: $size-mobile) {
                display: inline-block;
                padding: $rad;
                margin-top: $rad;
            }
        }

        .radio-meta {
            padding: $rad * 1 $rad * 1.5; // who knows...
        }



        padding-top: $padding * 2;

        @media (max-width: $size-mobile) {
            padding: $padding * 1;
            padding-top: $padding * 3
        }

        .post-job-box {
            padding-bottom: $padding * 3;
            //    border-bottom: solid 1px $light2;
            margin-bottom: $padding * 3;

            h3.post-job-heading{
                margin-bottom: $padding * 1;
                font-weight: 800;
                font-size: $font-largest * 1.3;
            }

            .field-row {
                .label-container>label {
                    font-size: $font-largest;
                    padding-bottom: $spacing;
                }


            }
        }

        .post-job-submit {
            .discounted {
                text-decoration: line-through;
                padding: 0 $rad;
            }
        }

        .post-job-form-basic {}


        .post-job-form-job-description {

            .salary-to {
                font-size: $font-largest * 1.3;
                text-align: center;
            }

        }






    }





}