.jobs-page {


    .section-header{
        h1{
            text-align: center;
            text-wrap: balance;
        }
        h2 {
            text-align: center;
            text-wrap: balance;
        }


        .modal{
            h2 {
                text-align: left;
                font-size: $font-huge * 1.1;
                
            }
        }
    }
    .jobs-bottom {
    

    }
}