.job-filters-page {
    .filters-list {
        column-count: 3;
        column-gap: 20px;
        /* optional: to set the gap between columns */


        @media (max-width: $size-mobile) {
            column-count: 1;
        }

        // Set list disc colour to red
        li {
            list-style: disc;
            color: $dark5;
        }

    }
}