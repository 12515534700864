.wrapper .content-wrapper .main-container-wrapper .main-container.pros-and-cons-page {


    .did-you-know {
        font-size: $font-largish;
        color: $dark1;
        padding-bottom: $padding;
        text-transform: uppercase;
    }

    .pros-and-cons {



        .pros-and-cons {
            padding: 0;

            .pro-con-title {
                h3 {
                    color: $dark4;
                    text-align: center;
                    margin: 0 auto;
                    font-weight: 900;
                    font-size: $font-huge * 1;
                    font-weight: 900;
                    line-height: 1.25;
                    padding: $padding * 4 $padding * 2;

                    @media (max-width: $size-mobile) {
                        font-size: $font-huge * 0.7;
                        padding: $padding * 2 $padding;
                    }


                    @media (min-width: $size-mobile) and (max-width: $size-desktop) {
                        background: $light1 !important;
                        padding: 0;
                    }
                }

                &.pro-con-world h3 {
                    background: rgba(lighten($blue2, 35), 0.44);
                }

                &.pro-con-employee h3 {
                    background: rgba(lighten($gold, 20), 0.45);
                }

                &.pro-con-company h3 {
                    background: rgba(lighten($green1, 25), 0.25);
                }

                &.pro-con-extra h3 {
                    background: rgba(lighten($green1, 25), 0.25);
                }
            }

            &.pros-and-cons-extra {
                .pro-con {
                    h4 {
                        font-weight: 400 !important;
                        margin-bottom: $padding * 4 !important;
                    }
                }
            }

            &.pros-and-cons-cta {
                padding: $padding * 3 $padding;
                padding-bottom: $padding * 6;
                text-align: center;

                h4 {
                    margin-bottom: $padding * 1.5;


                }


                .pro-con-buttons {
                    padding-bottom: $padding * 6;

                    .btn {
                        margin-right: $padding;
                        padding: $padding * 1.5;
                        margin-bottom: $padding * 1.5;

                        .svg-inline--fa {
                            margin-right: $spacing;
                        }

                    }

                    .btn-linkedin {
                        background: $dark4;
                        color: $light1;

                        &:hover {
                            background: $green2;
                        }
                    }


                    .btn-contact {
                        background: $green1;
                        color: $light1;

                        &:hover {
                            background: lighten($green2, 10);
                        }
                    }


                    .btn-facebook {
                        background: $blue2;
                        color: $light1;

                        &:hover {
                            background: $green2;
                        }
                    }

                    .btn-twitter {
                        background: lighten($blue2, 14);
                        color: $light1;

                        &:hover {
                            background: $green2;
                        }
                    }
                }

            }


            .pro-con {
                padding: $padding * 5 0 $padding * 10 0;
                width: 100%;
                max-width: $size-mobile * 1.3;
                margin: 0 auto;

                @media (max-width: $size-mobile) {
                    padding: $padding * 2;
                    padding-bottom: $padding * 7;

                }


                .why-hire {
                    font-size: $font-huge;
                    text-align: left;
                    line-height: $font-huge;
                    margin-bottom: $padding;
                    font-weight: 900;
                }

                .pro-con-number {
                    font-weight: 400;
                    font-size: $font-largest * 1.2;
                    text-align: left;
                    padding: $spacing * 1.5 0 $spacing * 1.5 $padding * 1;
                    line-height: 1;
                    color: $green2;
                    border-top: 0;
                    border-right: 0;

                    &.con {
                        color: $red2;
                    }

                    @media (max-width: $size-mobile) {
                        text-align: left !important;
                        margin-bottom: $padding !important;
                        border: none !important;
                        padding-left: 0 !important;
                        padding-bottom: 0 !important;
                    }



                }



                .pro-con-text {
                    padding-top: $spacing;

                    .hero-img {

                        width: 100% !important;
                        max-width: 100%;
                    }

                    h4 {
                        margin: 0;
                        padding: 0;
                        margin-bottom: $spacing;
                        font-weight: 500;
                        color: $dark5;
                        font-size: $font-huge * 0.65;
                    }

                    p {
                        font-size: $font-large !important;
                        padding-top: $spacing;
                        color: $dark2;
                        line-height: 1.7;

                        .btn {
                            font-size: $font-large !important;

                        }

                    }


                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            font-size: $font-large;
                            line-height: 1.5;
                            list-style: none;
                            border-left: solid 25px lighten(rgba($green1, 0.4), 15);
                            padding-left: $padding * 1.5;
                            margin-bottom: $padding * 1.25;

                            .btn {
                                font-size: $font-large !important;

                            }
                        }
                    }


                    .pro-con-link {
                        padding-top: $spacing;
                        font-size: $font-large;
                        color: $dark1;

                        .btn {

                            font-size: $font-large !important;
                        }
                    }

                    .pro-con-more-info {
                        margin-top: $padding * 1.5;
                    }

                    .pro-con-quote {
                        margin-top: $padding * 2;
                        padding: $padding * 2.5 $padding * 5;
                        margin-bottom: $padding * 2;
                        max-width: $padding * 34;
                        @include border-radius($rad);
                        //       border: solid 1px $light3;
                        background-color: rgba(lighten($green2, 22), 0.22);
                        position: relative;


                        &.smaller {
                            blockquote {

                                font-size: $font-largest * 1.1;
                                line-height: $font-largest * 1.45;
                            }
                        }


                        @media (max-width: $size-mobile) {

                            padding: $padding * 2.5 $padding * 3;
                        }

                        .icon {
                            position: absolute;
                            left: -$padding * 2;
                            top: $padding * 1.25;
                            line-height: 1;
                            font-size: $font-huge * 4;
                            color: rgba(lighten($green2, 20), 0.8);

                        }

                        blockquote {
                            border: none;
                            font-size: $font-largest * 1.35;
                            line-height: $font-largest * 1.7;
                            color: darken($green1, 10);
                            padding: 0;
                            margin: 0;
                            padding-bottom: $spacing;

                        }

                        .pro-con-author {
                            font-size: $font-large;
                            padding: 0;
                            margin: 0;
                            font-style: italic;
                            color: $dark1;
                        }

                


                    }


                    .pro-con-emph {
                        margin-top: $padding * 2;
                        padding: $padding * 2.5 $padding * 5;
                        margin-bottom: $padding * 2;
                        max-width: $padding * 34;
                        @include border-radius($rad);
                        //       border: solid 1px $light3;
                        background-color: rgba(lighten($pink, 22), 0.18);
                        position: relative;


                        @media (max-width: $size-mobile) {

                            padding: $padding * 2.5 $padding * 3;
                        }

                        &.blue {
                            background-color: rgba(lighten($blue1, 22), 0.18);

                            .icon {
                                color: rgba(lighten($blue1, 20), 0.6);
                            }

                        }

                        &.green {
                            background-color: rgba(lighten($green2, 22), 0.22);

                            .icon {
                                color: rgba(lighten($green2, 20), 0.8);
                            }

                        }


                        .icon {
                            position: absolute;
                            color: rgba(lighten($pink, 20), 0.6);


                            &.arrow {

                                @media (max-width: $size-mobile) {
                                    left: -$padding * 2.1;
                                    top: $padding;

                                    font-size: $font-huge * 2.5 !important;
                                }

                                left: -$padding * 3.35;
                                top: -$spacing;
                                line-height: 1;
                                font-size: $font-huge * 4;
                            }

                        }

                        blockquote,
                        p {
                            border: none;
                            font-size: $font-largest * 1.2;
                            line-height: $font-largest * 1.7;
                            color: darken($green1, 10);
                            padding: 0;
                            margin: 0;
                            padding-bottom: $spacing;

                        }

                        .pro-con-author {
                            font-size: $font-large;
                            padding: 0;
                            margin: 0;
                            font-style: italic;
                            color: $dark1;
                        }

                    }




                    .pro-con-img {
                        margin-top: $padding * 3;
                        width: 100%;
                        max-width: $padding * 50;

                        @media (max-width: $size-mobile) {
                            max-width: unset;
                            width: calc(100vw - #{$padding * 3});
                            margin-left: -$padding * 1.5;
                        }

                    }

                    .pro-con-caption {
                        font-size: $font-largish;
                        color: $dark2;
                        font-weight: 700;
                        padding-top: $padding;
                        text-align: center;

                        @media (min-width: $size-desktop) {
                            margin-left: -$padding * 4;

                        }
                    }
                }




            }

            .advantages {
                padding: $padding * 8 0;
                text-align: center;

                h1 {
                    margin-bottom: $padding * 2;
                }

                .advantages-boxes {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    text-align: left;

                    .advantages-box {

                        .emoji {
                            width: $padding * 2.5;
                            display: block;
                        }

                        font-size: $font-largest * 1.5;
                        line-height: $font-largest * 1.75;
                        margin-bottom: $padding * 3;
                    }
                }

                .hero-img {
                    width: 300px;
                    margin-bottom: $padding * 2;
                    max-width: 100%;
                }

            }
        }

    }





}