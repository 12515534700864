.article-notad-tile-wrapper {
    margin: 0 auto;
    display: block;
    position: relative;
    @include box-theme();
    padding: 0;
    margin-bottom: $padding;
    overflow: visible;
    padding: $spacing;
    margin: $padding * 3 0;

    //  @include box-shadow-light();
    background-color: lighten($light2, 6);

    &:hover {
        background-color: $light1;

    }


    &::before {
        content: "Sponsored";
        position: absolute;
        background-color: lighten($light2, 6);
        border: solid 1px $light2;
        color: $dark3;
        padding: $rad / 2 $rad * 2;
        @include border-radius($rad);
        top: -$padding * 0.75;
        left: $padding * 1.5;


        @media (max-width: $size-mobile) {
            background-color: lighten($light2, 6);
        }
    }


    @media (max-width: $size-mobile) {
        border: none;
        border-top: 1px solid $light2;
        border-bottom: 1px solid $light2;
        padding: $spacing;
        @include border-radius(0);
    }




    .article-notad-tile-image-wrapper {
        width: 37%;
        display: inline-block;
        vertical-align: top;
        padding: $padding;
        padding-right: $spacing;

        @media (max-width: $size-mobile) {
            width: 100%;
            display: block;
        }

        .article-notad-tile-image {
            @include border-radius($rad);
            width: 100%;


        }

        .article-notad-meta {
            padding-top: $spacing;



            .article-notad-rating {
                margin: 0 auto;
                text-align: center;
                max-width: $padding * 8;
                display: inline-block;
                margin-top: -$rad;
                width: 45%;


                @media (max-width: $size-mobile) {
                    width: auto;
                }

            }

            .article-notad-tile-tags {
                margin: 0;
                padding: 0;
                padding-left: $spacing;
                display: inline-block;
                width: 55%;



                li {
                    list-style: none;
                    margin-bottom: 0;
                    display: block;
                    text-align: center;
                    color: $dark1;
                    font-size: $font-largish * 1.1;

                    &:last-child {
                        margin-right: 0;
                    }




                }
            }
        }


    }



    .article-notad-tile-content-wrapper {

        width: 63%;
        display: inline-block;
        padding: $padding;

        padding-left: $spacing * 3;



        @media (max-width: $size-mobile) {
            width: 100%;
            display: block;
            padding: $padding;
        }

        h4 {
            font-size: $font-largish * 1;
            color: $dark3;
            margin: 0;
            padding: 0;
            padding-bottom: $spacing;
            display: none;
        }

        h5 {
            font-size: $font-largest;
            color: $dark5;
            margin: 0;
            padding: 0;
        }

        .btn {
            margin: 0;

        }

        h6 {
            display: inline-block;
            line-height: $control-height;
            vertical-align: middle;
            padding: 0;
            padding-left: $spacing;
            color: $green1;
            margin: 0;
            font-style: italic;
        }

        p {
            color: $dark3;
            font-size: $font-largest * 0.9;
            line-height: 1.5;
            margin: 0;
            padding: 0;
        }


    }
}