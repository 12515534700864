.company-tile-wrapper {
    max-width: $size-mobile;
    margin: 0 auto;
    position: relative;
    display: block;



    @media (max-width: $size-mobile) {
        &:nth-child(odd) {
            .company-tile {
                //  background: lighten($light2, 4);
            }
        }
    }

    &:focus {
        .company-tile-title {
            text-decoration: underline;
        }
    }

    .company-tile-logo,
    .company-tile-missing-logo {
        position: absolute;
        background: $light1;
        border: solid 1px $dark1;
        background-size: contain;
        height: $padding * 2.5;
        line-height: $padding * 2.5;
        width: $padding * 2.5;
        left: -$padding * 1.25;
        margin-top: $padding * 1.25;
        z-index: 100;
        @include border-radius(50%);

        @media (max-width: $size-mobile) {
            height: $padding * 1.5;
            width: $padding * 1.5;
            top: 0;
            left: $padding;
            padding: 0;
        }
    }

    .company-tile-missing-logo {
        font-size: $font-largest;
        border-color: $light3;
        text-align: center;
        background: $light2;
        color: $dark1;

    }

    &.hovering {
        cursor: pointer;

        .company-tile-apply {
            display: block;

            @media(max-width: $size-mobile) {
                display: none;
            }
        }
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none !important;
    }

    .company-tile {
        @include box-theme();
        padding: $padding $padding * 1.5;
        margin-bottom: $padding;
        position: relative;
        line-height: 1;
        display: flex;
        flex-direction: column;
        overflow: visible;

        //   overflow: auto !important;


        @media (max-width: $size-mobile) {
            padding: $padding * 1.5 $padding;
            @include border-radius(0);
            //     background: $light1;
            border-left: none !important;
            border-right: none;
            border-bottom: none;
            margin-bottom: 0;
            line-height: 1;
        }


        .company-featured {
            position: absolute;
            bottom: $padding;
            right: $padding;
            font-size: $font-small;
            font-weight: 400;
            color: $dark1; //lighten($green2, 0);

        }

        // Top
        .company-tile-top {
            width: 100%;
            padding-left: 0;
            display: flex;
            flex-direction: row;

            .company-features {
                width: 40%;

                @media (max-width: $size-mobile) {
                    width: 40%;
                }



                .company-score {
                    text-align: right;


                    .company-score-bar {
                        width: $padding * 7;
                        max-width: 100%;
                        @include border-radius($rad * 1.5);
                        border: solid 1px $light3;
                        padding: $rad / 2;
                        height: $spacing * 1.5;
                        display: inline-block;
                        margin-right: $spacing;


                        @media (max-width: $size-mobile) {
                            display: none;
                        }

                        .company-score-tube {
                            background: lighten($green1, 10);
                            border: none;
                            @include border-radius($rad);
                            height: 100%;


                        }
                    }

                    .company-score-number {
                        color: $dark3;
                        display: inline-block;
                        font-weight: 400;
                        margin-left: $spacing;
                        font-size: $font-largest * 0.9;

                        @media (max-width: $size-mobile) {
                            font-size: $font-largest;
                        }

                        .small {
                            padding-left: $rad / 2;
                        }

                    }

                }


            }




            .company-tile-title {
                font-weight: 700;
                align-self: flex-start;
                //    @include ellipsis();
                color: $dark3;
                text-align: left;
                font-size: $font-large * 1.1;
                margin-bottom: $rad;
                padding-top: 0;
                line-height: 1;

                width: 60%;


                @media (max-width: $size-mobile) {
                    padding-bottom: $rad;
                    white-space: initial;
                    margin-top: -$rad / 2;
                    font-size: $font-large * 1.15;
                    padding-left: $padding * 2;
                    width: 80%;

                }

                &:hover {
                    text-decoration: none;
                    color: $pink;
                }

                .company-tile-ranking {
                    color: $dark1;
                    font-weight: 400;
                    font-size: $font-largish;
                    padding-bottom: $spacing;

                    @media (max-width: $size-mobile) {
                        padding-left: $padding * 2;
                    }


                    .small {
                        font-size: initial; //$font-small;
                    }
                }
            }

        }


        .company-tile-middle {
            p {
                font-size: $font-normal;
                line-height: 1.5;
                margin-bottom: 0;
                padding-bottom: 0;
                margin-top: $spacing;
            }

            .reduced_hours {
                color: $dark1;
                margin-bottom: 0;

                @media (max-width: $size-mobile) {
                    display: none;
                }
            }

            blockquote {
                font-weight: 400;
                margin-top: $padding;
                line-height: 1.25;
                font-size: $font-normal;
            }
        }

        // Bottom
        .company-tile-bottom {
            display: flex;
            padding-top: $spacing;

            @media (max-width: $size-mobile) {
                padding-top: $rad;

            }

            .company-tile-left {
                width: 90%;


                @media (max-width: $size-mobile) {
                    width: 100%;


                }


                .company-details {
                    margin: 0;
                    padding: 0;

                    @media (max-width: $size-mobile) {
                        padding-top: $spacing;

                    }

                    li {
                        list-style: none;
                        margin-bottom: 0;
                        display: inline-block;
                        margin-right: $padding * 1.5;
                        color: $dark2;
                        font-size: $font-normal;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.company-reduced {
                            color: $green1;

                            &.offered-other {
                                color: $dark3;
                            }
                        }

                        @media (max-width: $size-mobile) {
                            display: block;

                            &.company-num-employees {
                                display: none;
                            }

                        }

                    }
                }

            }

            .company-tile-right {
                width: 20%;
                padding-left: 0; //$padding;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                align-items: center;


                @media (max-width: $size-mobile) {
                    align-items: center;
                }

                .company-roles {
                    text-align: right;
                    margin: 0;
                    padding: 0;
                    color: $dark1;
                    font-size: $font-normal;
                    font-weight: 400;
                    line-height: 1.25;

                    @media (max-width: $size-mobile) {
                        font-size: $font-small;
                    }

                    .svg-inline--fa {
                        margin-right: $rad / 2;
                    }
                }

                .company-feature-list {
                    padding: 0;
                    margin: 0;

                    li {
                        list-style: none;
                        color: $dark1;
                        background: transparent; //$light1;
                        font-size: $font-small;
                        line-height: 1.1;
                        padding: 0 0 0 $rad * 3;
                        //   @include inner-box-shadow();
                        text-align: right;
                        //     border: solid 1px darken($light2, 2);
                        display: inline-block;
                        margin-left: $spacing;
                        margin-bottom: $rad;
                        @include border-radius($padding);
                        font-weight: 400;



                        @media (max-width: $size-mobile) {
                            font-weight: 400;
                            font-size: $font-small;
                        }

                        .fa-check-circle {
                            margin-right: 2px;
                            font-size: $font-small * 0.9;
                        }

                        &.company-offered {
                            //    background: lighten($green1, 12);
                            color: $green1;
                            //   @include border-radius(0);
                            //     border-bottom: solid 1px  lighten($green1, 12);
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &.emoji {
                            padding: 0;
                            padding-right: $rad;
                            font-size: $font-small;
                        }
                    }
                }


                @media (max-width: $size-mobile) {
                    width: 40%;
                    //  display: none;
                }


            }
        }
    }
}