.countries-page {


    .countries-list {
        border-bottom: solid 1px $light3;

        .subscriber.subscriber-green {
            margin-bottom: $padding;

            @include border-radius(0px);
            margin-bottom: 0;

            @media (max-width: $size-mobile) {
                margin-bottom: 0;
                border: none;
                @include border-radius(0);
                border-top: solid 1px $light3;
            }
        }

        .country-tile-wrapper {
            max-width: $size-mobile;
            margin: 0 auto;
            position: relative;
            display: block;

            &:hover {
                cursor: pointer;
            }



            @media (max-width: $size-mobile) {
                &:nth-child(odd) {
                    .country-tile {
                        //  background: lighten($light2, 4);
                    }
                }
            }

            .country-flag {
                position: absolute;
                background: $light1;
                border: solid 1px $dark1;
                background-size: contain;
                height: $padding * 2.5;
                line-height: $padding * 2.5;
                width: $padding * 2.5;
                left: -$padding * 1.25;
                margin-top: $padding * 1.25;
                z-index: 100;
                @include border-radius(50%);

                @media (max-width: $size-mobile) {
                    height: $padding * 1.5;
                    width: $padding * 1.5;
                    border: none;
                    top: $rad / 2;
                    right: $padding;
                    left: initial;
                }
            }

            .country-tile-missing-logo {
                font-size: $font-largest;
                border-color: $light3;
                text-align: center;
                background: $light2;
                color: $dark1;

            }

            &.hovering {
                cursor: pointer;

                .country-tile-apply {
                    display: block;

                    @media(max-width: $size-mobile) {
                        display: none;
                    }
                }
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none !important;
            }

            .country-tile {
                @include box-theme();
                padding: $padding * 1.25 $padding * 1.75;

                position: relative;
                line-height: 1;
                display: flex;
                @include border-radius(0px);
                flex-direction: column;
                overflow: visible;
                border-bottom: none !important;
                z-index: 20;


                &:last-child {
                    border-bottom: solid 1px $light3;
                }

                &.show-hover,
                &:hover {
                    background-color: lighten($light2, 5);

                    @media (min-width: $size-mobile) {

                        .country-cta {
                            display: block !important;
                        }
                    }
                }

                //   overflow: auto !important;



                @media (max-width: $size-mobile) {
                    padding: $padding * 1.5 $padding;
                    @include border-radius(0);
                    //     background: $light1;
                    border-left: none !important;
                    border-right: none;
                    border-bottom: none;
                    margin-bottom: 0;
                    line-height: 1;
                    display: block;
                }


                .country-featured {
                    position: absolute;
                    bottom: $padding;
                    right: $padding;
                    font-size: $font-small;
                    font-weight: 400;
                    color: $dark1; //lighten($green2, 0);

                }

                // Top
                .country-tile-top {
                    width: 100%;
                    padding-left: 0;
                    display: flex;
                    flex-direction: row;


                    @media (max-width: $size-mobile) {
                        display: block;
                    }

                    .country-features {
                        width: 40%;


                        @media (max-width: $size-mobile) {
                            width: 100%;

                            .country-cta {
                                width: 100%;
                                display: none;
                                ;

                                .btn {
                                    margin: $rad 0 $spacing 0;
                                    width: 100%;
                                }

                            }
                        }

                        .country-cta {
                            text-align: right;

                            display: none;


                        }


                    }




                    .country-tile-title {
                        font-weight: 700;
                        align-self: flex-start;
                        @include ellipsis();
                        color: $dark3;
                        text-align: left;
                        font-size: $font-large * 1.1;
                        margin-bottom: $spacing;
                        line-height: 1;

                        width: 70%;

                        .country-rank {
                            color: $dark1;
                            font-weight: 400;
                        }


                        @media (max-width: $size-mobile) {
                            width: 100%;
                            padding-bottom: $rad;
                            white-space: initial;
                            font-size: $font-large * 1;
                            padding-left: 0;

                        }


                        .country-policy {
                            display: block;

                            p {
                                background: rgba(215, 255, 225, 0.8);
                                color: darken($green1, 12%);
                                padding: $rad * 1 $rad * 1.5;
                                @include border-radius($rad);
                                margin-top: $rad;
                                line-height: 1;
                                ;
                                display: inline-block;
                            }
                        }


                        a {
                            line-height: 1.5;
                            display: inline-block;
                        }

                        h2 {
                            font-size: $font-largest;
                            font-weight: 700;
                            line-height: 1.25;
                            color: $dark5;
                            margin-bottom: $rad;

                            @media (max-width: $size-mobile) {
                                margin-bottom: $spacing;
                            }
                        }

                        .country-tile-ranking {
                            color: $dark1;
                            font-weight: 400;
                            font-size: $font-largish;
                            padding-bottom: $spacing;
                        }
                    }

                }


                // Bottom
                .country-tile-bottom {

                    p {
                        font-size: $font-normal * 1.1;
                    }

                }
            }
        }

        .country-fdw-description {
            padding: $padding * 4;
            border: solid 1px $light3;
            border-top: solid 1px $light3;
            z-index: 100;

            @media (max-width: $size-mobile) {
                padding: $padding * 2;
                padding-bottom: $padding * 3;
            }


            .country-fdw-description-markdown {
                margin-bottom: $padding * 2;

                p {
                    color: $dark3;
                    font-size: $font-largish;
                    line-height: 1.5;

                    @media (max-width: $size-mobile) {}
                }

                a {
                    color: $green1;
                    text-decoration: none;
                    border-bottom: solid 1px rgba($green1, 0.5);

                    &:hover {
                        color: $green2;
                        text-decoration: none;
                        border-bottom: none;

                    }
                }

                h3 {
                    font-size: $font-huge * 0.8;
                    line-height: 1.5;
                    font-weight: 900;
                    margin-bottom: $padding;
                    padding-bottom: 0;
                    color: $dark5;

                    @media (max-width: $size-mobile) {
                        font-size: $font-huge * 0.6;
                    }
                }

                h4 {
                    font-size: $font-large * 1.1;
                    line-height: 1.5;
                    font-weight: 900;
                    margin-top: $padding * 2;
                    padding-bottom: 0;
                    color: $dark4;

                    @media (max-width: $size-mobile) {
                        font-size: $font-large * 0.9;
                    }
                }

                img {
                    max-width: 100%;
                    margin: $spacing 0;
                }

                ul {
                    padding: 0;
                    margin: $padding 0 0 $padding * 1;

                    li {
                        list-style: disc;
                        font-size: $font-largish;
                        color: $dark4;

                        margin-bottom: $padding;


                        @media (max-width: $size-mobile) {}

                        &:last-child {
                            margin-bottom: 0;
                        }

                    }
                }
            }
        }
    }
}