.jobs-list {
    @media (min-width: $size-mobile) {
        padding-top: $padding;
    }


    .num-jobs {
        font-size: $font-normal;
        max-width: $size-mobile;
        margin: 0 auto;
        margin-bottom: $spacing;
        padding-top: $padding;

        @media (max-width: $size-mobile) {
            padding: $padding;
            margin: 0;
            text-align: center;
        }
    }

    .no-jobs {
        padding: $padding * 4 $padding * 2;
        font-size: $font-largest * 1.1;

        line-height: 1.5;
        max-width: $size-mobile;
        text-align: center;
        margin: 0 auto;
    }

    .job-tile-wrapper {
        max-width: $size-mobile;
        margin: 0 auto;
        position: relative;
        padding: $padding * 1 0;
        padding-left: $padding;
        padding-right: $spacing / 2;

        padding-bottom: calc(#{$padding} - #{$rad}); // to accomomdate for tags
        overflow: visible;
        border: 1px solid $light3;
        border-bottom: none;
        //  @include box-shadow-light();

        &.job-highlighted {
            //    border-left: solid 1px $green1;
            //   padding: cacl(#{$padding} - px) 0;
            //   background: rgba($green1, 0.03);

            .job-tile-logo-wrapper {
                //       border: solid 3px $green1;
            }
        }



        &.hovering,
        &.expanded {
            @include box-shadow();
            background-color: lighten($light2, 4%);
            cursor: pointer;
            cursor: pointer;
            pointer-events: auto;

            * {
                cursor: pointer;
                pointer-events: auto;
            }

            .job-apply-button {
                display: block !important;

                .btn {
                    font-size: $font-large;
                    padding: $padding * 0.5 $padding * 1;
                    .apply-location {
                        padding-top: $rad;
                    }
                }

                @media (max-width: $size-mobile) {
                    display: none !important;
                }
            }



        }



        &:last-child {
            border-bottom: solid 1px $light3;


        }



        @media (max-width: $size-mobile) {
            overflow: hidden;
            padding: 0;
            @include border-radius(0);
            //  border-left: none;
            border-right: none;
            border-bottom: none;
            border-left: none;

            margin: 0;

            //    border-top: solid 2px lighten($light2, 3) !important;
            .inner-triangle {
                display: none;
            }

            &.success {
                border-left: none !important;
                // border-bottom: none !important;




            }
        }

        .job-tile-logo-wrapper {
            position: absolute;
            width: $padding * 3;
            top: $padding;
            left: -$padding * 1.75;
            height: $padding * 3;
            background: $light2;
            border: solid 1px $light3;
            overflow: hidden;
            z-index: 1;
            @include border-radius(50%);
            display: flex;
            align-items: center;



            &:hover {
                border: solid 1px * 0.75 $green1;
                @include box-shadow();

                &.no-link {
                    box-shadow: none;
                    border: solid 1px $light3 !important;
                }
            }


            @media (max-width: $size-mobile) {
                width: $padding * 1.5;
                height: $padding * 1.5;
                left: $spacing * 2;
                top: $spacing * 2;
            }

            .job-tile-logo,
            .job-tile-missing-logo {


                line-height: $padding * 3;
                width: 100%;
                height: 100%;





            }

            .job-tile-missing-logo {
                font-size: $font-largest;
                text-align: center;
                background: $light4;
                color: $light1;
                font-weight: 900;


                &:hover {
                    border: solid 1px $light4;
                }

            }

        }

        &.hovering {
            border-left: solid 1px $light3;

        }

        &:hover {
            text-decoration: none !important;
            //   border-left-width:  $spacing;

        }



        .job-tile {



            position: relative;
            line-height: 1;
            display: flex;
            flex-direction: row;
            // overflow: auto !important;

            @media (max-width: $size-mobile) {
                padding: $padding * 1.25 0; // $spacing $spacing;
                @include border-radius(0);
                background: $light1;
                flex-direction: column;
                padding-bottom: $padding - $rad;
                margin-bottom: 0;
                line-height: 1;
                // border-bottom: solid 1px $light2;
            }



            .job-tile-left {
                @media (min-width: $size-mobile) {
                    padding-right: 0;
                }


                .job-tile-title {
                    margin: 0;
                    padding-bottom: $spacing;

                    @media (max-width: $size-mobile) {
                        padding-bottom: $spacing;
                    }

                    h3 {
                        text-align: left;
                        margin: 0;
                        padding: 0;
                        line-height: 0.9;

                        padding-top: $rad;

                        @media (max-width: $size-mobile) {
                            padding: $rad 0;
                        }

                        a {
                            font-weight: 700;
                            font-size: $font-large;





                            @media (max-width: $size-mobile) {
                                white-space: normal;
                                text-overflow: unset;
                            }
                        }
                    }


                }

                .job-tile-company {
                    color: $dark5;
                    font-size: $font-largish;
                    font-weight: 400;
                    margin: 0;

                    @media (max-width: $size-mobile) {
                        padding-bottom: $spacing * 0.75;
                    }


                    &.no-link {
                        h5 {
                            color: $dark2;
                        }
                    }

                    h5 {
                        text-align: left;
                        line-height: initial;
                        font-size: $font-largish;
                        font-weight: 400;
                        padding: 0;
                        margin: 0;
                        line-height: 1;

                        @media (max-width: $size-mobile) {
                            padding-left: $padding * 2;
                        }

                        &:active {
                            color: unset;
                        }


                    }

                    a {
                        &:hover {
                            text-decoration: none;
                            color: $pink !important;
                        }

                    }



                }

                .job-tile-tags {
                    color: $dark1;
                    line-height: 1;
                    display: block;
                    margin: 0;
                    padding: 0;



                    li {
                        list-style: none;
                        margin-right: $spacing * 0.75;
                        display: inline-block;
                        line-height: 1.25;
                        font-size: $font-normal * 0.94;
                        margin-bottom: $rad;

                        background: rgba(225, 235, 245, 1);
                        color: $dark2;
                        @include border-radius($spacing);
                        padding: $rad * 1 $rad * 1.5;


                        .svg-inline--fa {
                            margin-right: $rad / 2;
                        }

                        &.offered,
                        &.would-consider,
                        &.remote-100 {
                            background: rgba(215, 255, 225, 0.8);
                            color: darken($green1, 12%);

                            &.hours-40 {
                                background: rgba(255, 200, 200, 0.6);

                                a {
                                    color: darken($red1, 50%);
                                }
                            }



                            &.hours-36,
                            &.hours-37,
                            &.hours-38,
                            &.hours-39 {
                                background: rgba(255, 235, 170, 0.8);

                                a {
                                    color: darken($dark5, 10%);

                                }
                            }



                        }

                        &.generous-pto,
                        &.unlimited-pto,
                        &.flexible-hours,
                        &.flex-fridays {
                            background: rgba(255, 225, 225, 0.65);
                            color: darken($red2, 30%);

                            a {
                                color: darken($red2, 30%);
                            }
                        }

                        &.nine-day-fortnight,
                        &.part-time,
                        &.offered-4-5-days,
                        &.offered-every-2nd-week,
                        &.offered-summer {

                            background-color: rgba(230, 245, 255, 0.9);
                            color: darken($blue1, 30%);
                        }

                        &.remote-city,
                        &.remote-country,
                        &.remote-continent {
                            background: rgba(255, 235, 170, 0.8);
                            color: darken($dark5, 5%);

                            a {
                                color: darken($dark5, 5%);
                            }
                        }

                        &.tile-salary,
                        &.relocation-budget {
                            // background: rgba(200, 230, 255, 1);
                            //    color: darken($dark3, 5%);
                            background: rgba(215, 255, 225, 0.8);
                            color: darken($green1, 12%);
                        }

                        a {
                            color: $dark4;
                        }



                        @media (max-width: $size-mobile) {
                            font-size: $font-small;
                            line-height: 1;
                            //       padding-bottom: 0;
                            margin-bottom: $rad * 1.5;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                }



            }

            .job-tile-right {

                @media (max-width: $size-mobile) {
                    position: absolute;
                    right: $spacing;
                    top: -$rad * 1.5;

                    .job-tile-expired {
                        color: $red2 !important;
                    }

                    .job-tile-time {
                        padding-top: $spacing * 1.5;

                        font-size: $font-small !important;

                    }
                }


                .job-apply-button {
                    display: none;
                }



                .job-tile-time {
                    text-align: right;
                    margin-bottom: $spacing;
                    font-size: $font-small;
                    color: $dark1;
                    line-height: 1;
                    font-weight: 400;

                    @media (max-width: $size-mobile) {
                        margin-top: $spacing * 1.5;
                    }

                }


            }
        }
    }

    .subscriber.subscriber-green {
        max-width: $size-mobile;
        margin: 0 auto;
        color: darken($green1, 12%);
        border-color: $light3;
        border-bottom: none;
        @include border-radius(0);
    }

}